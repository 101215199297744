import { Action } from '@ngrx/store';
import { LocationModel, SavedLocationSaveResult } from '../../models';
import { FailAction } from '../../../store/actions/fail-action';

export const LOAD_SAVED_LOCATIONS = '[SAVED LOCATIONS] Load Saved Locations';
export const LOAD_SAVED_LOCATIONS_SUCCESS = '[SAVED LOCATIONS] Load Saved Locations Success';
export const LOAD_SAVED_LOCATIONS_FAIL = '[SAVED LOCATIONS] Load Saved Locations Fail';
export const SET_LOCATION = '[SAVED LOCATIONS] Set Location';
export const CREATE_SAVED_LOCATION = '[SAVED LOCATION] Create Saved Location';
export const CREATE_SAVED_LOCATION_SUCCESS = '[SAVED LOCATION] Create Saved Location Success';
export const CREATE_SAVED_LOCATION_FAIL = '[SAVED LOCATION] Create Saved Location Fail';
export const DELETE_SAVED_LOCATION = '[SAVED LOCATION] Delete Saved Location';
export const DELETE_SAVED_LOCATION_SUCCESS = '[SAVED LOCATION] Delete Saved Location Success';
export const DELETE_SAVED_LOCATION_FAIL = '[SAVED LOCATION] Delete Saved Location Fail';

export const SUBMIT_SAVED_LOCATION = '[SAVED LOCATION] Submit Saved Location';
export const EDIT_SAVED_LOCATION_SUCCESS = '[SAVED LOCATION] Edit Saved Location Success';
export const EDIT_SAVED_LOCATION_FAIL = '[SAVED LOCATION] Edit Saved Location Fail';

export const UPDATE_NAME = '[SAVED LOCATION] Update Name';
export const UPDATE_ADDRESS = '[SAVED LOCATION] Update Address';
export const UPDATE_DEFAULT = '[SAVED LOCATION] Update Default';
export const EDIT_START = '[SAVED LOCATION] Start Saved Location edit';
export const EDIT_FINISH = '[SAVED LOCATION] Finish Saved Location edit';
export const SET_RESULT = '[SAVED LOCATION] Set Result';

export class LoadSavedLocations implements Action {
  readonly type = LOAD_SAVED_LOCATIONS;

  constructor(
    public searchText: string = ''
  ) {}
}

export class LoadSavedLocationsSuccess implements Action {
  readonly type = LOAD_SAVED_LOCATIONS_SUCCESS;

  constructor(
    public payload: LocationModel[]
  ) {}
}

export class LoadSavedLocationsFail extends FailAction {
  readonly type = LOAD_SAVED_LOCATIONS_FAIL;

  constructor(public error: any) {
    super();
  }
}

export class SetLocation implements Action {
  readonly type = SET_LOCATION;

  constructor(
    public payload: LocationModel
  ) {}
}

export class CreateSavedLocation implements Action {
  readonly type = CREATE_SAVED_LOCATION;
}

export class CreateSavedLocationSuccess implements Action {
  readonly type = CREATE_SAVED_LOCATION_SUCCESS;

  constructor(
    public payload: LocationModel
  ) {}
}

export class CreateSavedLocationFail implements Action {
  readonly type = CREATE_SAVED_LOCATION_FAIL;

  constructor(
    public error: any
  ) {}
}

export class DeleteSavedLocation implements Action {
  readonly type = DELETE_SAVED_LOCATION;
}

export class DeleteSavedLocationSuccess implements Action {
  readonly type = DELETE_SAVED_LOCATION_SUCCESS;

  constructor(
    public payload: number
  ) {}
}

export class DeleteSavedLocationFail implements Action {
  readonly type = DELETE_SAVED_LOCATION_FAIL;

  constructor(
    public error: any
  ) {}
}

export class SubmitSavedLocation implements Action {
  readonly type = SUBMIT_SAVED_LOCATION;

  constructor(
    public payload: {
      updateAll: boolean
    }
    ) {}
  }

export class EditSavedLocationSuccess implements Action {
  readonly type = EDIT_SAVED_LOCATION_SUCCESS;

  constructor(
    public payload: LocationModel
    ) {}
}

export class EditSavedLocationFail implements Action {
  readonly type = EDIT_SAVED_LOCATION_FAIL;

  constructor(
    public error: any
    ) {}
}

export class UpdateName implements Action {
  readonly type = UPDATE_NAME;

  constructor(public payload: string) {}
}

export class UpdateAddress implements Action {
  readonly type = UPDATE_ADDRESS;

  constructor(public payload: LocationModel) {}
}

export class UpdateDefault implements Action {
  readonly type = UPDATE_DEFAULT;

  constructor(public isDefault: boolean) {}
}

export class EditStart implements Action {
  readonly type = EDIT_START;
}

export class EditFinish implements Action {
  readonly type = EDIT_FINISH;
}

export class SetResult implements Action {
  readonly type = SET_RESULT;

  constructor(public payload: SavedLocationSaveResult) {}
}

export type SavedLocationsAction =
  | LoadSavedLocations
  | LoadSavedLocationsSuccess
  | LoadSavedLocationsFail
  | SetLocation
  | CreateSavedLocation
  | CreateSavedLocationSuccess
  | CreateSavedLocationFail
  | DeleteSavedLocation
  | DeleteSavedLocationSuccess
  | DeleteSavedLocationFail
  | SubmitSavedLocation
  | EditSavedLocationSuccess
  | EditSavedLocationFail
  | UpdateName
  | UpdateAddress
  | UpdateDefault
  | EditFinish
  | EditStart
  | SetResult;

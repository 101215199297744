import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import {
    CupcakeDropdownTriggerDirective,
    CupcakeDropdownTriggerOptions
} from '@ipreo/cupcake-components';
import { CupcakeSuggesterComponent } from '@ipreo/cupcake-suggester';
import { ListCount, ListEntity, ListType, inputDebounceTime } from '../../models';

@Component({
  selector: 'ea-lists-selector',
  templateUrl: 'lists-selector.component.html',
  styleUrls: ['lists-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListsSelectorComponent implements OnInit, OnDestroy {
  @Input() data: Observable<ListEntity[]>;
  @Input() totalCount: ListCount;
  @Input() loaded: boolean;
  @Input() filtered: boolean;
  @Input() disabled: boolean;
  @Output() searchTextChange = new EventEmitter();
  @Output() listSelect = new EventEmitter();

  searchText = new FormControl('');
  dropdownOptions: CupcakeDropdownTriggerOptions;
  destroyed$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('dropdown', { read: CupcakeDropdownTriggerDirective, static: false })
  dropdown: CupcakeDropdownTriggerDirective;

  @ViewChild('suggester', { static: false }) suggester: CupcakeSuggesterComponent;

  constructor(private translateService: TranslateService) {
    this.dropdownOptions = <CupcakeDropdownTriggerOptions>{
      trigger: 'manual',
      expandAreaWidth: 250
    };
  }

  ngOnInit() {
    this.searchText.valueChanges
      .pipe(
        debounceTime(inputDebounceTime),
        distinctUntilChanged(),
        filter(() => this.dropdown.isExpanded()),
        takeUntil(this.destroyed$)
      )
      .subscribe(d => {
        this.searchTextChange.emit(d);
      });
  }

  public toggleDropdown(): void {
    if (this.dropdown.isExpanded()) {
      this.dropdown.collapse();
    } else {
      if (this.filtered || !this.loaded) {
        this.searchTextChange.emit('');
      }
      this.dropdown.expand();
      this.suggester.setFocusOnInput();
    }
  }

  public onListSelect($event: ListEntity[]): void {
    if ($event.length > 0) {
      this.listSelect.emit($event[0]);
      this.closeDropdown();
    }
  }

  public getGroupHeader(type: ListType) {
    const count = this.totalCount[type];

    return this.translateService.instant(`bd/event-activity/form.list_group_header_${type}`, {
      loaded: count.loaded.toString(),
      total: count.total.toString()
    });
  }

  public closeDropdown(): void {
    this.dropdown.collapse();
    this.suggester.clearInput();
  }

  public stopPropagation($event: MouseEvent): void {
    $event.stopPropagation();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

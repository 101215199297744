import { NgModule, ModuleWithProviders } from '@angular/core';
import { AuthClientSettings } from './contracts/auth-client-settings';
import { AUTH_CLIENT_SETTINGS } from './contracts/auth-client-settings.token';
import { BillfoldAuthClient } from './services/auth-client.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BillfoldAuthInterceptor } from './services/auth-interceptor';
import { BillfoldAuthGuard } from './services/auth.guard';
import { SilentSignInIFrameObserver } from './services/silent-sign-in-iframe-observer.service';
@NgModule()
export class BillfoldAuthorizationModule {
  static forRoot(settings: AuthClientSettings): ModuleWithProviders {
    return {
      ngModule: BillfoldAuthorizationModule,
      providers: [
        SilentSignInIFrameObserver,
        BillfoldAuthClient,
        BillfoldAuthGuard,
        {
          provide: AUTH_CLIENT_SETTINGS,
          useValue: settings
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: BillfoldAuthInterceptor,
          multi: true
        }
      ]
    };
  }
}

export const ALLOWED_FILE_EXTENSIONS = [
'.xml',
'.csv',
'.avi',
'.bmp',
'.txt',
'.text',
'.doc',
'.docx',
'.dot',
'.dvi',
'.gif',
'.gz',
'.gzip',
'.htm',
'.html',
'.htmls',
'.shtml',
'.jpg',
'.jpeg',
'.log',
'.midi',
'.mp3',
'.mp4',
'.pdf',
'.png',
'.ppt',
'.pptx',
'.rm',
'.rtf',
'.tif',
'.tiff',
'.wav',
'.wp',
'.wp5',
'.wp6',
'.wpd',
'.wri',
'.xls',
'.xlsx',
'.xlt',
'.zip',
'.mpp',
'.wmv'
];

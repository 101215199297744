import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, map, switchMap, withLatestFrom, mergeMap } from 'rxjs/operators';

import { EventActivityService } from '../../services';
import * as dialogActions from '../actions/dialog-view.action';
import * as eventActivityActions from '../actions/event-activity.action';
import * as dataActions from '../actions/event-activity-data.action';

import * as fromEventActivity from '../selectors/event-activity.selector';
import * as fromDialog from '../selectors/dialog-view.selector';
import * as fromStore from '../../store';

import {
  ContactExternalParticipant,
  ContactListItemsSearchResponse,
  InstitutionExternalParticipant,
  InstitutionListItemsSearchResponse,
  ListType,
  FailedParticipantsInformation,
  EntityType as ParticipantEntityType,
  isEqualParticipants,
  ExternalParticipant
} from '../../models';
import { ListsService, ActivityParticipantValidationService } from '../../services';
import { MissingField } from '@ipreo/event-participant-missing-data-edit';

@Injectable()
export class ParticipantListEffect {
  constructor(
    private actions$: Actions,
    private store: Store<fromStore.State>,
    private listsService: ListsService,
    private eventService: EventActivityService,
    private participantValidation: ActivityParticipantValidationService
  ) {}

  @Effect()
  AddContactsFromList$ = this.actions$.pipe(
    ofType<dialogActions.SubmitExternalParticipantsList>(
      dialogActions.SUBMIT_EXTERNAL_PARTICIPANTS_LIST
    ),
    map(action => action.payload),
    filter(list => list.type === ListType.Contact),
    withLatestFrom(this.store.pipe(select(fromEventActivity.getExternalParticipants))),
    switchMap(([list, externalParticipants]) => {
      return this.listsService.getContactsFromList(list.id).pipe(
        mergeMap((response: ContactListItemsSearchResponse) => {
          const mappedContacts = response.data.map(
            data =>
              new ContactExternalParticipant(
                data.ipreoContactId,
                data.contactId,
                `${data.firstName} ${data.lastName}`,
                data.companyName,
                data.ipreoCompanyId,
                data.companyId,
                data.firstName,
                data.lastName,
                null,
                data.mobilePhone,
                data.phone,
                data.email,
                data.companyCity,
                data.companyCountry
              )
          );

          const { successParticipants, skippedCount, failedParticipants } = this.filterParticipants(
            mappedContacts,
            externalParticipants
          );

          const context = <FailedParticipantsInformation>{
            participantsType: ParticipantEntityType.Contact,
            participants: failedParticipants.map(d =>
              this.participantValidation.toMissingFieldsContactModel(
                <ContactExternalParticipant>d.participant,
                d.fields
              )
            ),
            totalCount: list.itemsCount,
            skippedCount
          };

          return [
            new dialogActions.ExternalParticipantsLoaded(),
            new eventActivityActions.AddExternalParticipants(successParticipants),
            new dialogActions.UpdateFailedParticipants(context)
          ];
        })
      );
    })
  );

  @Effect()
  AddInstitutionsFromList$ = this.actions$.pipe(
    ofType<dialogActions.SubmitExternalParticipantsList>(
      dialogActions.SUBMIT_EXTERNAL_PARTICIPANTS_LIST
    ),
    map(action => action.payload),
    filter(list => list.type === ListType.Account),
    withLatestFrom(this.store.pipe(select(fromEventActivity.getExternalParticipants))),
    switchMap(([list, externalParticipants]) =>
      this.listsService.getInstitutionsFromList(list.id).pipe(
        switchMap((response: InstitutionListItemsSearchResponse) => {
          const mappedInstitutions = response.data.map(
            data =>
              new InstitutionExternalParticipant(
                data.prId,
                data.crmId,
                data.name,
                null,
                null,
                data.city,
                data.country
              )
          );

          const { successParticipants } = this.filterParticipants(
            mappedInstitutions,
            externalParticipants
          );


          return this.mapHasContact(successParticipants);
        })
      )
    )
  );

  @Effect()
  ToggleListsFiltered$ = this.actions$.pipe(
    ofType<dataActions.LoadLists>(dataActions.LOAD_LISTS),
    map(action => action.payload),
    withLatestFrom(this.store.pipe(select(fromDialog.getListsFiltered))),
    filter(([text, filtered]) => (!text && filtered) || (text && !filtered)),
    map(() => new dialogActions.ToggleListsFiltered())
  );

  private mapHasContact(participants: ExternalParticipant[]) {
    const ids = participants.map(institution => institution.company.id);

    return this.eventService.getHasContacts(ids).pipe(
      mergeMap(result => {
        const institutionsWithHasContacts = participants.map(
          (d: InstitutionExternalParticipant) => {
            d.setHasContacts(!!result.find(a => a.prId === d.company.id.prId));
            return d;
          }
        );

        return [
          new dialogActions.ExternalParticipantsLoaded(),
          new eventActivityActions.AddExternalParticipants(institutionsWithHasContacts)
        ];
      })
  );
  }

  private filterParticipants(
    participants: ExternalParticipant[],
    existingParticipants: ExternalParticipant[]
  ) {
    const failedParticipants: {
      fields: MissingField[];
      participant: ExternalParticipant;
    }[] = [];

    let skippedCount = 0;
    const successParticipants: ExternalParticipant[] = [];

    participants.forEach(participant => {
      const missingFields = this.participantValidation.getMissingFields(participant);
      if (missingFields.length > 0) {
        failedParticipants.push({
          fields: missingFields,
          participant
        });
      } else if (existingParticipants.some(p => isEqualParticipants(p, participant))) {
        skippedCount++;
      } else {
        successParticipants.push(participant);
      }
    });

    return {
      successParticipants,
      failedParticipants,
      skippedCount
    };
  }
}

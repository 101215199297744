import { ExternalParticipant } from './participant/external-participant.model';
import { UpdateStatusModel } from './participant/update-status-model';

interface ExternalParticipantPopoverResponseTemplate<TAction, TEntity> {
  action: TAction;
  entity: TEntity;
}

export type ExternalParticipantPopoverResponse =
  ExternalParticipantPopoverResponseTemplate<PopoverResponseAction.Add, ExternalParticipant> |
  ExternalParticipantPopoverResponseTemplate<PopoverResponseAction.Update, UpdateStatusModel> |
  ExternalParticipantPopoverResponseTemplate<PopoverResponseAction.Remove, ExternalParticipant>;

export enum PopoverResponseAction {
  Update,
  Add,
  Remove
}

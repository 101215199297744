import { ActivitySubType } from './activity-type.enum';
import { ActivityInitiator } from './activity-initiator.enum';
import { ActivityMethod } from './activity-method.enum';
import { ActivityMode } from './activity-mode.enum';

export const RIXMLFieldsLocalization = {
  [ActivitySubType.OneOnOne]: 'activity_sub_type_one_to_one',
  [ActivitySubType.TwoOnOne]: 'activity_sub_type_two_to_one',
  [ActivitySubType.ThreeOnOne]: 'activity_sub_type_three_to_one',
  [ActivitySubType.Group]: 'activity_sub_type_group',
  [ActivitySubType.None]: 'activity_sub_type_none',
  [ActivityInitiator.Corporate]: 'activity_initiator_corporate',
  [ActivityInitiator.Consumer]: 'activity_initiator_consumer',
  [ActivityInitiator.Provider]: 'activity_initiator_provider',
  [ActivityMethod.Interaction]: 'activity_method_interaction',
  [ActivityMethod.Electronic]: 'activity_method_electronic',
  [ActivityMode.Inperson]: 'activity_mode_in_person',
  [ActivityMode.Call]: 'activity_mode_call',
  [ActivityMode.VoiceMail]: 'activity_mode_voicemail',
  [ActivityMode.Video]: 'activity_mode_video',
  [ActivityMode.Email]: 'activity_mode_email',
  'N/A': 'activity_initiator_undefined'
};

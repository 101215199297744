import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as fromModels from '../../models';

@Component({
  selector: 'ea-rixml-fields-selector',
  templateUrl: './rixml-field-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RixmlFieldSelectorComponent implements OnInit {
  @Input() title: string;
  @Input()
  options:
    | fromModels.ActivityInitiator[]
    | fromModels.ActivityMode[]
    | fromModels.ActivitySubType[];
  @Input()
  selected: fromModels.ActivityInitiator | fromModels.ActivityMode | fromModels.ActivitySubType;
  @Input() placeholder: string;
  @Output() changed = new EventEmitter();

  localizationKeys = fromModels.RIXMLFieldsLocalization;
  select = new FormControl();

  ngOnInit() {
    this.select.setValue(this.selected);

    if (this.options && this.options.length < 2) {
      this.select.disable();
    }
  }

  onChange() {
    const value = (this.select.value === 'null' || this.select.value === 'undefined') ?
        null :
        this.select.value;
    this.changed.emit(value);
  }
}

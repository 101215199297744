import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { EntityId, EntityType, CompositeId } from '../models';
import { State, getBillfoldHost, getDefaultToNewProfiles } from '../../store';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class LinkService {
  private readonly contactProfileUrl = '/UI/Profile/Contact/Default.aspx';
  private readonly institutionProfileUrl = '/UI/Profile/Institution/Default.aspx';

  private _host: Observable<string>;
  private _defaultToNewProfiles: Observable<boolean>;

  constructor(store: Store<State>) {
    this._host = store.pipe(select(getBillfoldHost));
    this._defaultToNewProfiles = store.pipe(select(getDefaultToNewProfiles));
  }

  public buildProfileLink(entityId: EntityId): Observable<string> {
    if (!entityId || !entityId.id || (!entityId.id.prId && !entityId.id.crmId)) {
      return of('');
    }

    const prId = entityId.id.prId || '';
    const crmId = entityId.id.crmId || '';

    switch (entityId.type) {
      case EntityType.Contact:
        return this.getFullLink(`${this.contactProfileUrl}?ProfileId=${prId}|${crmId}`);
      case EntityType.Institution:
        return this.getFullLink(`${this.institutionProfileUrl}?ProfileId=${prId}|${crmId}`);
      default:
        return of('');
    }
  }

  public getEditContactUrl(id: CompositeId, editFields: { contact: string[] }) {
    const prId = id.prId || '';
    const crmId = id.crmId || '';

    return this._defaultToNewProfiles.pipe(
      mergeMap((defaultToNewProfiles) => {
        if (defaultToNewProfiles) {
          let relativeUrl = `/pf-contact/#/${prId}|${crmId}/information?profileType=contact`;
          relativeUrl = `${relativeUrl}&editContact=${editFields.contact.join(',')}`;

          return this.getFullLink(relativeUrl);
        } else {
          return this.getFullLink(`/${this.contactProfileUrl}?ProfileId=${prId}|${crmId}`);
        }
      })
    );
  }

  isProfileAvailable(id: CompositeId): boolean {
    return !!id.crmId || !!id.prId;
  }

  private getFullLink(relativeUrl: string): Observable<string> {
    return this._host.pipe(map(host => `https://${host}${relativeUrl}`));
  }
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, map, switchMap, withLatestFrom, mergeMap } from 'rxjs/operators';
import { EventActivityService } from '../../services';
import * as dialogActions from '../actions/dialog-view.action';
import * as eventActivityActions from '../actions/event-activity.action';
import * as dataActions from '../actions/event-activity-data.action';
import * as fromEventActivity from '../selectors/event-activity.selector';
import * as fromDialog from '../selectors/dialog-view.selector';
import { ContactExternalParticipant, InstitutionExternalParticipant, ListType, EntityType as ParticipantEntityType, isEqualParticipants } from '../../models';
import { ListsService, ActivityParticipantValidationService } from '../../services';
var ParticipantListEffect = /** @class */ (function () {
    function ParticipantListEffect(actions$, store, listsService, eventService, participantValidation) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.listsService = listsService;
        this.eventService = eventService;
        this.participantValidation = participantValidation;
        this.AddContactsFromList$ = this.actions$.pipe(ofType(dialogActions.SUBMIT_EXTERNAL_PARTICIPANTS_LIST), map(function (action) { return action.payload; }), filter(function (list) { return list.type === ListType.Contact; }), withLatestFrom(this.store.pipe(select(fromEventActivity.getExternalParticipants))), switchMap(function (_a) {
            var list = _a[0], externalParticipants = _a[1];
            return _this.listsService.getContactsFromList(list.id).pipe(mergeMap(function (response) {
                var mappedContacts = response.data.map(function (data) {
                    return new ContactExternalParticipant(data.ipreoContactId, data.contactId, data.firstName + " " + data.lastName, data.companyName, data.ipreoCompanyId, data.companyId, data.firstName, data.lastName, null, data.mobilePhone, data.phone, data.email, data.companyCity, data.companyCountry);
                });
                var _a = _this.filterParticipants(mappedContacts, externalParticipants), successParticipants = _a.successParticipants, skippedCount = _a.skippedCount, failedParticipants = _a.failedParticipants;
                var context = {
                    participantsType: ParticipantEntityType.Contact,
                    participants: failedParticipants.map(function (d) {
                        return _this.participantValidation.toMissingFieldsContactModel(d.participant, d.fields);
                    }),
                    totalCount: list.itemsCount,
                    skippedCount: skippedCount
                };
                return [
                    new dialogActions.ExternalParticipantsLoaded(),
                    new eventActivityActions.AddExternalParticipants(successParticipants),
                    new dialogActions.UpdateFailedParticipants(context)
                ];
            }));
        }));
        this.AddInstitutionsFromList$ = this.actions$.pipe(ofType(dialogActions.SUBMIT_EXTERNAL_PARTICIPANTS_LIST), map(function (action) { return action.payload; }), filter(function (list) { return list.type === ListType.Account; }), withLatestFrom(this.store.pipe(select(fromEventActivity.getExternalParticipants))), switchMap(function (_a) {
            var list = _a[0], externalParticipants = _a[1];
            return _this.listsService.getInstitutionsFromList(list.id).pipe(switchMap(function (response) {
                var mappedInstitutions = response.data.map(function (data) {
                    return new InstitutionExternalParticipant(data.prId, data.crmId, data.name, null, null, data.city, data.country);
                });
                var successParticipants = _this.filterParticipants(mappedInstitutions, externalParticipants).successParticipants;
                return _this.mapHasContact(successParticipants);
            }));
        }));
        this.ToggleListsFiltered$ = this.actions$.pipe(ofType(dataActions.LOAD_LISTS), map(function (action) { return action.payload; }), withLatestFrom(this.store.pipe(select(fromDialog.getListsFiltered))), filter(function (_a) {
            var text = _a[0], filtered = _a[1];
            return (!text && filtered) || (text && !filtered);
        }), map(function () { return new dialogActions.ToggleListsFiltered(); }));
    }
    ParticipantListEffect.prototype.mapHasContact = function (participants) {
        var ids = participants.map(function (institution) { return institution.company.id; });
        return this.eventService.getHasContacts(ids).pipe(mergeMap(function (result) {
            var institutionsWithHasContacts = participants.map(function (d) {
                d.setHasContacts(!!result.find(function (a) { return a.prId === d.company.id.prId; }));
                return d;
            });
            return [
                new dialogActions.ExternalParticipantsLoaded(),
                new eventActivityActions.AddExternalParticipants(institutionsWithHasContacts)
            ];
        }));
    };
    ParticipantListEffect.prototype.filterParticipants = function (participants, existingParticipants) {
        var _this = this;
        var failedParticipants = [];
        var skippedCount = 0;
        var successParticipants = [];
        participants.forEach(function (participant) {
            var missingFields = _this.participantValidation.getMissingFields(participant);
            if (missingFields.length > 0) {
                failedParticipants.push({
                    fields: missingFields,
                    participant: participant
                });
            }
            else if (existingParticipants.some(function (p) { return isEqualParticipants(p, participant); })) {
                skippedCount++;
            }
            else {
                successParticipants.push(participant);
            }
        });
        return {
            successParticipants: successParticipants,
            failedParticipants: failedParticipants,
            skippedCount: skippedCount
        };
    };
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ParticipantListEffect.prototype, "AddContactsFromList$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ParticipantListEffect.prototype, "AddInstitutionsFromList$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ParticipantListEffect.prototype, "ToggleListsFiltered$", void 0);
    return ParticipantListEffect;
}());
export { ParticipantListEffect };

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DialogService } from '../../services/dialog.service';
import * as dialogActions from '../actions/dialog.action';
import { tap } from 'rxjs/operators';

@Injectable()
export class DialogEffects {

  constructor(private actions$: Actions, private dialogService: DialogService) {}

  @Effect({ dispatch: false })
  closeDialog$ = this.actions$.pipe(
    ofType(dialogActions.CLOSE_DIALOG),
    tap((action: dialogActions.CloseDialog<object>) =>
      this.dialogService.closeDialog(action.action, action.payload))
  );

}

import { createSelector } from '@ngrx/store';
import { get } from 'lodash';
import {
  ActivityInitiator,
  ActivityMode,
  ActivitySubType,
  ActivityTemplate,
  activityTypeOptions,
  EventActivity,
} from '../../models';
import { getEventActivityEntity } from './event-activity.selector';
import { getUserProfile } from '../../../store/selectors';
import { UserProfile } from '../../../models';
import { ActivityTypePermissions } from '../../models/activity-type/activity-type-permissions';
import { getActivityTemplate } from '../reducers/event-activity.reducer';

export const getEventActivityMethod = createSelector(
  getEventActivityEntity,
  entity => entity && entity.activityMethod
);
export const getEventActivitySubType = createSelector(
  getEventActivityEntity,
  entity => entity && entity.activityType
);

export const getEventActivityMode = createSelector(
  getEventActivityEntity,
  entity => entity && entity.activityMode
);

export const getEventActivityInitiator = createSelector(
  getEventActivityEntity,
  entity => entity && entity.initiator
);

export const getEventActivityTemplate = createSelector(
  getEventActivityEntity,
  (entity: EventActivity) =>
    entity &&
    getActivityTemplate(entity.activityMode, entity.activityMethod, entity.activityType, entity.initiator)
);

export const getActivityTemplates = createSelector(
  getUserProfile,
  (userProfile: UserProfile) =>
    activityTypeOptions.map((option: ActivityTemplate) => {
      const permissions = get(ActivityTypePermissions, option.id);

      if (permissions && !get(userProfile, permissions)) {
        return { ...option, hidden: true };
      }

      return option;
    })
);

export const getSubTypeOptions = createSelector(
  getEventActivityTemplate,
  (template: ActivityTemplate) => template.subTypes
);

export const getModeOptions = createSelector(
  getEventActivityTemplate,
  (template: ActivityTemplate) => template.modes
);

export const getInitiatorOptions = createSelector(
  getEventActivityTemplate,
  (template: ActivityTemplate) => template.initiators
);

export const getShowChangeButton = createSelector(
  getSubTypeOptions,
  getModeOptions,
  getInitiatorOptions,
  (
    subTypes: ActivitySubType[],
    modes: ActivityMode[],
    initiators: ActivityInitiator[]
  ) => {
    return (
      (subTypes && subTypes.length > 1) ||
      (modes && modes.length > 1) ||
      (initiators && initiators.length > 1)
    );
  }
);

import { Injectable } from '@angular/core';
import { ActivityLocation, LocationModel, SavedLocationItemModel } from '../models';

@Injectable()
export class LocationMapperService {

  toActivityLocation(viewModel: SavedLocationItemModel): ActivityLocation {
    const location = { ...viewModel };
    delete location.locationName;
    delete location.placeId;
    delete location.description;

    return location as ActivityLocation;
  }

  toSavedLocation(viewModel: SavedLocationItemModel): LocationModel {
    const location = { ...viewModel };
    delete location.placeId;
    delete location.description;

    return location as LocationModel;
  }

  fromLocationModel(source: LocationModel): ActivityLocation {
    const location = {...source };
    delete location.locationName;
    delete location.venueType;

    return location as ActivityLocation;
  }

  fromActivityLocation(source: ActivityLocation, name: string): LocationModel {
    const location = {
      ...source,
      locationName: name,
      venueType: 'Onsite'
     };
     delete location.activityId;
     delete location.activityLocationId;

     return location as LocationModel;
  }
}

import { Action } from '@ngrx/store';
import { ActivityDefinedField } from '../../models/activity-defined-field/activity-defined-field.model';
import { FailAction } from '../../../store/actions/fail-action';

export const CHECK_HAS_ANY = '[ADF] Check Has Any';
export const CHECK_HAS_ANY_SUCCESS = '[ADF] Check Has Any Success';
export const CHECK_HAS_ANY_FAIL = '[ADF] Check Has Any Fail';

export const LOAD_ACTIVITY_DEFINED_FIELDS = '[ADF] Load';
export const LOAD_ACTIVITY_DEFINED_FIELDS_SUCCESS = '[ADF] Load Success';
export const LOAD_ACTIVITY_DEFINED_FIELDS_FAIL = '[ADF] Load Fail';

export const SET_SELECTED_ACTIVITY_DEFINED_FIELDS = '[ADF] Set Selected Activity Defined Fields';
export const SELECT_ACTIVITY_DEFINED_FIELD = '[ADF] Select Activity Defined Fields';
export const UPDATE_ACTIVITY_DEFINED_FIELD = '[ADF] Update Activity Defined Field';
export const HIDE_ACTIVITY_DEFINED_FIELD = '[ADF] Hide Activity Defined Field';

export class CheckHasAny implements Action {
  readonly type = CHECK_HAS_ANY;
}

export class CheckHasAnySuccess implements Action {
  readonly type = CHECK_HAS_ANY_SUCCESS;

  constructor(public payload: { hasAny: boolean }) {}
}

export class CheckHasAnyFail extends FailAction {
  readonly type = CHECK_HAS_ANY_FAIL;

  constructor(public error: any) {
    super();
  }
}

export class LoadActivityDefinedFields implements Action {
  readonly type = LOAD_ACTIVITY_DEFINED_FIELDS;
}

export class LoadActivityDefinedFieldsSuccess implements Action {
  readonly type = LOAD_ACTIVITY_DEFINED_FIELDS_SUCCESS;

  constructor(public payload: { fields: ActivityDefinedField[] }) {}
}

export class LoadActivityDefinedFieldsFail extends FailAction {
  readonly type = LOAD_ACTIVITY_DEFINED_FIELDS_FAIL;

  constructor(public error: any) {
    super();
  }
}

export class SetSelectedActivityDefinedFields implements Action {
  readonly type = SET_SELECTED_ACTIVITY_DEFINED_FIELDS;

  constructor(public payload: { fields: ActivityDefinedField[] }) {}
}

export class SelectActivityDefinedField implements Action {
  readonly type = SELECT_ACTIVITY_DEFINED_FIELD;

  constructor(public payload: { fieldId: number }) {}
}

export class UpdateActivityDefinedField implements Action {
  readonly type = UPDATE_ACTIVITY_DEFINED_FIELD;

  constructor(public payload: { field: ActivityDefinedField }) {}
}

export class HideActivityDefinedField implements Action {
  readonly type = HIDE_ACTIVITY_DEFINED_FIELD;

  constructor(public payload: { fieldId: number }) {}
}

export type ActivityDefinedFieldAction =
  | CheckHasAny
  | CheckHasAnySuccess
  | CheckHasAnyFail
  | LoadActivityDefinedFields
  | LoadActivityDefinedFieldsSuccess
  | LoadActivityDefinedFieldsFail
  | SetSelectedActivityDefinedFields
  | SelectActivityDefinedField
  | UpdateActivityDefinedField
  | HideActivityDefinedField;

<div class="c-p-vertical-sm attachments" data-automation-id="attachments">
    <kendo-upload
    class="c-kendo-uploader"
    [withCredentials]="false"
    [accept]="acceptList"
    [saveUrl]="'uploadSaveUrl'"
    [saveField]="'file'"
    [batch]="true"
    [ngModel]="attachmentsFileInfo$ | async"
    (upload)="onUpload($event)"
    >
      <kendo-upload-messages
      cancel="{{ 'bd/event-activity/form.attachmentCancel' | translate }}"
      dropFilesHere="{{ 'bd/event-activity/form.attachmentDropFilesHere' | translate }}"
      headerStatusUploaded="{{ 'bd/event-activity/form.attachmentDropFilesHere' | translate }}"
      remove="{{ 'bd/event-activity/form.attachmentRemove' | translate }}"
      select="{{ 'bd/event-activity/form.attachmentSelect' | translate }}"
      >
  </kendo-upload-messages>
  <ng-template kendoUploadFileTemplate let-files let-state="state">
    <ea-attachment-list-item
    class="c-d-flex c-full-width c-justify-space-between"
    [file]="files[0]"
    (download)="onDownload($event)"
    (remove)="onRemove($event)"
    >
    </ea-attachment-list-item>
  </ng-template>
</kendo-upload>
<div class='c-text-warning c-m-top-xs' *ngIf="partialError$ | async">
  {{ 'bd/event-activity/form.attachments_saving_error_message' | translate }}
</div>
</div>

<div class='c-alert c-alert-danger c-alert-top c-alert-has-icon c-m-bottom-sm' *ngIf="errorMessage$ | async as errorMessage">
  <i aria-hidden="true" class="fa fa-exclamation-circle c-alert-icon">
  </i>
  <div [innerHTML]="errorMessage">
  </div>
</div>

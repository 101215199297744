import { Contact, Institution, InstitutionDto, ContactDto } from '@ipreo/bd-external-participants';
import { sortBy, uniq } from 'lodash';
import * as eventActivityActions from '../actions/event-activity.action';
import {
  defaultDuration,
  Duration,
  EventActivity,
  maxLengthLimit,
  minLengthLimit,
  Error,
  isEqualParticipants,
  ErrorState,
  SaveErrorEntities,
  ChangeEntity,
  ActivitySavingError,
  ActivityTemplateCode,
  ActivityMode,
  ActivityMethod,
  ActivitySubType,
  ActivityInitiator,
  ActivityTemplate,
  activityTypeOptions,
  otherType
} from '../../models';
import { HttpStatusCodes } from '../../models/http-status-codes';
import { LocationType } from '../../models/saved-locations/location-type';

export interface State {
  entity: EventActivity;
  loading: boolean;
  loaded: boolean;
  error: ErrorState;
  previousStartDate?: Date;
}

export const initialState = (): State => ({
  entity: null,
  loading: false,
  loaded: false,
  error: {
    type: Error.None,
    failedEntities: {
      [ChangeEntity.Adf]: false,
      [ChangeEntity.ExternalParticipant]: false,
      [ChangeEntity.InternalParticipant]: false,
      [ChangeEntity.Location]: false,
      [ChangeEntity.Activity]: false,
      [ChangeEntity.Attachment]: false,
      [ChangeEntity.Security]: false,
      [ChangeEntity.AddressComment]: false
    }}
});

export function reducer(
  state = initialState(),
  action: eventActivityActions.EventActivityAction
): State {
  switch (action.type) {
    case eventActivityActions.INIT_NEW:
    case eventActivityActions.LOAD_EVENT_ACTIVITY_MODEL: {
      return {
        ...state,
        loading: true
      };
    }

    case eventActivityActions.LOAD_EVENT_ACTIVITY_MODEL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: {...state.error, type: Error.None}
      };
    }

    case eventActivityActions.LOAD_EVENT_ACTIVITY: {
      return {
        ...state,
        entity: {
          ...action.payload,
          attachments: []
        }
      };
    }

    case eventActivityActions.LOAD_EVENT_ACTIVITY_MODEL_FAIL: {
      return {
        ...state,
        loading: false,
        error: {...state.error, type: mapErrorCode(action.payload)}
      };
    }

    case eventActivityActions.SAVE_EVENT_ACTIVITY: {
      return {
        ...state,
        loading: true
      };
    }

    case eventActivityActions.SAVE_EVENT_ACTIVITY_SUCCESS: {
      return action.payload.savingErrors.length > 0 ? {
        ...state,
        loading: false,
        error: {
          ...state.error,
          type: Error.PartialSaveError,
          failedEntities: mapFailedEntities(action.payload.savingErrors)
        },
        entity: {
          ...action.payload.activity,
          attachments: []
        }
      } : state;
    }

    case eventActivityActions.SAVE_EVENT_ACTIVITY_FAIL: {
      return {
        ...state,
        loading: false,
        error: {...state.error, type: mapErrorCode(action.payload)}
      };
    }

    case eventActivityActions.UPDATE_SUBJECT: {
      return {
        ...state,
        entity: {
          ...state.entity,
          subject: action.payload
        }
      };
    }

    case eventActivityActions.UPDATE_START_DATE: {
      return {
        ...state,
        entity: {
          ...state.entity,
          date: action.payload
        }
      };
    }

    case eventActivityActions.UPDATE_END_DATE: {
      return {
        ...state,
        entity: {
          ...state.entity,
          endDate: action.payload
        }
      };
    }

    case eventActivityActions.UPDATE_DURATION: {
      return mapDuration(state, action);
    }

    case eventActivityActions.UPDATE_INTERNAL_PARTICIPANTS: {
      return {
        ...state,
        entity: {
          ...state.entity,
          internalParticipants: action.payload
        }
      };
    }

    case eventActivityActions.UPDATE_LOCATION: {
      return {
        ...state,
        entity: {
          ...state.entity,
          location: action.payload && {
            ...action.payload,
            activityId: state.entity.activityId,
            activityLocationId: state.entity.location && state.entity.location.activityLocationId
          }
        }
      };
    }

    case eventActivityActions.UPDATE_EVENT_LOCATION: {
      return {
        ...state,
        entity: {
          ...state.entity,
          scheduleId: action.payload.scheduleId,
          timeZone: action.payload.timeZone || state.entity.timeZone
        }
      };
    }

    case eventActivityActions.UPDATE_ACTIVITY_TEMPLATE: {
      return {
        ...state,
        entity: {
          ...state.entity,
          activityMode: action.payload.defaultMode,
          activityType: action.payload.defaultSubType,
          activityMethod: action.payload.method,
          initiator: action.payload.initiators ? action.payload.defaultInitiator : null
        }
      };
    }

    case eventActivityActions.UPDATE_ACTIVITY_INITIATOR: {
      return {
        ...state,
        entity: {
          ...state.entity,
          initiator: action.payload
        }
      };
    }

    case eventActivityActions.UPDATE_ACTIVITY_MODE: {
      return {
        ...state,
        entity: {
          ...state.entity,
          activityMode: action.payload
        }
      };
    }

    case eventActivityActions.UPDATE_ACTIVITY_SUB_TYPE: {
      return {
        ...state,
        entity: {
          ...state.entity,
          activityType: action.payload
        }
      };
    }

    case eventActivityActions.ADD_EXTERNAL_PARTICIPANTS: {
      const newParticipants = action.payload.filter(
        d => !state.entity.externalParticipants.find(a => isEqualParticipants(d, a))
      );
      return {
        ...state,
        entity: {
          ...state.entity,
          externalParticipants: [...state.entity.externalParticipants, ...newParticipants]
        }
      };
    }

    case eventActivityActions.UPDATE_EXTERNAL_PARTICIPANTS: {
      return {
        ...state,
        entity: {
          ...state.entity,
          externalParticipants: [...action.payload]
        }
      };
    }

    case eventActivityActions.UPDATE_EXTERNAL_PARTICIPANT_STATUS: {
      const participant = state.entity.externalParticipants.find(
        d => d === action.payload.participant
      );
      const otherParticipants = state.entity.externalParticipants.filter(
        d => d !== action.payload.participant
      );

      return {
        ...state,
        entity: {
          ...state.entity,
          externalParticipants: [
            ...otherParticipants,
            { ...participant, status: action.payload.newStatus }
          ]
        }
      };
    }

    case eventActivityActions.DELETE_EXTERNAL_PARTICIPANT: {
      return {
        ...state,
        entity: {
          ...state.entity,
          externalParticipants: state.entity.externalParticipants.filter(
            d => !isEqualParticipants(action.payload, d)
          )
        }
      };
    }

    case eventActivityActions.CHANGE_EXTERNAL_PARTICIPANTS_COMPANY_CONTACTS: {
      const otherParticipants = state.entity.externalParticipants.filter(
        d => !d.contact || (d.company && d.company.id.prId !== action.payload.id.prId)
      );

      return {
        ...state,
        entity: {
          ...state.entity,
          externalParticipants: [...otherParticipants, ...action.payload.participants]
        }
      };
    }

    case eventActivityActions.UPDATE_NOTES: {
      return {
        ...state,
        entity: {
          ...state.entity,
          comment: action.payload
        }
      };
    }

    case eventActivityActions.DELETE_ACTIVITY_FAIL: {
      return {
        ...state,
        error: {...state.error, type: mapErrorCode(action.payload)}
      };
    }

    case eventActivityActions.RESET_PARTIAL_ERRORS: {
      const entities = {...state.error.failedEntities, [action.payload] : false};
      return {
        ...state,
        error: {...state.error,
          type: Object.values(entities).every(x => !x) ? Error.None : Error.PartialSaveError,
          failedEntities: entities
        }
      };
    }

    case eventActivityActions.UPDATE_TIMEZONE: {
      return {
        ...state,
        entity: {
          ...state.entity,
          timeZone: action.payload
        }
      };
    }

    case eventActivityActions.UPDATE_ADDRESS_COMMENT: {
      return {
        ...state,
        entity: {
          ...state.entity,
          addressComment: {
            ...state.entity.addressComment,
            value: action.payload
          }
        }
      };
    }

    case eventActivityActions.UPDATE_ONLINE_MEETING_LINK: {
      const newMode = state.entity && getNewActivityMode(state.entity, action.payload);
      return {
        ...state,
        entity: {
          ...state.entity,
          onlineMeetingLink: action.payload,
          activityMode: newMode
        }
      };
    }

    case eventActivityActions.UPDATE_ONLINE_MEETING_PASSWORD: {
      return {
        ...state,
        entity: {
          ...state.entity,
          onlineMeetingPassword: action.payload
        }
      };
    }
  }

  return state;
}

function mapErrorCode(errorCode: number): Error {
  switch (errorCode) {
    case HttpStatusCodes.BadRequest:
      return Error.BadRequest;
    case HttpStatusCodes.NotFound:
      return Error.NotFound;
    case HttpStatusCodes.InternalError:
      return Error.InternalError;
    default:
      return Error.Unknown;
  }
}

function mapFailedEntities(savingErrors: ActivitySavingError[]): SaveErrorEntities {
  // partial save error when Activity wasn`t updated is not handled
  const entities = uniq(savingErrors.map(e => e.entity).filter(entity => entity !== ChangeEntity.Activity));
  const result: SaveErrorEntities = {
    [ChangeEntity.Adf]: false,
    [ChangeEntity.ExternalParticipant]: false,
    [ChangeEntity.InternalParticipant]: false,
    [ChangeEntity.Location]: false,
    [ChangeEntity.Activity]: false,
    [ChangeEntity.Attachment]: false,
    [ChangeEntity.Security]: false,
    [ChangeEntity.AddressComment]: false
  };
  entities.forEach(entity => result[entity] = true);
  return result;
}

function mapDuration(state: State, action: eventActivityActions.UpdateDuration): State {
  let date = state.entity.date;

  if (
    <Duration>action.payload !== Duration.AllDay &&
    state.entity.duration === Duration.AllDay &&
    state.previousStartDate
  ) {
    date = state.previousStartDate;
  }

  if (action.payload === Duration.EndDate) {
    const endDate = new Date(+date);
    return {
      ...state,
      entity: {
        ...state.entity,
        date,
        duration: action.payload,
        endDate: new Date(endDate.setMinutes(endDate.getMinutes() + defaultDuration))
      }
    };
  }

  if (action.payload === Duration.AllDay) {
    date = new Date(
      state.entity.date.getFullYear(),
      state.entity.date.getMonth(),
      state.entity.date.getDate(),
      0,
      0
    );

    return {
      ...state,
      entity: {
        ...state.entity,
        duration: action.payload,
        date,
        endDate: null
      },
      previousStartDate: new Date(+state.entity.date)
    };
  }

  return {
    ...state,
    entity: {
      ...state.entity,
      date,
      endDate: null,
      duration: action.payload
    }
  };
}

function getNewActivityMode(activity: EventActivity, newLink: string) {
  const currentLink = activity.onlineMeetingLink;
  const activityTemplate = getActivityTemplate(
    activity.activityMode, activity.activityMethod, activity.activityType, activity.initiator);

  if ([ActivityTemplateCode.OneOnOne, ActivityTemplateCode.GroupMeeting].includes(activityTemplate.id)) {
    if (!!newLink && !currentLink) {
      return ActivityMode.Video;
    } else if (!newLink && !!currentLink) {
      return ActivityMode.Inperson;
    }
  }

  return activity.activityMode;
}

export function getActivityTemplate(
  mode: ActivityMode,
  method: ActivityMethod,
  subType: ActivitySubType,
  initiator: ActivityInitiator = null
): ActivityTemplate {
  return activityTypeOptions.find(
    d =>
      d.modes && d.modes.includes(mode) &&
      d.subTypes && d.subTypes.includes(subType) &&
      d.method === method &&
      ((!d.initiators && !initiator) || d.initiators.includes(initiator))
  ) || otherType;
}

export const getEventActivityEntity = (state: State) => state.entity;
export const getEventActivityLoading = (state: State) => state.loading;
export const getEventActivityLoaded = (state: State) => state.loaded;
export const getIsNewActivity = (state: State) => !state.entity || !state.entity.activityId;
export const getEventActivityDuration = (state: State) => state.entity.duration;

export const getEventActivitySubjectIsValid = (state: State) => {
  const subject = state.entity && state.entity.subject;

  return !subject || (subject.length >= minLengthLimit && subject.length <= maxLengthLimit);
};

export const getEventActivityStartDateIsValid = (state: State) => {
  return !!state.entity && !!state.entity.date;
};

export const getEventActivityEndDateIsValid = (state: State) => {
  return !!state.entity && (!isEndDateSelected(state.entity.duration) || !!state.entity.endDate);
};

export const getEventActivityDateRangeIsValid = (state: State) => {
  return (
    getEventActivityStartDateIsValid(state) &&
    getEventActivityEndDateIsValid(state) &&
    (!isEndDateSelected(state.entity.duration) || +state.entity.endDate > +state.entity.date)
  );
};

function isEndDateSelected(duration: Duration) {
  return duration === Duration.EndDate;
}

export const getEventActivityVirtualMeetingLinkIsValid = (state: State) => {
  const link = state.entity && state.entity.onlineMeetingLink;

  return !link || link.length <= 1000;
};

export const getEventActivityVirtualMeetingPasswordIsValid = (state: State) => {
  const password = state.entity && state.entity.onlineMeetingPassword;

  return !password || password.length <= 200;
};

export const getExternalParticipants = (entity: EventActivity) => {
  return (
    entity &&
    sortBy(entity.externalParticipants || [], d => [
      d.company && d.company.name && d.company.name.toLowerCase(),
      d.lastName && d.lastName.toLowerCase()
    ])
  );
};

export const getNewExternalParticipantsCount = (entity: EventActivity) =>
  entity && entity.externalParticipants ? entity.externalParticipants.filter(ep => !ep.id).length : 0;


export const getSuggesterExternalParticipants = (entity: EventActivity) => {
  return (
    entity &&
    entity.externalParticipants.map(d => {
      return d.contact
        ? Contact.fromDto(
            <ContactDto>{
              prId: d.contact.id.prId,
              crmId: d.contact.id.crmId,
              isCrm: !!d.contact.id.crmId,
              isPrimary: null,
              name: d.contact.name,
              accountName: d.company.name,
              prCompanyId: d.company.id.prId,
              crmAccountId: d.company.id.crmId,
              firstName: d.firstName,
              lastName: d.lastName,
              phone: d.workPhone,
              mobilePhone: d.mobilePhone,
              email: d.email,
              jobRole: null
            },
            d.companyCountry,
            d.companyCity
          )
        : Institution.fromDto(
            <InstitutionDto>{
              prId: d.company.id.prId,
              crmId: d.company.id.crmId,
              isCrm: !!d.company.id.crmId,
              name: d.company.name,
              equityAssets: null,
              country: d.companyCountry,
              city: d.companyCity
            },
            d.hasContacts
          );
    })
  );
};

export const getIsEventActivity = (entity: EventActivity) => {
  return entity && !!entity.eventId;
};

export const isSavedLocationSelected = (entity: EventActivity) => {
  return entity && !!entity.location && !!entity.location.locationId;
};

export const getLocationType = (entity: EventActivity) => {
  if (!entity || !entity.location) {
    return LocationType.None;
  }
  if (entity.location.locationId) {
    return entity.location.googlePlaceId ? LocationType.ValidSaved : LocationType.InvalidSaved;
  }
  return entity.location.googlePlaceId ? LocationType.ValidOneOff : LocationType.InvalidOneOff;
};

export const getAddressComment = (entity: EventActivity) => {
  return !!entity && entity.addressComment;
};

export const getOnlineMeetingLink = (entity: EventActivity) => {
  return !!entity && entity.onlineMeetingLink || '';
};

export const getOnlineMeetingPassword = (entity: EventActivity) => {
  return !!entity && entity.onlineMeetingPassword || '';
};

import { createSelector } from '@ngrx/store';
import { getEventActivityDialogState } from '../reducers';
import { ActivityAttachment } from '../../models';

import { sortBy } from 'lodash';

export const getActivityAttachmentState = createSelector(
  getEventActivityDialogState,
  state => state.activityAttachments
);

export const getActivityAttachmentErrors = createSelector(
  getActivityAttachmentState,
  state => state.errors
);

export const getActivityAttachments = createSelector(
  getActivityAttachmentState,
  state => sortAttachmentsByCreateDate(state.attachments)
);

function sortAttachmentsByCreateDate(attachments: ActivityAttachment[]): ActivityAttachment[] {
  return  sortBy(attachments, [
    (a: ActivityAttachment) => new Date(a.lastModified).getTime(),
    (a: ActivityAttachment) => a.id
  ]);
}

<div class="c-row c-m-bottom-sm" *ngIf="visible$ | async">
  <div class="c-col-2 c-p-right-0">
    <label class="ea-meeting-label">
      {{ 'bd/event-activity/form.virtual_meeting' | translate }}
    </label>
  </div>
  <div class="c-col-10">
    <div class="c-d-flex">
      <div class="c-p-relative ea-link" [title]="linkValue$ | async">
        <ea-input [placeholder]="'bd/event-activity/form.enter_meeting_link' | translate"
                  [error]="linkError$ | async"
                  [value]="linkValue$ | async"
                  (valueChange)="onLinkChange($event)">
        </ea-input>
      </div>
      <div class="c-m-left-sm ea-password" [title]="passwordValue$ | async">
        <ea-input [placeholder]="'bd/event-activity/form.password' | translate"
                  [error]="passwordError$ | async"
                  [warning]="passwordWarning$ | async"
                  [value]="passwordValue$ | async"
                  (valueChange)="onPasswordChange($event)">
        </ea-input>
      </div>
    </div>
    <div class="c-m-top-xs" *ngIf="showActivityLocationBtn$ | async">
      <button class="c-btn-link c-btn-link-sm c-p-0" (click)="onActivityLocation()">{{ 'bd/event-activity/form.meeting_actual_location' | translate }}</button>
    </div>
  </div>
</div>
export abstract class HttpServiceBase {
    private isDate(value: any): boolean {
        return value instanceof Date && !isNaN(+value);
    }

    private getShiftedDate(date: any) {
        const dateOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() - dateOffset);
    }

    protected correctDates<T>(data: any): T {
        if (data) {
            if (data.constructor === Array) {
                this.correctArray(data);

            } else if (typeof data === 'object') {
                this.correctObject(data);
            }
        }

        return data;
    }

    private correctArray(data: any) {
        let val;
        for (let i = 0; i < data.length; i++) {
            val = data[i];
            if (this.isDate(val)) {
                data[i] = this.getShiftedDate(val);
            } else {
                this.correctDates(val);
            }
        }
    }
    private correctObject(data: any) {
        let val;

        for (const field in data) {
            if (data.hasOwnProperty(field)) {
                val = data[field];
                if (this.isDate(val)) {
                    data[field] = this.getShiftedDate(val);
                } else {
                    this.correctDates(val);
                }
            }
        }
    }
}

<div>{{ title | translate }}</div>
<div class="c-m-bottom-md">
  <select [formControl]="select"
          (change)="onChange()"
          class="c-input">
    <option *ngIf="!!placeholder && !selected" value="undefined" hidden>{{ placeholder | translate }}</option>
    <option *ngFor="let option of options"
            [value]="option">{{ 'bd/event-activity/form.' + localizationKeys[option || 'N/A'] | translate }}
    </option>
  </select>
</div>
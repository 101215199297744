import { Injectable } from '@angular/core';
import { FileInfo } from '@progress/kendo-angular-upload';
import { ActivityDocumentDto, ActivityAttachment } from '../models';

@Injectable()
export class ActivityAttachmentMapper {
  constructor() {}

  fromDto(entity: ActivityDocumentDto): ActivityAttachment {
    return {
      id: entity.id,
      description: entity.description,
      name: entity.name,
      size: entity.size,
      lastModified: entity.lastModified
    };
  }

  toDto(entity: ActivityAttachment): ActivityDocumentDto {
    return <ActivityDocumentDto> {
      id: entity.id,
      description: entity.description,
      name: entity.name,
      size: entity.size,
      lastModified: entity.lastModified
    };
  }

  fromFileInfo(fileInfo: FileInfo): ActivityAttachment {
    return {
      rawFile: fileInfo.rawFile,
      name: fileInfo.name,
      size: fileInfo.size,
      lastModified: (new Date(fileInfo.rawFile.lastModified)).toString()
    };
  }
}

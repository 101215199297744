import { Component, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import { ActivityDefinedField, ActivityDefinedFieldValue } from '../../../models';
import { AdfComponentInterface } from '../adf-component.interface';

@Component({
  selector: 'ea-date-adf',
  templateUrl: './date-adf.component.html',
  styleUrls: ['./date-adf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateAdfComponent implements AdfComponentInterface {
  @ViewChild('datePicker', { static: false }) datePicker: DatePickerComponent;
  @Input()
  public set field(value: ActivityDefinedField) {
    this._field = value;
    if (this._field && this._field.values[0]) {
      this.value = new Date(this._field.values[0].dateValue);
    }
  }
  public get field() {
    return this._field;
  }

  @Output()
  valueChange = new EventEmitter<ActivityDefinedField>();

  value: Date;
  isInvalid: boolean;

  private _field: ActivityDefinedField;

  onValueChange(newValue: Date) {
    this.value = newValue;
  }

  onBlur() {
    this.isInvalid = !this.value && !this.isInputControlEmpty();
    this.valueChange.emit(<ActivityDefinedField>{
      ...this._field,
      values: this.value ? [<ActivityDefinedFieldValue>{ dateValue: this.value.toDateString() }] : []
    });
  }

  private isInputControlEmpty() {
    const input = this.datePicker.input.dateInput.nativeElement as HTMLInputElement;

    return input.value === this.datePicker.placeholder;
  }

}

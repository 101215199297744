import { Injectable } from '@angular/core';
import { FileInfo } from '@progress/kendo-angular-upload';
import { TranslateService } from '@ngx-translate/core';
import { sum } from 'lodash';
import { ActivityAttachment, ALLOWED_FILE_EXTENSIONS } from '../models';

const MAX_FILE_SIZE = 52428800; // 50MB
const MAX_TOTAL_FILE_SIZE = 52428800; // 50Mb

@Injectable()
export class ActivityAttachmentValidationService {
  constructor(private translateService: TranslateService) {}

  public validateFile(file: FileInfo, attachments: ActivityAttachment[] = []): string {
    const name = `<span class="c-text-bold">"${file.name}"</span>`;

    if (this.isRestrictedExtension(file.extension)) {
      return this.translateService.instant(
        'bd/event-activity/form.attachmentInvalidFileExtension', {
          name });
    }
    if (this.isEmptyFile(file.size)) {
      return this.translateService.instant('bd/event-activity/form.attachmentInvalidMinFileSize',
      { name });
    }
    if (this.isOverMaxFileSize(file.size)) {
      return this.translateService.instant('bd/event-activity/form.attachmentInvalidMaxFileSize', {
        name
      });
    }
    if (this.isOverTotalFileSizeMax(file.size, attachments)) {
      return this.translateService.instant('bd/event-activity/form.attachmentInvalidTotalMaxFileSize',
        { name });
    }
    if (this.isFileNameExist(file.name, attachments)) {
      return this.translateService.instant('bd/event-activity/form.attachmentFileAlreadyExist',
        { name });
    }
    return null;
  }

  private isFileNameExist(name: string, attachments: ActivityAttachment[]): boolean {
    return attachments.map(a => a.name).includes(name);
  }

  private isEmptyFile(size: number): boolean {
    return size === 0;
  }

  private isOverMaxFileSize(size: number): boolean {
    return size > MAX_FILE_SIZE;
  }

  private isOverTotalFileSizeMax(size: number, attachments: ActivityAttachment[]): boolean {
    return attachments.length > 0 && sum(attachments.map(at => at.size)) + size > MAX_TOTAL_FILE_SIZE;
  }

  private isRestrictedExtension(extension: string) {
    return !ALLOWED_FILE_EXTENSIONS.includes(extension);
  }
}

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromStore from '../../store';
import * as fromRoot from '../../../store';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { ActivityLocation } from '../../models';
import { LocationType } from '../../models/saved-locations/location-type';

@Component({
  selector: 'ea-activity-location-container',
  templateUrl: './activity-location.component.html',
  styleUrls: ['./activity-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityLocationContainerComponent implements OnInit {
  eventActivityLocationFullAddress$: Observable<string>;
  eventLocationMatched$: Observable<boolean>;
  alert$: Observable<boolean>;
  timeZoneFailed$: Observable<boolean>;
  savedLocationsEnabled$: Observable<boolean>;
  savedLocationSelected$: Observable<boolean>;
  locationType$: Observable<LocationType>;
  addressComment$: Observable<string>;
  address$: Observable<string>;
  showVirtualMeetingBtn$: Observable<boolean>;
  isVirtualEvent$: Observable<boolean>;
  isVisible$: Observable<boolean>;

  constructor(private store: Store<fromRoot.State>) { }

  ngOnInit() {
    this.eventActivityLocationFullAddress$ = this.store.pipe(
      select(fromStore.getEventActivityLocationFullAddress)
    );
    this.eventLocationMatched$ = this.store.pipe(
      select(fromStore.getEventLocationMatched),
      distinctUntilChanged()
    );
    this.alert$ = this.store.pipe(select(fromStore.getSaveActivityLocationError));
    this.timeZoneFailed$ = this.store.pipe(select(fromStore.getTimeZoneFailed));
    this.savedLocationsEnabled$ = this.store.pipe(select(fromRoot.getIsSavedLocationEnabled));
    this.savedLocationSelected$ = this.store.pipe(select(fromStore.isSavedLocationSelected));
    this.locationType$ = this.store.pipe(select(fromStore.getLocationType));
    this.addressComment$ = this.store.pipe(select(fromStore.getAddressComment))
      .pipe(map(d => d && d.value));
    this.address$ = this.store.pipe(
      select(fromStore.getSavedLocationAddress),
    );
    this.isVirtualEvent$ = this.store.pipe(select(fromStore.getIsVirtualEvent));
    this.showVirtualMeetingBtn$ = this.store.pipe(select(fromStore.getVirtualMeetingVisibility),
      withLatestFrom(this.isVirtualEvent$),
      map(([visible, virtualEvent]) => !visible && !virtualEvent)
    );
    this.isVisible$ = this.store.pipe(select(fromStore.getActivityLocationVisibility),
      withLatestFrom(this.isVirtualEvent$),
      map(([visible, virtualEvent]) => visible || !virtualEvent)
    );
  }

  onLocationSelected(location: ActivityLocation) {
    this.store.dispatch(new fromStore.SubmitLocation(location));
  }

  onCommentChange(value: string) {
    this.store.dispatch(new fromStore.UpdateAddressComment(value));
  }

  onVirtualMeeting() {
    this.store.dispatch(new fromStore.SetVirtualMeetingVisibility(true));
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { mapKeys } from 'lodash';
import { LocalizationResource } from './localization-resource.model';
import { LoadLocalizationFail } from '../store/actions/translation.action';
import { State } from '../store/reducers';
import { environment } from '@env/environment';

class IpreoLocalizationLoader implements TranslateLoader {
  constructor(private http: HttpClient, private store: Store<State>) {}

  getTranslation(lang: string): Observable<{ [id: string]: string }> {
    const params = new HttpParams()
      .append('type', 'bd/event-activity/form')
      .append('culture', lang);

    return this.http
      .get(`${environment.domain}/app/bd/localization/api/resources`, { params })
      .pipe(
        map((result: LocalizationResource[]) => {
          return this.mapLocalization(result);
        }),
        catchError((error) => {
          this.store.dispatch(new LoadLocalizationFail(error));

          return this.http.get('/activity/localization.json')
            .pipe(
              map((result: LocalizationResource) => {
                return this.mapLocalization([result]);
              })
            );
        }
      ));
  }

  private mapLocalization(result: LocalizationResource[]) {
    const resources = {};

    result.forEach(res => {
      Object.assign(
        resources,
        mapKeys(res.items, (_: any, key: string) => `${res.type}.${key}`)
      );
    });

    return resources;
  }
}

export function createTranslateLoader(http: HttpClient, store: Store<State>) {
  return new IpreoLocalizationLoader(http, store);
}

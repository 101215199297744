import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { GoogleHostSelectorService, GoogleHostLocation } from './google-host-selector.service';
import { PlaceService } from './place.service';
import { MapsService } from './maps.service';
import { AddressComponentDto } from '../models/hub-place.model';
import { PlaceAutocompletionRequest } from '../models/place-autocompletion-request.model';
import { PlaceAutocompletePrediction } from '../models/place-autocomplete-prediction.model';
import { ActivityLocation } from '../models';
import { PlaceDetails } from '../models/place-details.model';
import { PlaceDetailsRequest } from '../models/place-details-request.model';

@Injectable({
  providedIn: 'root'
})
export class PlaceFacade {

  constructor(
    private googleHostSelector: GoogleHostSelectorService,
    private placeService: PlaceService,
    private mapsService: MapsService
  ) { }

  public getPlacePredictions(
    autocompletionRequest: PlaceAutocompletionRequest
  ): Observable<PlaceAutocompletePrediction[]> {
    return this.googleHostSelector.selectHost().pipe(
      switchMap((hostLocation) => {
        switch (hostLocation) {
          case GoogleHostLocation.China:
            return this.getHubPlacePredictions(autocompletionRequest);
          case GoogleHostLocation.Default:
          default:
            return this.placeService.getPlacePredictions(autocompletionRequest);
        }
      })
    );
  }

  public createAutocompleteSessionToken() {
    return this.placeService.createAutocompleteSessionToken();
  }

  public getDetails(req: PlaceDetailsRequest): Observable<ActivityLocation> {
    return this.googleHostSelector.selectHost().pipe(
      switchMap((hostLocation) => {
        switch (hostLocation) {
          case GoogleHostLocation.China:
            return this.mapsService.getDetails(req.placeId);
          case GoogleHostLocation.Default:
          default:
            return this.placeService.getDetails(req);
        }
      }),
      map(placeDetails => this.getFormattedAddress(placeDetails))
    );
  }

  private getFormattedAddress(place: PlaceDetails): ActivityLocation {
    const components = place.address_components;
    const locationObj = <ActivityLocation>{
      address: place.formatted_address,
      city: this.getLocationComponent(components, 'locality')
        || this.getLocationComponent(components, 'postal_town')
        || this.getLocationComponent(components, 'sublocality_level_1')
        || this.getLocationComponent(components, 'administrative_area_level_3'),
      country: this.getLocationComponent(components, 'country'),
      stateCode: this.getLocationComponent(components, 'administrative_area_level_1', true),
      googlePlaceId: place.place_id,
      venueName: place.name
    };
    return locationObj;
  }

  public getDescription(venueName: string, address: string) {
    const venuePart = address.startsWith(venueName) ? '' : venueName;
    return [venuePart, address].filter(Boolean).join(', ');
  }

  private getLocationComponent(place: AddressComponentDto[], prop: string, isShort: boolean = false): string {
    const x = place.find(a => a.types.indexOf(prop) > -1) || ({} as AddressComponentDto);
    return isShort ? x.short_name : x.long_name || x.short_name;
  }

  private getHubPlacePredictions(req: PlaceAutocompletionRequest): Observable<PlaceAutocompletePrediction[]> {
    const { input, types, sessionToken } = req;

    return this.mapsService
      .autocomplete(input, types, sessionToken as string)
      .pipe(map((dtos) => dtos.map(dto => this.mapPlacePredictionToPlaceResult(dto))));
  }

  private mapPlacePredictionToPlaceResult(
    prediction: google.maps.places.AutocompletePrediction
  ): PlaceAutocompletePrediction {
    return {
      placeId: prediction.place_id,
      name: prediction.structured_formatting.main_text,
      description: prediction.description,
      reference: prediction.reference
    };
  }
}

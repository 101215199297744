import { Injectable } from '@angular/core';
import { CupcakeIntegrationIframeService } from '@ipreo/cupcake-components';
import { CloseDialogAction } from '../models';

@Injectable()
export class DialogService {
  constructor(private iframeService: CupcakeIntegrationIframeService) {}

  closeDialog<T = void>(action: CloseDialogAction = CloseDialogAction.Cancel, result?: T) {
    this.iframeService.sendCommandToHost({action, result});
  }
}

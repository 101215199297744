import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ea-error-handling',
  templateUrl: './error-handling.component.html',
  styleUrls: ['./error-handling.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorHandlingComponent {
  @Output() close = new EventEmitter();
}

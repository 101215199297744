import * as translationActions from '../actions/translation.action';

export interface State {
  loading: boolean;
  loaded: boolean;
}

export const initialState = (): State => ({
  loading: false,
  loaded: false
});

export function reducer(
  state = initialState(),
  action: translationActions.TranslateActions
): State {
  switch (action.type) {
    case translationActions.LOAD_TRANSLATION: {
      return {
        ...state,
        loading: true
      };
    }

    case translationActions.LOAD_TRANSLATION_SUCCESS: {
      return {
        loading: false,
        loaded: true
      };
    }

    case translationActions.LOAD_TRANSLATION_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
  }

  return state;
}

export const getTranslationLoading = (state: State) => state.loading;
export const getTranslationLoaded = (state: State) => state.loaded;

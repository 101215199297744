import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';

import { EventActivityDataService } from '../../services';

import * as eventActivityDataAction from '../actions/event-activity-data.action';
import * as fromEventActivityData from '../selectors/event-activity-data.selector';
import * as fromRoot from '../../store';
import { ListSearchResponse } from '../../models';
import { ListsService } from '../../services/lists.service';

@Injectable()
export class EventActivityDataEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private eventDataService: EventActivityDataService,
    private listsService: ListsService
  ) {}

  @Effect()
  LoadInternalParticipants$ = this.actions$.pipe(
    ofType<eventActivityDataAction.LoadInternalParticipants>(
      eventActivityDataAction.LOAD_INTERNAL_PARTICIPANTS
    ),
    withLatestFrom(this.store.pipe(select(fromEventActivityData.getInternalParticipantsLoaded))),
    filter(([, loaded]) => !loaded),
    switchMap(() =>
      this.eventDataService.getInternalParticipants().pipe(
        map(
          internalParticipants =>
            new eventActivityDataAction.LoadInternalParticipantsSuccess(internalParticipants)
        ),
        catchError(error => of(new eventActivityDataAction.LoadInternalParticipantsFail(error)))
      )
    )
  );

  @Effect()
  LoadLists$ = this.actions$.pipe(
    ofType<eventActivityDataAction.LoadLists>(eventActivityDataAction.LOAD_LISTS),
    map(action => action.payload),
    switchMap((searchText?: string) =>
      forkJoin(
        [this.listsService.getInstitutionLists(searchText),
        this.listsService.getContactsLists(searchText)]
      ).pipe(
        map(
          ([institutionLists, contactLists]: [ListSearchResponse, ListSearchResponse]) =>
            new eventActivityDataAction.LoadListsSuccess({ institutionLists, contactLists })
        ),
        catchError(error => of(new eventActivityDataAction.LoadListsFail(error)))
      )
    )
  );
}

<div class="c-row ea-dates-container">
    <div class="c-col-2 c-p-right-0" data-automation-id="dates-row-name">
        <label class="ea-date-label">
            {{ 'bd/event-activity/form.date_and_time' | translate }}
            <sup class="c-text-danger">*</sup>
        </label>
    </div>
    <div class="c-col-10 ea-dates-controls">
        <div class="c-row c-row-collapsed">
            <div class="c-col-6 c-d-flex">
                <div class="ea-start-date" data-automation-id="start-date">
                    <div *ngIf="startDateHasError$ | async"
                        class="c-popover c-popover-top c-popover-danger ea-start-date-tooltip">
                        <div class="c-popover-arrow"></div>
                        <div class="c-popover-content">
                            {{ 'bd/event-activity/form.start_date_invalid' | translate }}
                        </div>
                    </div>
                    <bd-date-time-selector [dateTime]="startDate$ | async"
                                        [is12HourFormat]="is12HourFormat$ | async"
                                        [localization]="localization"
                                        (dateTimeChange)="timeChange($event)"
                                        [hideTimePicker]="allDay$ | async"
                                        [class.has-error]="startDateHasError$ | async">
                    </bd-date-time-selector>
                </div>
                <div class="c-full-width c-m-left-sm">
                    <ea-duration [selected]="selectedDuration$ | async"
                                [endDate]="endDate$ | async"
                                [endDateVisible]="endDateVisible$ | async"
                                [endDateValid]="endDateValid$ | async"
                                [dateRangeValid]="dateRangeValid$ | async"
                                [is12HourFormat]="is12HourFormat$ | async"
                                [localization]="localization"
                                (endDateChange)="endDateChange($event)"
                                (valueChange)="onDurationChange($event)"
                                class="c-d-block c-full-width">
                    </ea-duration>
                </div>
            </div>
            <div class="ea-timezone-label" [ngClass]="{'c-p-left-0 c-col-12': endDateVisible$ | async, 'c-col-6 c-p-left-sm' : !(endDateVisible$ | async)}">
                <span data-automation-id="time-zone-label">{{ timeZone$ | async }} {{ utcOffset$ | async }}</span>
                <span *ngIf="timeZoneLoading$ | async"
                      data-automation-id="time-zone-loading"
                      class="fa fa-spinner fa-pulse c-m-left-md"></span>
            </div>
        </div>
    </div>
</div>

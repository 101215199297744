import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromSavedLocations from '../reducers/saved-locations.reducer';
import * as fromRoot from '../../../store/selectors';

export const getSavedLocationsState = createSelector(
  fromFeature.getEventActivityDialogState,
  (state: fromFeature.State) => state.savedLocations
);

export const getSavedLocations = createSelector(getSavedLocationsState, fromSavedLocations.getSavedLocations);

export const getSavedLocationsLoading = createSelector(
  getSavedLocationsState, fromSavedLocations.getSavedLocationsLoading
);

export const getSelectedLocation = createSelector(
  getSavedLocationsState, fromSavedLocations.getSelectedLocation
);

export const getEditValue = createSelector(
  getSavedLocationsState, fromSavedLocations.getEditValue
);

export const isDefaultChecked = createSelector(
  getSavedLocationsState, fromSavedLocations.getIsDefaultChecked
);

export const getSaveDisabled = createSelector(
  getSavedLocationsState,
  fromRoot.getDefaultLocation,
  (state, defaultLocationId) => {
    if (!state.editValue) {
      return true;
    }

    const defaultLocationUnchanged = isDefaultLocationUnchanged(
      state.editValue.locationId,
      state.isDefaultChecked,
      defaultLocationId
    );

    if (isUnmappedDefault(state, defaultLocationId)) {
      return isEmpty(state) && defaultLocationUnchanged;
    }

    return isEmpty(state) || isEqualLocation(state) && defaultLocationUnchanged;
  }
);

export const getDefaultDisabled = createSelector(
  getSavedLocationsState,
  fromSavedLocations.getDefaultDisabled
);

export const getWarning = createSelector(
  getSavedLocationsState, fromSavedLocations.getWarning
);

export const getError = createSelector(
  getSavedLocationsState, fromSavedLocations.getError
);

export const getLoadFailed = createSelector(
  getSavedLocationsState, fromSavedLocations.getLoadFailed
);

export const isLocationChanged = createSelector (
  getSavedLocationsState,
  (state) => !isEqualLocation(state)
);

function isEmpty(state: fromSavedLocations.State) {
  return !state.editValue ||
  !state.editValue.locationName ||
  !state.editValue.googlePlaceId;
}

function isUnmappedDefault(state: fromSavedLocations.State, defaultLocationId: number) {
  return state.editValue.locationId &&
    !state.editValue.googlePlaceId &&
    state.editValue.locationId === defaultLocationId;
}

function isEqualLocation(state: fromSavedLocations.State) {
  return !!state.selected &&
    !!state.editValue &&
    state.editValue.locationName === state.selected.locationName &&
    state.editValue.googlePlaceId === state.selected.googlePlaceId;
}

function isDefaultLocationUnchanged(editLocationId: number, defaultChecked: boolean, userDefaultLocationId: number) {
  if (!defaultChecked && !editLocationId && !userDefaultLocationId) {
    return true;
  }
  if (!defaultChecked && (editLocationId !== userDefaultLocationId)) {
    return true;
  }
  if (defaultChecked && !!editLocationId && !!userDefaultLocationId && editLocationId === userDefaultLocationId) {
    return true;
  }
  return false;
}

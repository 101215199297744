export interface NavigationParams {
  url: string;
  id: string;
}

export abstract class AuthRedirectNavigator {
  prepare() {
    return Promise.resolve(this);
  }

  abstract navigate(params: NavigationParams): Promise<void>;

  abstract get url(): string;
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { StoreModule, Store } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';

import { CupcakeIntegrationModule } from '@ipreo/cupcake-components';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

import { createTranslateLoader } from './translation/localization-loader';
import { reducers, metaReducers, effects, CustomSerializer } from './store';
import { BillfoldAuthorizationModule } from './authorization/authorization.module';
import { EventActivityModule } from './event-activity/event-activity.module';
import { EventActivityComponent } from './event-activity/containers';
import { BillfoldAuthGuard } from './authorization/services/auth.guard';
import { GoogleApisScriptComponent } from './components/google-apis-script/google-apis-script.component';
import { NotAuthorizedPageComponent } from './components/not-authorized-page/not-authorized-page.component';
import { authHandlers } from './services/authorization';
import { WINDOW_REF } from './services/window-ref.provider';
import { DialogService } from './services/dialog.service';
import { LoggingService } from './services/logging.service';
import { FlavorInjectorService } from './services/flavor-injector.service';

import '@ipreo/common-client'; // CupcakeIntegrationModule requires to import Ipreo namespace from common-client to work

export const ROUTES: Routes = [
  {
    path: 'not-authorized',
    component: NotAuthorizedPageComponent
  },
  {
    path: '',
    canActivate: [BillfoldAuthGuard],
    children: [{
      path: '',
      canActivate: [FlavorInjectorService],
      component: EventActivityComponent,
      children: [{
          path: ':id',
          component: EventActivityComponent
        }]
    }]
  }
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    EventActivityModule,
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    CupcakeIntegrationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, Store]
      }
    }),
    BillfoldAuthorizationModule.forRoot({
      billfoldAuthSettings: {
        clientId: environment.ipreoAccount.clientId,
        authority: environment.ipreoAccount.authority,
        scope: [
          'openid',
          'eventhub-read',
          'eventhub-write',
          'eventhub-activity-write',
          'eventhub-activity-read',
          'bd-api',
          'ipreo-api',
          'localization'
        ],
        baseRedirectionPath: environment.baseRedirectionPath
      },
      ihsSamSettings: {
        clientId: environment.ihsSam.clientId,
        authority: environment.ihsSam.authority,
        scope: [
          'openid',
          'eventhub-read',
          'eventhub-write',
          'eventhub-activity-write',
          'eventhub-activity-read',
          'bd-api',
          'ipreo-api',
          'localization'
        ],
        baseRedirectionPath: environment.baseRedirectionPath
      }
    })
  ],
  declarations: [AppComponent, NotAuthorizedPageComponent, GoogleApisScriptComponent],
  providers: [
    DialogService,
    LoggingService,
    FlavorInjectorService,
    { provide: WINDOW_REF, useValue: window },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    ...authHandlers
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Action } from '@ngrx/store';
import { ActivityAttachment } from '../../models';


export const LOAD_ACTIVITY_ATTACHMENTS = '[Attachment] Load Activity Attachments';
export const ADD_ACTIVITY_ATTACHMENTS = '[Attachment] Add Attachments';
export const REMOVE_ACTIVITY_ATTACHMENT_SUBMIT = '[Attachment] Remove Attachment Submit';
export const REMOVE_ACTIVITY_ATTACHMENT = '[Attachment] Remove Attachment';
export const DOWNLOAD_ACTIVITY_ATTACHMENT = '[Attachment] Download Attachment';
export const SET_ERRORS = '[Attachment] Set Errors';

export class LoadActivityAttachments implements Action {
  readonly type = LOAD_ACTIVITY_ATTACHMENTS;

  constructor(public attachments: ActivityAttachment[]) {}
}

export class AddActivityAttachments implements Action {
  readonly type = ADD_ACTIVITY_ATTACHMENTS;

  constructor(public attachments: ActivityAttachment[]) {}
}

export class RemoveActivityAttachmentSubmit implements Action {
  readonly type = REMOVE_ACTIVITY_ATTACHMENT_SUBMIT;

  constructor(public uId: string) {}
}

export class RemoveActivityAttachment implements Action {
  readonly type = REMOVE_ACTIVITY_ATTACHMENT;

  constructor(public uId: string) {
  }
}

export class DownloadActivityAttachment implements Action {
  readonly type = DOWNLOAD_ACTIVITY_ATTACHMENT;

  constructor(public attachment: ActivityAttachment) {
  }
}

export class SetErrors implements Action {
  readonly type = SET_ERRORS;

  constructor(public errors: string[]) {
  }
}

export type ActivityAttachmentAction = LoadActivityAttachments
  | RemoveActivityAttachmentSubmit
  | RemoveActivityAttachment
  | DownloadActivityAttachment
  | AddActivityAttachments
  | SetErrors;

import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { shareReplay, map, filter } from 'rxjs/operators';
import { getCountry, State } from '../../store';

export enum GoogleHostLocation {
  Default = 0,
  China = 1
}

@Injectable({
  providedIn: 'root'
})
export class GoogleHostSelectorService {
  private hostLocation$: Observable<GoogleHostLocation>;

  constructor(store: Store<State>) {
    this.hostLocation$ = store.pipe(
      select(getCountry),
      filter(c => !!c),
      map(country => this.chooseGoogleHostLocation(country)),
      shareReplay(1)
    );
  }

  public selectHost() {
    return this.hostLocation$;
  }

  private chooseGoogleHostLocation(country: string) {
    return country.toUpperCase() === 'CHN' ?
      GoogleHostLocation.China :
      GoogleHostLocation.Default;
  }
}

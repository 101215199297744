import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, of, ReplaySubject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import {
  getLoadFailed,
  getSavedLocations
} from '../store/selectors/saved-locations.selector';
import { catchError, filter, map, take } from 'rxjs/operators';
import { PlaceFacade } from './place.facade';
import { LocationGroupEnum, LocationModel, LocationViewModel, SavedLocationItemModel } from '../models';
import { LoadSavedLocations, SetLocation } from '../store/actions';
import { PlaceAutocompletePrediction } from '../models/place-autocomplete-prediction.model';

@Injectable()
export class LocationsSourceService {
  private locationSource$ = new ReplaySubject<LocationViewModel[]>(1);
  private autocompleteSessionToken: google.maps.places.AutocompleteSessionToken;
  private savedLocationsCount = 10;
  private loading = false;

  constructor(
    private store: Store<State>,
    private placeFacade: PlaceFacade) {}

  fetchSavedLocations() {
    this.getSavedLocations()
      .subscribe((locations) => {
        this.setLoading(false);
        this.locationSource$.next(locations);
      });
  }

  fetchGoogleLocations(query: string) {
    this.getGoogleLocations(query)
      .subscribe((locations) => {
        this.setLoading(false);
        this.locationSource$.next(locations);
      });
  }

  fetchCombinedResults(query: string) {
    forkJoin(
      [this.getGoogleLocations(query),
      this.getSavedLocations(query)]
    )
      .pipe(
        filter(([google, saved]) => !!google && !!saved),
        map(([google, saved]) => [...saved.slice(0, this.savedLocationsCount), ...google])
      )
      .subscribe((locations) => {
        this.setLoading(false);
        this.locationSource$.next(locations);
      });
  }

  hasGoogleResults() {
    return this.locationSource$.asObservable()
      .pipe(
        map(locations =>
          locations.some(l => l.groupKey === LocationGroupEnum.GoogleLocations))
      );
  }

  getLocationsSource() {
    this.setAutocompleteSessionToken();

    return this.locationSource$.asObservable()
      .pipe(
        filter(() => !this.loading)
    );
  }

  setAutocompleteSessionToken() {
    this.autocompleteSessionToken = this.placeFacade.createAutocompleteSessionToken();
  }

  setSavedLocation(location: LocationModel) {
    this.store.dispatch(new SetLocation(location));
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  private getSavedLocations(query: string = null) {
    this.store.dispatch(new LoadSavedLocations(query));

    return combineLatest(
      [this.store.pipe(select(getSavedLocations)),
      this.store.pipe(select(getLoadFailed))]
    )
      .pipe(
        filter(([, loadFailed]) => loadFailed !== null),
        take(1),
        map(([locations, loadFailed]) => {
          if (loadFailed) {
            return [this.mapToError('saved_locations_not_available', LocationGroupEnum.SavedLocations)];
          }

          if (locations) {
            return this.mapToViewModel(locations, LocationGroupEnum.SavedLocations);
          }

          return [];
        })
      );
  }

  private getGoogleLocations(query: string) {
    return this.placeFacade
      .getPlacePredictions({
        input: query,
        sessionToken: this.autocompleteSessionToken
      }).pipe(
        take(1),
        map(locations => locations && this.mapToViewModel(locations, LocationGroupEnum.GoogleLocations)),
        catchError(() => {
          return of([this.mapToError('google_results_not_available', LocationGroupEnum.GoogleLocations)]);
        })
      );
  }

  private mapToViewModel(
    items: Array<PlaceAutocompletePrediction | SavedLocationItemModel>,
    groupKey: LocationGroupEnum
  ) {
    return items.map(i => ({
      item: i,
      groupKey
    }));
  }

  private mapToError(
    description: string,
    groupKey: LocationGroupEnum
  ) {
    return <LocationViewModel>{ groupKey, error: true, item: { description }};
  }
}

import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { SilentSignInErrorHandler } from '../../authorization/contracts/silent-sign-in-error-handler';
import { AuthorizationFailInfo } from '../../authorization/contracts/authorization-fail-info';
import { Store } from '@ngrx/store';
import { State } from '../../store';
import { LogAuthErrorAction } from '../../store/actions/fail-action';

export const NOT_AUTHORIZED_PAGE_PATH = '/not-authorized';

@Injectable()
export class NotAuthorizedHandler implements SilentSignInErrorHandler {

  handle(errorInfo: AuthorizationFailInfo): void {
    this.store.dispatch(new LogAuthErrorAction(errorInfo.error, errorInfo.availableToken));

    const queryParams = errorInfo.failedRouteSnapshot.queryParams;
    const key = '_routerState';
    const state = errorInfo.failedRouteSnapshot[key as keyof ActivatedRouteSnapshot] as { url: string };

    this.router.navigate(
      [NOT_AUTHORIZED_PAGE_PATH], { queryParams, state: { authType: errorInfo.authType, initialUrl: state.url } }
    );
  }

  constructor(private router: Router, private store: Store<State>) {
  }
}

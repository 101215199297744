<ng-container *ngIf="!(notFoundErrorCode$ | async); else errorHandling">
    <div class="c-full-height ea-wrapper c-bg-white" [class.c-full-page-loader]="loading$ | async" *ngIf="dialogReady$ | async; else loading">
        <div class="c-modal-header">
            <button class="c-modal-close" type="button" (click)="cancel()"><span>×</span></button>
            <h4 class="c-modal-title">{{(newActivity$ | async) ? ('bd/event-activity/form.new_activity_title' | translate) : ('bd/event-activity/form.edit_activity_title' | translate) }}</h4>
        </div>
        <ng-container *ngIf="eventActivityEntity$ | async as eventActivityEntity">
            <div class="c-modal-body ea-body c-full-height">
                <div class="c-row c-full-height">
                    <div class="c-col-9">
                        <div class="ea-main">
                            <div *ngIf="eventName$ | async as eventName" class="c-d-flex c-m-bottom-md">
                                <div class="c-text-gray c-p-right-sm">{{'bd/event-activity/form.event' | translate}}:</div>
                                <div class="c-text-ellipsis" [title]="eventName">{{eventName}}</div>
                            </div>
                            <ea-subject [class.ea-form-disabled]="isEditDisabled$ | async"
                                    [attr.inert]="isEditDisabled$ | async"
                                    [value]="eventActivitySubject$ | async"
                                    [invalid]="!(eventActivitySubjectIsValid$ | async)"
                                    (subjectChange)="onSubjectChange($event)">
                            </ea-subject>
                            <ea-event-location
                                    [class.ea-form-disabled]="isEditDisabled$ | async"
                                    [attr.inert]="isEditDisabled$ | async"
                                    *ngIf="!!(eventId$ | async)"
                                    [schedules]="eventSchedules$ | async"
                                    [selectedPlaceId]="selectedEventSchedulePlaceId$ | async"
                                    [isVirtualEvent]="isVirtualEvent$ | async"
                                    (eventLocationChange)="onEventLocationSelected($event)">
                            </ea-event-location>
                            <div class="c-d-flex" [ngClass]="(isVirtualEvent$ | async) ? 'c-flex-column-reverse' : 'c-flex-column'" [class.ea-form-disabled]="isEditDisabled$ | async" [attr.inert]="isEditDisabled$ | async" data-automation-id="location-container">
                                <ea-activity-location-container></ea-activity-location-container>
                                <ea-activity-virtual-meeting-container></ea-activity-virtual-meeting-container>
                            </div>
                            <ea-date-container [class.ea-form-disabled]="isEditDisabled$ | async" [attr.inert]="isEditDisabled$ | async"></ea-date-container>
                            <ea-activity-type-container></ea-activity-type-container>
                            <ea-event-activity-securities *ngIf="isSymbolEnabled$ | async"></ea-event-activity-securities>
                            <ea-internal-participant-container [class.ea-form-disabled]="isEditDisabled$ | async" [attr.inert]="isEditDisabled$ | async"></ea-internal-participant-container>
                            <ea-defined-fields-container *ngIf="hasAnyADFs$ | async"></ea-defined-fields-container>
                            <ea-notes
                                    [value]="notes$ | async"
                                    [language]="language$ | async"
                                    [isHubActivityFormEnabled]="isHubActivityFormEnabled$ | async"
                                    (notesChange)="onNotesChange($event)">
                            </ea-notes>
                            <ea-attachments-container *ngIf="isAttachmentEnabled$ | async">
                            </ea-attachments-container>
                        </div>
                    </div>
                    <div class="c-col-3" [class.ea-form-disabled]="isEditDisabled$ | async" [attr.inert]="isEditDisabled$ | async" data-automation-id="external-participants-container">
                        <ea-external-participants-container>
                        </ea-external-participants-container>
                    </div>
                </div>
            </div>
        </ng-container>
        <ea-footer [disableSave]="!(isSavingEnabled$ | async)"
                   [displayDeleteButton]="!(newActivity$ | async)"
                   [displayEmptySubjectWarning]="!(eventActivitySubject$ | async)"
                   [alerts]="summaryAlert$ | async"
                   [eventActivity]="!!(eventId$ | async)"
                   (saveAndClose)="saveAndClose()"
                   (delete)="delete()"
                   (cancelSave)="onCanceledSave()"
                   (close)="cancel()"
                   class="ea-footer">
        </ea-footer>
    </div>
    <cupcake-integration-iframe-client></cupcake-integration-iframe-client>
</ng-container>

<ng-template #loading>
    <div class='c-full-page-loader c-full-height'></div>
</ng-template>

<ng-template #errorHandling>
    <ea-error-handling (close)="close()">
    </ea-error-handling>
</ng-template>

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { SourceSecurity } from '../../models/security/source-security.model';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { getSecurities } from '../../store/selectors';
import { CupcakeSuggesterComponent, CupcakeSuggesterContainerPosition } from '@ipreo/cupcake-suggester';
import { FormControl } from '@angular/forms';
import { getSourceSelected, LoadSecurities, SubmitSecurities } from '../../store';
import { debounceTime, delay, distinctUntilChanged } from 'rxjs/operators';
import { inputDebounceTime } from '../../models';

@Component({
  selector: 'ea-event-activity-securities',
  templateUrl: './event-activity-securities.component.html',
  styleUrls: ['./event-activity-securities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventActivitySecuritiesComponent implements OnInit, OnDestroy {
  ContainerPosition = CupcakeSuggesterContainerPosition;
  suggestedSecurities$: Observable<SourceSecurity[]>;
  selectedSecurities$: Observable<SourceSecurity[]>;
  alert$: Observable<boolean>;
  formInput = new FormControl('');

  @ViewChild('suggester', { static: false }) suggester: CupcakeSuggesterComponent;

  constructor(private store: Store<fromStore.State>) { }

  subscription = Subscription.EMPTY;

  ngOnInit() {
    this.suggestedSecurities$ = this.store.pipe(select(getSecurities));
    this.selectedSecurities$ = this.store.pipe(select(getSourceSelected), delay(0));
    this.alert$ = this.store.pipe(select(fromStore.getSaveSecurityError));
    this.subscription = this.formInput.valueChanges
      .pipe(
        debounceTime(inputDebounceTime),
        distinctUntilChanged(),
      )
      .subscribe(d => {
        this.store.dispatch(new LoadSecurities(d));
    });
  }

  onSelect(selected: SourceSecurity[]) {
    this.store.dispatch(new SubmitSecurities(selected));
    this.formInput.setValue('');
    this.suggester.destroyContainer();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

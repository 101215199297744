import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { environment } from '@env/environment';

import {
  ChangeItem,
  CompositeId,
  EventActivity,
  UpdateRequest,
  Event,
  ActivityAttachment
} from '../models';
import { HttpServiceBase } from '../../services/http.service';
import { EventActivityMapper } from './event-activity-mapper.service';
import { EventActivityModel, EventActivityModelDto } from '../models/event-activity-model.model';
import { SavedActivityModel } from '../models/saved-activity-model.model';
import { JobDto } from '../models/polling/job.dto';
import { PollingService } from './polling.service';
import { SaveType } from '../models/polling/save-type.enum';

@Injectable()
export class EventActivityService extends HttpServiceBase {
  private baseUrl = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private mapper: EventActivityMapper,
    private pollingService: PollingService) {
    super();
  }

  get(id: string): Observable<EventActivityModel> {
    return this.http
      .get<EventActivityModelDto>(`${this.baseUrl}/api/activity/${id}`)
      .pipe(map(dto => this.toEventActivityModel(dto)));
  }

  update(activity: EventActivity,
    changeLog: ChangeItem[] = [],
    newAttachments: ActivityAttachment[] = []
    ): Observable<SavedActivityModel> {
    return this.http
      .post<JobDto>(
        `${this.baseUrl}/api/job/update-activity`,
        this.getRequestContent<UpdateRequest>({
              activity: this.mapper.toServer(
              this.correctDates(cloneDeep(activity))),
            changeLog
          },
        newAttachments))
      .pipe(
        switchMap(job => this.pollingService.getSavingJobResult(job.jobId, SaveType.Update)),
        map(d => <SavedActivityModel>{
          activity: this.mapper.fromServer(d.activity),
          hubLoadActivity: d.hubLoadActivity,
          savingErrors: d.savingErrors
      }));
  }

  create(
    activity: EventActivity,
    attachments: ActivityAttachment[] = []): Observable<SavedActivityModel> {
    return this.http
      .post<JobDto>(
        `${this.baseUrl}/api/job/create-activity`,
        this.getRequestContent<EventActivity>(
          this.mapper.toServer(this.correctDates(cloneDeep(activity))),
          attachments)
      )
      .pipe(
        switchMap(job => this.pollingService.getSavingJobResult(job.jobId, SaveType.Create)),
        map(d => <SavedActivityModel>{
          activity: this.mapper.fromServer(d.activity),
          attachments: d.activity.attachments,
          hubLoadActivity: d.hubLoadActivity,
          savingErrors: d.savingErrors
        }));
  }

  delete(activityId: string): Observable<Object> {
    return this.http.delete(`${this.baseUrl}/api/activity/${activityId}`);
  }

  prototype(params: {
    eventId?: string,
    startDate?: string,
    actionDataKey?: string
  } = {}): Observable<EventActivityModel> {
    if (!params.eventId) { delete params.eventId; }
    if (!params.startDate) { delete params.startDate; }
    if (!params.actionDataKey) {delete params.actionDataKey; }

    const httpParams = new HttpParams({
      fromObject: params
    });
    return !!params.eventId ?
      this.http.get<EventActivityModelDto>(`${this.baseUrl}/api/activity/event-prototype`, { params: httpParams })
        .pipe(map((dto) => this.toEventActivityModel(dto)))
      : this.http.get<EventActivityModelDto>(`${this.baseUrl}/api/activity/v2/prototype`, { params: httpParams })
        .pipe(map((dto) => this.toEventActivityModel(dto)));
  }

  getHasContacts(keys: CompositeId[]): Observable<CompositeId[]> {
    return this.http.post<CompositeId[]>(
      `${environment.domain}/app/bd/contacts/api/institutionsWithContacts`,
      {
        accountCompositeKeys: keys,
        excludeInternals: 'true'
      }
    );
  }

  private toEventActivityModel(dto: EventActivityModelDto): EventActivityModel {
    return {
      activity: this.mapper.fromServer(dto.activity),
      event: dto.event && this.mapEvent(dto.event),
      activityPrototypeWarnings: dto.activityPrototypeWarnings,
      attachments: !!dto.activity ? dto.activity.attachments : []
    };
  }

  private mapEvent(event: Event): Event {
    return {
      ...event,
      schedules: event.schedules.map((s) => ({
        ...s,
        startDate: new Date(s.startDate)
      }))
    } as Event;
  }

  private getRequestContent<T>(request: T, attachments: ActivityAttachment[]): T | FormData {
    if (!attachments || attachments.length === 0) {
      return request;
    }
    const formData = new FormData();
    attachments.forEach(attachment => {
      formData.append('files', attachment.rawFile, attachment.name);
    });
    formData.append('payload', JSON.stringify(request));
    return formData;
  }
}

<div class="ea-popover-content" data-qa-id="group-popover">
    <div *ngFor="let item of (context | async)" class="c-row c-row-align-center c-row-collapsed ea-popover-participant">
        <div class="c-col-11 c-text-ellipsis" data-qa-id="participant-pill-row">
            <a data-qa-id="popover-participant-name"
               class="c-link c-p-left-xs"
               target="_blank"
               [title]="item.value"
               [href]="linkService.buildProfileLink(item.model.participant.contact) | async">
                {{ item.value }}
            </a>
        </div>
        <div data-qa-id="popover-delete-button"
             class="c-text-gray c-cursor-pointer"
             (click)="removeItem(item, $event)">
            <i class="fa fa-times"></i>
        </div>
    </div>
</div>

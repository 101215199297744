import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { ActivityAttachment } from '../../../models';
import {FileListSingleItemComponent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'ea-attachment-list-item',
  templateUrl: './attachment-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentListItemComponent extends FileListSingleItemComponent {
  @Input()
  file: ActivityAttachment;
  @Output()
  download: EventEmitter<ActivityAttachment> = new EventEmitter<ActivityAttachment>();
  @Output()
  remove: EventEmitter<string> = new EventEmitter<string>();

  public downloadFile() {
      this.download.emit(this.file);
  }
  public onRemove() {
      this.remove.emit(this.file.uid);
  }
}

import { Component, OnInit } from '@angular/core';
import { variables } from './build.variables';

@Component({
  selector: 'ea-root',
  template: `
    <router-outlet></router-outlet>
    <ea-google-apis-script></ea-google-apis-script>
  `
})
export class AppComponent implements OnInit {
  ngOnInit() {
    console.log(variables.version);
  }
}

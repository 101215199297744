<ng-container *ngIf="viewMode$ | async as viewMode">
    <div class="c-row c-row-collapsed c-m-bottom-md">
        <div class="c-col c-text-muted" data-qa-id="save-location-caption">
            {{ (viewMode === mode.Create ? 'bd/event-activity/form.save_new_location' : 'bd/event-activity/form.edit_saved_location') | translate }}
        </div>
    </div>
    <div class="c-row c-row-collapsed c-m-bottom-sm">
        <div class="c-col-2 c-p-right-0">
            <label class="c-input-label" for="location-name">{{'bd/event-activity/form.name' | translate}} <sup class="c-text-danger">*</sup></label>
        </div>
        <div class="c-col">
            <input class="c-input"
                [class.c-input-warning]="warning$ | async"
                type="text"
                id="location-name"
                placeholder="{{'bd/event-activity/form.enter_location_name' | translate}}"
                [formControl]="name"
                #locationName
                data-qa-id="location-name">
            <span class="c-text-warning" *ngIf="warning$ | async">{{'bd/event-activity/form.saved_location_name_unique' | translate}}</span>
        </div>
    </div>
    <div class="c-row c-row-collapsed c-m-bottom-xs">
        <div class="c-col-2 c-p-right-0">
            <label class="c-input-label"
                data-automation-id="saved-location-address-label">
                {{'bd/event-activity/form.address' | translate}} <sup class="c-text-danger">*</sup></label>
        </div>
        <div class="c-col">
            <ea-location
                    #location
                    [value$]="selectedAddress$"
                    [viewName]="'saved-location-address'"
                    (locationSelected)="onLocationsSelected($event)"
            >
            </ea-location>
        </div>
    </div>
    <div class="c-row c-row-collapsed c-m-bottom-xs">
        <div class="c-col-offset-2 c-col">
            <label class="c-control c-checkbox" [class.c-cursor-default]="defaultDisabled$ | async">
                <input type="checkbox" name="radio-isDefault" data-qa-id="set-as-default-location"
                [checked]="isDefault$ | async"
                [disabled]="defaultDisabled$ | async"
                (change)="toggleIsDefault($event.target.checked)">
                <i class="c-bg-primary"></i>
                <span class="c-text-muted">{{'bd/event-activity/form.set_up_as_default_location' | translate}} </span>
            </label>
        </div>
    </div>
    <div class="c-row c-row-collapsed c-m-bottom-xs" *ngIf="viewMode === mode.Edit">
        <div class="c-col-offset-2 c-col">
            <label class="c-control c-checkbox">
                <input type="checkbox"
                       name="radio-updateAll"
                       data-qa-id="update-all-activities"
                       [checked]="updateAll"
                       (change)="toggleUpdateAll()"
                >
                <i class="c-bg-primary"></i>
                <span class="c-text-muted">{{'bd/event-activity/form.update_all_activities' | translate}} </span>
            </label>
        </div>
    </div>

    <div class="c-row c-row-collapsed" *ngIf="error$ | async">
        <div class="c-col">
            <div class="c-alert c-alert-danger c-alert-top c-alert-has-icon c-m-bottom-sm">
                <i class="fa fa-exclamation-circle c-alert-icon" aria-hidden="true"></i>
                {{ 'bd/event-activity/form.saved_location_save_error' | translate }}
            </div>
        </div>
    </div>

    <div class="c-row" [class.c-m-top-lg]="!(error$ | async)">
        <div class="c-col c-text-left">
            <ng-container *ngIf="viewMode == mode.Edit">
                <button
                  (click)="onDelete()"
                  class="c-btn c-btn-default c-m-left-md" data-automation-id="delete-location-button">
                  <i class="fa fa-trash c-text-danger"></i>
                  {{'bd/event-activity/form.delete' | translate}}
                </button>
              </ng-container>
        </div>
        <div class="c-col c-text-right">
            <button class="c-btn c-btn-default" (click)="hide()" data-qa-id="cancel-location-btn">{{'bd/event-activity/form.cancel' | translate}}</button>
            <button
                class="c-btn c-btn-primary c-m-left-md"
                [disabled]="saveDisabled$ | async"
                (click)="save(viewMode)"
                data-qa-id="save-location-btn">
                    {{'bd/event-activity/form.save' | translate}}
            </button>
        </div>
    </div>
</ng-container>

<div class="c-row c-row-align-center c-m-bottom-sm">
  <div class="c-col-2 c-p-right-0" data-automation-id="subject-name">
    <label>{{'bd/event-activity/form.subject' | translate}}</label>
  </div>
  <div class="c-col-10">
    <div class="ea-subject">
      <input #subjectInput
        (input)="valueChange$.next($event.target.value)"
        (blur)="onBlur()"
        [class.c-input-error]="invalid && showError"
        [attr.maxlength]="maxLength"
        class="c-input ea-subject-input"
        type="text"
        placeholder="{{'bd/event-activity/form.activity_subject' | translate}}"
        data-automation-id="subject-activity-input">

      <div *ngIf="invalid && showError"
           class="c-popover c-popover-bottom c-popover-danger ea-subject-popover">
        <div class="c-popover-arrow"></div>
        <div class="c-popover-content">
          {{'bd/event-activity/form.subject_too_short' | translate}}
        </div>
      </div>
    </div>
  </div>
</div>

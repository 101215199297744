import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ElementRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil, observeOn } from 'rxjs/operators';
import { Subject, asyncScheduler } from 'rxjs';
import { CupcakePopoverService, CupcakePopoverOptions } from '@ipreo/cupcake-components';
import { inputDebounceTime } from '../../../models/constants';

@Component({
  selector: 'ea-input',
  templateUrl: './input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent implements OnInit, OnDestroy  {
  @Input() placeholder: string;
  @Input() set warning(value: string) {
    this.warningMsg = value;
    if (this.isValid) {
      this.hasWarning ? this.openWarningPopover() : this.closeWarningPopover();
    }
  }
  @Input() set error(value: string) {
    this.errorMsg = value;
    this.isValid ? this.closeErrorPopover() : this.openErrorPopover();
  }
  @Input() set value(val: string) {
    if (this.inputForm.value !== val) {
      this.inputForm.setValue(val, { emitEvent: false });
    }
  }

  @Output() valueChange = new EventEmitter<string>();

  get isValid() { return !this.errorMsg; }
  get hasWarning() { return !!this.warningMsg; }

  inputForm = new FormControl();

  private focused = false;
  private errorMsg: string;
  private warningMsg: string;
  private popover: Subject<void>;
  private destroyed$ = new Subject();
  private openPopoverQueue$ = new Subject<CupcakePopoverOptions>();

  constructor(private popoverService: CupcakePopoverService, private elementRef: ElementRef) { }

  ngOnInit() {
    this.inputForm.valueChanges.pipe(
      debounceTime(inputDebounceTime),
      distinctUntilChanged(),
      takeUntil(this.destroyed$)
    ).subscribe(value => this.valueChange.emit(value));

    this.openPopoverQueue$.pipe(
      observeOn(asyncScheduler), // to avoid ExpressionChangedAfterItHasBeenCheckedError
      takeUntil(this.destroyed$)
    ).subscribe(options => {
      this.popover = this.popoverService.openPopover(this.elementRef.nativeElement, options).response;
    });
  }

  onFocus() {
    this.focused = true;
    if (this.isValid && this.hasWarning) {
      this.openWarningPopover();
    }
  }

  onBlur() {
    this.focused = false;
    if (this.isValid && this.hasWarning) {
      this.closeWarningPopover();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.closePopover();
  }

  private openWarningPopover() {
    if (this.focused) {
      this.openPopover('c-popover-warning', this.warningMsg);
    }
  }

  private openErrorPopover() {
    this.closePopover();
    this.openPopover('c-popover-danger', this.errorMsg);
  }

  private closeErrorPopover() {
    this.closePopover();

    if (this.hasWarning) {
      this.openWarningPopover();
    }
  }

  private closeWarningPopover() {
    this.closePopover();
  }

  private openPopover(rootClass: string, msg: string) {
    const options = <CupcakePopoverOptions>{
      rootCssClass: rootClass,
      position: 'bottom',
      trigger: 'manual',
      text: msg as Object
    };

    this.openPopoverQueue$.next(options);
  }

  private closePopover() {
    if (this.popover && !this.popover.closed) {
      this.popover.complete();
    }
  }
}

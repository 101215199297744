import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalizationModel } from '@ipreo/bd-date-time-selector';

import * as fromStore from '../../store';
import * as fromRoot from '../../../store';
import { Duration } from '../../models';
import { TimeZoneService } from '../../services/timezone.service';

@Component({
  selector: 'ea-date-container',
  templateUrl: './event-activity-date.component.html',
  styleUrls: ['./event-activity-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventActivityDateComponent implements OnInit {
  timeZone$: Observable<string>;
  startDate$: Observable<Date>;
  endDate$: Observable<Date>;
  endDateValid$: Observable<boolean>;
  dateRangeValid$: Observable<boolean>;

  is12HourFormat$: Observable<boolean>;
  localization: LocalizationModel;

  selectedDuration$: Observable<number>;
  allDay$: Observable<boolean>;
  endDateVisible$: Observable<boolean>;

  startDateHasError$: Observable<boolean>;
  startDateErrorMsg$: Observable<boolean>;

  utcOffset$: Observable<string>;

  eventLocationTimeZone$: Observable<string>;
  timeZoneLoading$: Observable<boolean>;

  constructor(private store: Store<fromRoot.State>,
     private translateService: TranslateService,
     private timeZoneService: TimeZoneService) {}

  ngOnInit(): void {
    this.timeZone$ = this.store.pipe(select(fromStore.getEventActivityTimezone));
    this.startDate$ = this.store.pipe(select(fromStore.getEventActivityStartDate));
    this.endDate$ = this.store.pipe(select(fromStore.getEventActivityEndDate));
    this.endDateValid$ = this.store.pipe(select(fromStore.getEventActivityEndDateIsValid));
    this.dateRangeValid$ = this.store.pipe(select(fromStore.getEventActivityDateRangeIsValid));
    this.is12HourFormat$ = this.store.pipe(select(fromRoot.getIs12HourFormat));
    this.localization = {
      AM: this.translateService.instant('bd/event-activity/form.am'),
      PM: this.translateService.instant('bd/event-activity/form.pm'),
      today: this.translateService.instant('bd/event-activity/form.today'),
      toggle: this.translateService.instant('bd/event-activity/form.date_time_selector_tooltip')
    };
    this.selectedDuration$ = this.store.pipe(select(fromStore.getEventActivityDuration));
    this.allDay$ = this.selectedDuration$.pipe(map(d => d === Duration.AllDay));
    this.endDateVisible$ = this.selectedDuration$.pipe(
      map(duration => duration === Duration.EndDate)
    );

    this.startDateHasError$ = this.store.pipe(select(fromStore.getEventActivityStartDateIsValid), map(valid => !valid));

    this.utcOffset$ = combineLatest([this.startDate$, this.timeZone$, this.startDateHasError$]).pipe(
      map(([activityDate, timeZone, hasErrors]) => {
        return hasErrors ? '' : this.timeZoneService.getUtcOffset(activityDate, timeZone);
      }));

    this.timeZoneLoading$ = this.store.pipe(select(fromStore.getTimeZoneLoading));
  }

  timeChange($event: Date) {
    this.store.dispatch(new fromStore.UpdateStartDate($event));
  }

  endDateChange($event: Date) {
    this.store.dispatch(new fromStore.UpdateEndDate($event));
  }

  onDurationChange(value: Duration) {
    this.store.dispatch(new fromStore.UpdateDuration(value));
  }
}

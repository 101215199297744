﻿<div class="c-full-width c-p-relative ea-activity-location-suggester" [attr.data-automation-id]="viewName">
    <cupcake-suggester
            [ngClass]="{ 'ea-suggester-warning': (timeZoneFailed$ | async) }"
            [formControl]="suggesterInput"
            [suggesterDataSource]="locations$"
            [suggesterValueField]="'item.description'"
            [suggesterIdField]="'item.placeId'"
            [suggesterMultiselectMode]="false"
            [suggesterFilterMode]="false"
            [containerPosition]="suggesterPositions.Auto"
            [suggesterFooterTemplate]="googleFooter"
            [suggesterItemTemplate]="itemTemplate"
            [suggesterPlaceholder]="'bd/event-activity/form.search_location' | translate"
            [suggesterNoResultsText]="'bd/event-activity/form.no_results_found' | translate"
            [suggesterControlStream]="suggesterControlStream"
            [suggesterSelectedItems]="selectedValue"
            (suggesterOnSelectEvent)="onLocationSelected($event)"
            (change)="mapToSelected()"
            (mousedown)="onFocus()"
            #suggester>
    </cupcake-suggester>
    <ng-template #googleFooter>
        <div class="c-pull-right c-m-xs">
            <div class="powered-by-google-logo"></div>
        </div>
    </ng-template>
    <ng-template #itemTemplate let-model="item">
        <ng-container *ngIf="!model.item.model.error; else errorTemplate">
            {{ model.item.model.item.description }}
        </ng-container>

        <ng-template #errorTemplate>
            <i class="fa fa-exclamation-circle c-text-danger c-m-right-xs" aria-hidden="true"></i>
            {{ 'bd/event-activity/form.' + model.item.model.item.description | translate }}
        </ng-template>
    </ng-template>
</div>
<div *ngIf="popoverOptions.showPopover"
    class="c-popover c-popover-bottom c-popover-warning ea-location-popover">
    <div class="c-popover-arrow"></div>
    <div class="c-popover-content">
        {{ popoverOptions.warningMessage | translate }}
    </div>
</div>

import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export class SilentSignInError<T> {
  constructor(public error: T, public availableToken: string) { }
}

@Injectable()
export class SilentSignInIFrameObserver {
  private observer: MutationObserver;
  private iframeWindow: Window;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.observer = this.createObserver();
  }

  public observeOn<T>(process: Observable<T>): Observable<T> {
    this.observer.observe(this.document.getElementsByTagName('body')[0], { childList: true });

    return process.pipe(
      catchError((error) => {
        return throwError(new SilentSignInError(error, this.getToken()));
      }),
      finalize(() => this.cleanUp())
    );
  }

  private getToken() {
    if (this.iframeWindow && this.iframeWindow.document) {
      const matches = /(id_token)=([^&]*)/.exec(this.iframeWindow.document.URL);
      return matches && matches[2];
    }
    return null;
  }

  private cleanUp() {
    this.observer.disconnect();
    this.iframeWindow = null;
  }

  private createObserver() {
    return new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        for (let i = 0; i < mutation.addedNodes.length; i++) {
          const node = mutation.addedNodes.item(i);
          if (node.nodeName === 'IFRAME') {
            const iframe = node as HTMLIFrameElement;
            this.iframeWindow = iframe.contentWindow;
            this.observer.disconnect();
          }
        }
      });
    });
  }
}


import { CupcakeModalService, CupcakeModalOptions } from '@ipreo/cupcake-components';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

const CONFIRM_ACTION = 'ok';
const CANCEL_ACTION = 'cancel';

export interface ModalOptions {
  title: string;
  text: string;
  titleCancelButton: string;
  titleConfirmButton: string;
  type: string;
  confirmEventEmit: () => void;
  cancelEventEmit?: () => void;
  interpolateParams?: {name: string};
}

@Injectable()
export class ModalService {
  constructor(
    private cupcakeModalService: CupcakeModalService,
    private translateService: TranslateService) { }

    public openModal(modalOptions: ModalOptions) {
      const options = <CupcakeModalOptions>{
        mode: 'component',
        type: modalOptions.type,
        title: this.translateService.instant(modalOptions.title),
        context: {
          text: this.translateService.instant(modalOptions.text, modalOptions.interpolateParams),
          buttons: [
            {
              title: this.translateService.instant(modalOptions.titleCancelButton),
              action: CANCEL_ACTION
            },
            {
              title: this.translateService.instant(modalOptions.titleConfirmButton),
              action: CONFIRM_ACTION
            }
          ]
        }
      };

      this.cupcakeModalService
        .open(options)
        .pipe(take(1))
        .subscribe(v => {
          if (v.action === CONFIRM_ACTION) {
            modalOptions.confirmEventEmit();
          } else if (v.action === CANCEL_ACTION && modalOptions.cancelEventEmit) {
            modalOptions.cancelEventEmit();
          }
        });
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { PagingService } from '../services/paging.service';

@Pipe({ name: 'eaPaginate' })
export class PaginatePipe implements PipeTransform {
  // IDE can`t infer return type in html template so return value is any
  transform<T>(items: T[], page: number, pageSize: number): any[] {
    return this.pagingService.getPageItems(items, page, pageSize);
  }

  constructor(private pagingService: PagingService) {}
}

import * as dialogActions from '../actions/dialog-view.action';
import { ActivityPrototypeWarning, ChangeItem, FailedParticipantsInformation } from '../../models';

export interface State {
  listsFiltered: boolean;
  externalParticipantsLoading: boolean;
  changeLog: ChangeItem[];
  failedParticipantsInformation: FailedParticipantsInformation;
  dirty: boolean;
  timeZoneLoading: boolean;
  timeZoneFailed: boolean;
  displayVirtualMeeting: boolean;
  displayActivityLocation: boolean;
  activityPrototypeWarnings?: ActivityPrototypeWarning[];
}

export const initialState = (): State => ({
  listsFiltered: false,
  externalParticipantsLoading: false,
  changeLog: [],
  failedParticipantsInformation: null,
  dirty: false,
  timeZoneLoading: false,
  timeZoneFailed: false,
  displayVirtualMeeting: false,
  displayActivityLocation: false,
  activityPrototypeWarnings: null
});

export function reducer(state = initialState(), action: dialogActions.DialogViewAction): State {
  switch (action.type) {
    case dialogActions.UPDATE_CHANGE_LOG: {
      return {
        ...state,
        changeLog: [...state.changeLog, ...action.payload]
      };
    }

    case dialogActions.RESET_CHANGE_LOG: {
      return {
        ...state,
        changeLog: []
      };
    }

    case dialogActions.SUBMIT_EXTERNAL_PARTICIPANTS_LIST: {
      return {
        ...state,
        externalParticipantsLoading: true
      };
    }

    case dialogActions.EXTERNAL_PARTICIPANTS_LOADED: {
      return {
        ...state,
        externalParticipantsLoading: false
      };
    }

    case dialogActions.TOGGLE_LISTS_FILTERED: {
      return {
        ...state,
        listsFiltered: !state.listsFiltered
      };
    }

    case dialogActions.MARK_FORM_DIRTY: {
      return {
        ...state,
        dirty: true
      };
    }

    case dialogActions.RESET_DIRTY_FLAG: {
      return {
        ...state,
        dirty: false
      };
    }

    case dialogActions.UPDATE_FAILED_PARTICIPANTS: {
      return {
        ...state,
        failedParticipantsInformation:
          action.payload && action.payload.participants.length > 0 ? action.payload : null
      };
    }

    case dialogActions.SUBMIT_TIME_ZONE_UPDATE: {
      return {
        ...state,
        timeZoneLoading: true,
        timeZoneFailed: false
      };
    }

    case dialogActions.UPDATE_TIME_ZONE_SUCCESS: {
      return {
        ...state,
        timeZoneLoading: false
      };
    }
    case dialogActions.UPDATE_TIME_ZONE_FAIL: {
      return {
        ...state,
        timeZoneLoading: false,
        timeZoneFailed: true
      };
    }
    case dialogActions.SET_PRESELECT_WARNINGS: {
      return {
        ...state,
        activityPrototypeWarnings: action.payload
      };
    }
    case dialogActions.SET_VIRTUAL_MEETING_VISIBILITY: {
      return {
        ...state,
        displayVirtualMeeting: action.payload
      };
    }
    case dialogActions.SET_ACTIVITY_LOCATION_VISIBILITY: {
      return {
        ...state,
        displayActivityLocation: action.payload
      };
    }

  }

  return state;
}

export const getChangeLog = (state: State) => state.changeLog;
export const getListsFiltered = (state: State) => state.listsFiltered;
export const getExternalParticipantsLoading = (state: State) => state.externalParticipantsLoading;
export const getFormDirty = (state: State) => state.dirty;
export const getFailedParticipantsInformation = (state: State) =>
  state.failedParticipantsInformation;
export const getTimeZoneLoading = (state: State) =>
  state.timeZoneLoading;
export const getTimeZoneFailed = (state: State) =>
  state.timeZoneFailed;
export const getDisplayVirtualMeeting = (state: State) => state.displayVirtualMeeting;
export const getDisplayActivityLocation = (state: State) => state.displayActivityLocation;

export const getFundsPreselected = (state: State) => {
  return !!state &&
    state.activityPrototypeWarnings &&
    state.activityPrototypeWarnings.includes(ActivityPrototypeWarning.Fund);
};

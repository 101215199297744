import {
  Component,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { Alert } from '../../models/alert.model';

@Component({
  selector: 'ea-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  @Input() disableSave: boolean;
  @Input() displayEmptySubjectWarning = false;
  @Input() alerts: Alert[] = [];
  @Input() eventActivity: boolean;
  @Input() displayDeleteButton: boolean;

  @Output() saveAndClose = new EventEmitter();
  @Output() cancelSave = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() delete = new EventEmitter();

  public displayErrorMessage: boolean;
  public errorMessage: string;

  constructor(
    private modalService: ModalService
  ) { }

  onSaveAndClose() {
    if (this.displayEmptySubjectWarning) {
      this.openSaveModal();
    } else {
      this.saveAndClose.emit();
    }
  }

  onDelete() {
    this.openDeleteModal();
  }

  onClose() {
      this.close.emit();
  }

  private openSaveModal() {
    this.modalService.openModal({
      title: 'bd/event-activity/form.empty_subject_save_title',
      text: 'bd/event-activity/form.empty_subject_save_warning',
      titleCancelButton: 'bd/event-activity/form.go_back',
      titleConfirmButton: 'bd/event-activity/form.save',
      type: 'info',
      confirmEventEmit: () => this.saveAndClose.emit(),
      cancelEventEmit: () => this.cancelSave.emit()});
  }

  private openDeleteModal() {
    this.modalService.openModal({
      title: 'bd/event-activity/form.delete_activity_title',
      text: this.eventActivity ?
        'bd/event-activity/form.delete_activity_warning' :
        'bd/event-activity/form.delete_activity_without_event_warning',
      titleCancelButton: 'bd/event-activity/form.cancel',
      titleConfirmButton: 'bd/event-activity/form.delete',
      type: 'danger',
      confirmEventEmit: () => this.delete.emit()});
  }
}

import { EntityId } from './entity-id';
import { EntityType } from './entity-type.enum';
import { Participant } from './participant.model';
import { ExternalParticipantStatus } from './external-participant-status.enum';
import { CompositeId } from './composite-id';

export interface ExternalParticipant extends Participant {
  firstName?: string;
  lastName?: string;
  status?: ExternalParticipantStatus;
}

export class ContactExternalParticipant implements ExternalParticipant {
  contact: EntityId;
  company: EntityId;

  constructor(
    prId: number,
    crmId: number,
    name: string,
    companyName: string,
    prCompanyId: number,
    crmCompanyId: number,
    public firstName: string,
    public lastName: string,
    public id?: number,
    public mobilePhone: string = null,
    public workPhone: string = null,
    public email: string = null,
    public companyCity: string = null,
    public companyCountry: string = null,
    public status: ExternalParticipantStatus = ExternalParticipantStatus.None
  ) {
    this.contact = { id: { prId, crmId }, name: name, type: EntityType.Contact };
    this.company = {
      id: { prId: prCompanyId, crmId: crmCompanyId },
      name: companyName,
      type: EntityType.Institution
    };
  }
}

export class InstitutionExternalParticipant implements ExternalParticipant {
  company: EntityId;

  constructor(
    prId: number,
    crmId: number,
    name: string,
    public hasContacts: boolean = false,
    public id?: number,
    public companyCity: string = null,
    public companyCountry: string = null
  ) {
    this.company = { id: { prId, crmId }, name: name, type: EntityType.Institution };
  }

  setHasContacts(hasContacts: boolean) {
    this.hasContacts = hasContacts;
  }
}

export function isEqualParticipants(a: ExternalParticipant, b: ExternalParticipant) {
  return isEqualContacts(a, b) || (!a.contact && !b.contact && isEqualCompanies(a, b));
}

export function isEqualCompanies(a: ExternalParticipant, b: ExternalParticipant) {
  return a.company && b.company && isEqualCompositeId(a.company.id, b.company.id);
}

export function isEqualContacts(a: ExternalParticipant, b: ExternalParticipant) {
  return a.contact && b.contact && isEqualCompositeId(a.contact.id, b.contact.id);
}

export function isEqualCompositeId(idA: CompositeId, idB: CompositeId) {
  return (
    (idA.prId && idB.prId && idA.prId === idB.prId) ||
    (idA.crmId && idB.crmId && idA.crmId === idB.crmId)
  );
}

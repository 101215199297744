import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { inputDebounceTime } from '../../models';

@Component({
  selector: 'ea-address-comment',
  templateUrl: './address-comment.component.html',
  styleUrls: ['./address-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressCommentComponent implements OnInit, OnDestroy {
  @Input() value: string;
  @Output() commentChange = new EventEmitter<string>();

  addressComment = new FormControl();

  private destroySubject$: Subject<void> = new Subject();

  constructor() { }

  ngOnInit() {
    this.addressComment.setValue(this.value);

    this.addressComment.valueChanges
      .pipe(
        debounceTime(inputDebounceTime),
        distinctUntilChanged(),
        filter((v: string) => !!v || !!this.value),
        takeUntil(this.destroySubject$)
      )
      .subscribe(d => {
        this.commentChange.emit(d);
      });
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}

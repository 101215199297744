<ea-alert *ngFor="let alert of alerts" [alert]="alert"></ea-alert>
<div class="c-footer c-modal-footer ea-footer">
  <div class="c-footer-left">
  </div>
  <div class="c-footer-right">
    <button
      (click)="onClose()"
      class="c-btn c-btn-default c-m-left-md" data-automation-id="close-button">
      {{'bd/event-activity/form.close' | translate}}
    </button>
    <ng-container *ngIf="displayDeleteButton">
      <button
        (click)="onDelete()"
        class="c-btn c-btn-default c-m-left-md" data-automation-id="delete-button">
        <i class="fa fa-trash c-text-danger"></i>
        {{'bd/event-activity/form.delete' | translate}}
      </button>
    </ng-container>
    <button
      [disabled]="disableSave"
      (click)="onSaveAndClose()"
      class="c-btn c-btn-primary c-m-left-md" data-automation-id="save-and-close-button">
      {{'bd/event-activity/form.save_and_close' | translate}}
    </button>
  </div>
</div>

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { SILENT_SIGN_IN_ERROR_HANDLER } from '../../authorization/contracts/silent-sign-in-error-handler';

import { NotAuthorizedHandler } from './not-authorized.handler';
import { AuthorizationErrorInterceptor } from './authorization-error.interceptor';
import { IframeRedirectNavigator } from './iframe-redirect-navigator';
import { AuthRedirectNavigator } from '../../authorization/contracts/redirect-navigator';
import { OnAuthorizedHandler } from '../../authorization/contracts/on-authorized-handler';
import { State, LoadUserProfile } from '../../store';

export function onAuthorizeHandlerFactory(store: Store<State>) {
  return { handle: () => store.dispatch(new LoadUserProfile()) };
}

export const authHandlers = [
    NotAuthorizedHandler,
    { provide: OnAuthorizedHandler, useFactory: onAuthorizeHandlerFactory, deps: [Store] },
    { provide: SILENT_SIGN_IN_ERROR_HANDLER, useExisting: NotAuthorizedHandler },
    { provide: AuthRedirectNavigator, useClass: IframeRedirectNavigator },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationErrorInterceptor,
      multi: true
    }
];

import { Pipe, PipeTransform } from '@angular/core';
import { ExternalParticipantStatus } from '../../models/participant/external-participant-status.enum';

@Pipe({
  name: 'participantStatusIcon'
})
export class ParticipantStatusIconPipe implements PipeTransform {
  transform(status: ExternalParticipantStatus) {
    switch (status) {
        case ExternalParticipantStatus.Accepted: {
          return 'fa fa-check-circle c-text-success';
        }
        case ExternalParticipantStatus.Declined: {
          return 'fa fa-times-circle c-text-danger';
        }
        case ExternalParticipantStatus.Tentative: {
          return 'fa fa-question-circle c-text-muted' ;
        }
        case ExternalParticipantStatus.Invited: {
          return 'fa fa-share c-text-muted';
        }
        case ExternalParticipantStatus.None: {
          return '';
        }
      }
  }
}

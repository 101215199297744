export interface UserProfile {
  userPreferences?: UserPreferences;
  companyPreferences?: CompanyPreferences;
  isJpmcClient: boolean;
  isBonyClient: boolean;
  defaultToNewProfiles: boolean;
  isSymbolEnabled: boolean;
  isAttachmentEnabled: boolean;
  isSavedLocationEnabled: boolean;
  isHubActivityFormEnabled: boolean;
  flavor: Flavor;
}

export interface UserPreferences {
  culture?: string;
  country?: string;
  language?: string;
  shortDatePattern?: string;
  shortTimePattern?: string;
  dstTimeZone?: Timezone;
}

export interface CompanyPreferences {
  hubLocationId?: number;
}

export interface Timezone {
  timezone_nm: string;
}

export enum Flavor {
  Bd = 'BD',
  Ihs = 'IHS'
}

import * as securityActions from '../actions/securities.action';
import { SourceSecurity } from '../../models/security/source-security.model';
import { Security } from '../../models';

export interface State {
  data: SourceSecurity[];
  selected: Security[];
  loaded: boolean;
  loading: boolean;
}

export const initialState = (): State => ({
  data: [],
  selected: [],
  loaded: false,
  loading: false
});

export function reducer(
  state = initialState(),
  action: securityActions.SecuritiesAction
): State {
  switch (action.type) {
    case securityActions.LOAD_SECURITIES: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }
    case securityActions.LOAD_SECURITIES_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loaded: true,
        loading: false
      };
    }

    case securityActions.SET_SELECTED_SECURITIES:
    case securityActions.INIT_SELECTED_SECURITIES: {
      return {
        ...state,
        selected: action.payload
      };
    }
  }

  return state;
}

export const getSecurities = (state: State) =>
  state.data.filter(d => !state.selected.find(s => s.id === d.key));

export const getSecuritiesLoading = (state: State) =>
  state.loading;

export const getSelected = (state: State) =>
  state.selected;

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { UserService, PendoScriptLoaderService } from '../../services';
import * as userActions from '../actions/user.action';
import * as translateActions from '../actions/translation.action';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions,
    private userService: UserService,
    private pendoLoader: PendoScriptLoaderService
  ) {}

  @Effect()
  loadUserProfile$ = this.actions$.pipe(
    ofType(userActions.LOAD_USER_PROFILE),
    switchMap(() =>
      this.userService.load().pipe(
        switchMap(user => [
          new userActions.LoadUserProfileSuccess(user),
          new translateActions.LoadTranslation(user.userPreferences.language)
        ]),
        catchError(error => of(new userActions.LoadUserProfileFail(error)))
      )
    )
  );

  @Effect( { dispatch: false })
  loadPendoScript$ = this.actions$.pipe(
    ofType(userActions.LOAD_USER_PROFILE_SUCCESS),
      tap(() => {
        this.pendoLoader.loadPendoAnalyticsScript();
    })
  );

  @Effect()
  setDefaultLocation$ = this.actions$.pipe(
    ofType(userActions.SET_DEFAULT_LOCATION),
    switchMap((action: userActions.SetDefaultLocation) =>
      this.userService.setDefaultLocation(action.payload).pipe(
        map(() =>
          new userActions.SetDefaultLocationSuccess(action.payload)
        ),
        catchError((error) => of(new userActions.SetDefaultLocationFail(error)))
      )
    )
  );
}

import { ActivitySubType } from './activity-type.enum';
import { ActivityMode } from './activity-mode.enum';
import { ActivityMethod } from './activity-method.enum';
import { ActivityInitiator } from './activity-initiator.enum';

export enum ActivityTemplateCode {
  OneOnOne = 1,
  GroupMeeting = 2,
  ConferenceCall = 3,
  IncomingCall = 4,
  OutgoingCall = 5,
  IncomingEmail = 6,
  OutgoingEmail = 7,
  Other = 8
}

export interface ActivityTemplate {
  id: ActivityTemplateCode;
  localizationKey: string;
  subTypes: ActivitySubType[];
  modes: ActivityMode[];
  method: ActivityMethod;
  defaultSubType?: ActivitySubType;
  defaultMode?: ActivityMode;
  defaultInitiator?: ActivityInitiator;
  initiators?: ActivityInitiator[];
  hidden?: boolean;
}

import {
  OnDestroy,
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UploadEvent, FileInfo, FileState } from '@progress/kendo-angular-upload';
import { ActivityAttachmentMapper } from '../../services/activity-attachment-mapper.service';
import { getActivityAttachments, getSaveAttachmentsError, getActivityAttachmentErrors } from '../../store';
import * as fromAttachment from '../../store';
import {
  RemoveActivityAttachmentSubmit,
  DownloadActivityAttachment, AddActivityAttachments, SetErrors
} from '../../store/actions/attachment.action';
import { ActivityAttachment, ALLOWED_FILE_TYPES } from '../../models';
import { ActivityAttachmentValidationService } from '../../services';

@Component({
  selector: 'ea-attachments-container',
  templateUrl: 'event-activity-attachments.component.html',
  styleUrls: ['event-activity-attachments.component.scss'],
  // Kendo file uploader, do not support OnPush change detection strategy.
  changeDetection: ChangeDetectionStrategy.Default
})
export class AttachmentsComponent implements OnDestroy, OnInit {

  public attachmentsFileInfo$: Observable<FileInfo[]>;
  public attachments: ActivityAttachment[];
  public acceptList: string[] = ALLOWED_FILE_TYPES;
  public partialError$: Observable<boolean>;
  public errorMessage$: Observable<string>;

  private destroyedSubject$ = new Subject();

  constructor(
    private activityAttachmentMapper: ActivityAttachmentMapper,
    private attachmentValidation: ActivityAttachmentValidationService,
    private store: Store<fromAttachment.State>,
    private cdr: ChangeDetectorRef) { }


  ngOnInit(): void {
    this.attachmentsFileInfo$ = this.store.pipe(select(getActivityAttachments),
      map(attachments =>
        attachments.map((a: ActivityAttachment) => {
          return <FileInfo>{ ...a, state: FileState.Uploaded};
        }))
      );
    this.store.pipe(select(getActivityAttachments),
      takeUntil(this.destroyedSubject$)
    ).subscribe( a => {
      this.attachments = a;
    });
    this.partialError$ = this.store.pipe(select(getSaveAttachmentsError));
    this.errorMessage$ = this.store.pipe(
      select(getActivityAttachmentErrors),
      map(errors => errors && errors.join(' '))
    );
  }

  ngOnDestroy(): void {
    if (this.cdr) {
      this.cdr.detach();
      this.cdr = null;
    }
    this.destroyedSubject$.next();
    this.destroyedSubject$.complete();
  }

  onUpload(e: UploadEvent) {
    const errorMessages: string[] = [];
    const newAttachments: ActivityAttachment[] = [];

    e.files.forEach(file => {
      const message = this.attachmentValidation.validateFile(file, [...this.attachments, ...newAttachments]);
      if (message) {
        errorMessages.push(message);
      } else {
        newAttachments.push(this.activityAttachmentMapper.fromFileInfo(file));
      }
    });

    this.store.dispatch(new SetErrors(errorMessages));

    if (errorMessages.length === e.files.length) {
      e.preventDefault();
    } else {
      this.store.dispatch(
        new AddActivityAttachments(newAttachments)
      );
    }

    this.detectChanges();
  }

  onRemove(removeId: string) {
    this.store.dispatch(new RemoveActivityAttachmentSubmit(removeId));
    this.detectChanges();
  }

  public onDownload(attachment: ActivityAttachment) {
    this.store.dispatch(new DownloadActivityAttachment(attachment));
  }

  private detectChanges() {
    if (this.cdr) {
      this.cdr.detectChanges();
    }
  }
}

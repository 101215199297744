import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject, Observable, asyncScheduler, combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, observeOn, takeUntil } from 'rxjs/operators';
import {
  ActivityDefinedField, ActivityDefinedFieldElement, ActivityDefinedFieldValue, inputDebounceTime
} from '../../../models';
import { AdfComponentInterface } from '../adf-component.interface';

@Component({
  selector: 'ea-singleselect-adf',
  templateUrl: './singleselect-adf.component.html',
  styleUrls: ['./singleselect-adf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SingleSelectAdfComponent implements AdfComponentInterface, OnInit, OnDestroy {
  @Input() field: ActivityDefinedField;
  @Output() valueChange = new EventEmitter<ActivityDefinedField>(true);

  suggesterInput = new FormControl('');
  filteredItems$ = new BehaviorSubject<ActivityDefinedFieldElement[]>([]);
  selectedItems$: Observable<ActivityDefinedFieldElement[]>;
  optionsLimit = 100;

  private selectedItemsSubject$ = new BehaviorSubject<ActivityDefinedFieldElement[]>([]);
  private destroySubject$ = new Subject();

  constructor() {
    this.selectedItems$ = this.selectedItemsSubject$.pipe(observeOn(asyncScheduler));
  }

  ngOnInit() {
    combineLatest(
      [this.suggesterInput.valueChanges.pipe(
        debounceTime(inputDebounceTime),
        distinctUntilChanged()
      ),
      this.selectedItemsSubject$]
    ).pipe(
      takeUntil(this.destroySubject$)
    ).subscribe(([searchTerm, selectedItems]) => {
      this.filteredItems$.next(this.filterOptions(searchTerm, selectedItems));
    });

    if (this.field) {
      if (this.field.values && this.field.values.length > 0) {
        this.selectedItemsSubject$.next([{
          id: this.field.values[0].elementId,
          name: this.field.values[0].textValue
        }]);
    } else if (this.field.elements) {
        this.onAdfSingleSelectChange([this.field.elements[0]]);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  onAdfSingleSelectChange(newValue: ActivityDefinedFieldElement[]) {
    if (newValue.length) {
      const selectedValues = [
          <ActivityDefinedFieldValue>{
            elementId: newValue[0].id,
            textValue: newValue[0].name
          }
        ];
        this.valueChange.emit(<ActivityDefinedField>{
        ...this.field,
        values: selectedValues
      });
    } else {
      this.valueChange.emit(<ActivityDefinedField>{ ...this.field, values: [] });
    }

    this.selectedItemsSubject$.next([...newValue]);
  }

  private filterOptions(
    searchTerm: string,
    selectedItems: ActivityDefinedFieldElement[]
    ): ActivityDefinedFieldElement[] {

    const term = searchTerm.toLocaleLowerCase();

    if (selectedItems.length) {
        const selected = selectedItems[0].name.toLocaleLowerCase();

        if (selected === term) {
            return this.field.elements;
        }
    }

    return this.field.elements.filter(e => e.name.toLocaleLowerCase().includes(term));
  }
}

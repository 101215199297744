import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {
  ParticipantMissingDataModalContentComponent
} from '@ipreo/event-participant-missing-data-edit';
import { CupcakeModalOptions, CupcakeModalService } from '@ipreo/cupcake-components';
import { FailedParticipantsInformation } from '../../models';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';

@Component({
  selector: 'ea-missing-data-alert',
  templateUrl: './missing-data-alert.component.html',
  styleUrls: ['./missing-data-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissingDataAlertComponent implements OnChanges {
  @Input()
  context: FailedParticipantsInformation;

  @Output() hide = new EventEmitter();

  message = '';

  constructor(private modalService: CupcakeModalService, private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.context && !isEqual(changes.context.currentValue, changes.context.previousValue)) {
      this.message = this.getMessage();
    }
  }

  hideMessage() {
    this.hide.emit();
  }

  openReviewModal() {
    this.modalService.open({
      mode: 'component',
      title: this.translate.instant('bd/event-activity/form.review_failed_participants_title', {
        count: this.context.participants && this.context.participants.length
      }),
      contentWidth: 740,
      view: ParticipantMissingDataModalContentComponent,
      context: {
        participants: this.context.participants
      }
    } as CupcakeModalOptions);
  }

  getMessage(): string {
    if (!this.context.participants) {
      return '';
    }

    const added =
      this.context.totalCount - this.context.skippedCount - this.context.participants.length;

    const counts = [
      added &&
        this.translate.instant('bd/event-activity/form.participants_added', { count: added }),
      this.context.skippedCount &&
        this.translate.instant('bd/event-activity/form.skipped', {
          count: this.context.skippedCount
        }),
      this.context.participants.length &&
        this.translate.instant('bd/event-activity/form.failed', {
          count: this.context.participants.length
        })
    ]
      .filter(Boolean)
      .join(', ');

    const ofTotalMessage = this.context.totalCount > 1 ?
        'bd/event-activity/form.of_total_participants' :
        'bd/event-activity/form.of_total_participant';

    return `${counts} ${this.translate.instant(ofTotalMessage, {
      count: this.context.totalCount
    })}`;
  }
}

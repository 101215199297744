import * as eventActivityActions from '../actions/event-activity-data.action';
import { InternalParticipant, ListEntity, ListType, Event } from '../../models';

export interface State {
  event: Event;
  internalParticipants: { data: InternalParticipant[]; loading: boolean; loaded: boolean };
  institutionLists: { data: ListEntity[]; totalCount: number; loaded: boolean };
  contactLists: { data: ListEntity[]; totalCount: number; loaded: boolean };
}

export const initialState = (): State => ({
  event: null,
  internalParticipants: { data: [], loading: false, loaded: false },
  institutionLists: { data: [], totalCount: 0, loaded: false },
  contactLists: { data: [], totalCount: 0, loaded: false }
});

export function reducer(
  state = initialState(),
  action: eventActivityActions.EventActivityDataAction
): State {
  switch (action.type) {
    case eventActivityActions.LOAD_INTERNAL_PARTICIPANTS: {
      return {
        ...state,
        internalParticipants: { ...state.internalParticipants, loading: true }
      };
    }
    case eventActivityActions.LOAD_INTERNAL_PARTICIPANTS_SUCCESS: {
      return {
        ...state,
        internalParticipants: { data: action.payload, loaded: true, loading: false }
      };
    }
    case eventActivityActions.LOAD_INTERNAL_PARTICIPANTS_FAIL: {
      return {
        ...state,
        internalParticipants: { ...state.internalParticipants, loading: false }
      };
    }
    case eventActivityActions.LOAD_LISTS: {
      return {
        ...state,
        institutionLists: {
          ...state.institutionLists,
          loaded: false
        },
        contactLists: {
          ...state.contactLists,
          loaded: false
        }
      };
    }
    case eventActivityActions.LOAD_LISTS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        institutionLists: {
          data: payload.institutionLists.data,
          totalCount: payload.institutionLists.totalCount,
          loaded: true
        },
        contactLists: {
          data: payload.contactLists.data,
          totalCount: payload.contactLists.totalCount,
          loaded: true
        }
      };
    }
    case eventActivityActions.LOAD_EVENT_DATA: {
      return {
        ...state,
        event: action.payload
      };
    }
  }

  return state;
}

export const getInternalParticipants = (state: State) => state.internalParticipants.data;
export const getInternalParticipantsLoaded = (state: State) => state.internalParticipants.loaded;

export const getEventSchedules = (state: State) => state && state.event && state.event.schedules || [];
export const getEventName = (state: State) => state && state.event && state.event.name;
export const getIsVirtualEvent = (state: State) => state && state.event && state.event.isVirtual;

export const getTotalCount = (state: State) => {
  return {
    [ListType.Account]: {
      total: state.institutionLists.totalCount,
      loaded: state.institutionLists.data.length
    },
    [ListType.Contact]: {
      total: state.contactLists.totalCount,
      loaded: state.contactLists.data.length
    }
  };
};
export const getLists = (state: State) => [
  ...state.contactLists.data,
  ...state.institutionLists.data
];

export const getListsLoaded = (state: State) =>
  state.institutionLists.loaded && state.contactLists.loaded;

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class ActivityAttachmentService {
  private baseUrl = `${environment.hubBaseUrl}/api/activity`;

  constructor(private http: HttpClient) {}

  getAttachment(
    args: {
      activityId: string;
      documentId: string;
    }
  ): Observable<Blob> {
    return this.http.get(
      `${this.baseUrl}/${args.activityId}/documents/${args.documentId}`,
      { responseType: 'blob'});
  }
}

import {
  ChangeDetectorRef,
  EventEmitter,
  Input, OnDestroy, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  CupcakeSuggesterActions,
  CupcakeSuggesterComponent,
  CupcakeSuggesterContainerPosition
} from '@ipreo/cupcake-suggester';
import { PlaceFacade } from '../../services/place.facade';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ActivityLocation } from '../../models/activity-location.model';
import { FormControl } from '@angular/forms';
import { WarningPopover } from './warning-popover';
import { LocationsSourceService } from '../../services/locations-source.service';
import { LocationGroupEnum, LocationViewModel } from '../../models';
import { PlaceAutocompletePrediction } from '../../models/place-autocomplete-prediction.model';

export abstract class BaseActivityLocationComponent implements OnInit, OnDestroy {
  @Input() value$: Observable<string>;
  @Input() eventLocationMatched$: Observable<boolean> = of(true);
  @Input() timeZoneFailed$: Observable<boolean> = of(false);
  @Output() locationSelected: EventEmitter<ActivityLocation> = new EventEmitter<ActivityLocation>();

  @ViewChild('suggester', { static: false }) suggester: CupcakeSuggesterComponent;

  public suggesterInput = new FormControl();
  public suggesterPositions = CupcakeSuggesterContainerPosition;
  public suggesterControlStream: Subject<CupcakeSuggesterActions> = new Subject<
    CupcakeSuggesterActions
  >();
  public locations$: Observable<LocationViewModel[]>;

  public popoverOptions = new WarningPopover();

  public tzFailed: boolean;

  public selectedAddress: string;
  public selectedValue: string[];
  public hasGoogleResults$: Observable<boolean> = of(true);
  public locationGroup = LocationGroupEnum;

  protected destroySubject$: Subject<void> = new Subject();

  private selectedPlace: string;
  private LOCATION_SEARCH_WAIT_TIME = 400;
  private placeSearchText = new ReplaySubject<string>(1);
  private touched = false;
  private inputChanged: boolean;

  constructor(
    private placeFacade: PlaceFacade,
    protected cdr: ChangeDetectorRef,
    protected sourceService: LocationsSourceService
  ) {}

  public ngOnInit() {
    this.locations$ = this.sourceService.getLocationsSource();

    this.placeSearchText.pipe(
      debounceTime(this.LOCATION_SEARCH_WAIT_TIME),
      distinctUntilChanged(),
      filter(x => !!x),
      tap(() => {
        this.inputChanged = true;
        this.popoverOptions.showTzPopover = false;
      }),
      takeUntil(this.destroySubject$)
    ).subscribe((value) => {
      this.fetchResult(value);
      });
    this.suggesterInput.valueChanges
      .pipe(
        withLatestFrom(this.value$),
        takeUntil(this.destroySubject$)
      )
      .subscribe(([change, value]) => {
        if (!change) {
          this.onAddressClear(value);
        }
        if (change !== value) {
          this.sourceService.setLoading(true);
        }

        this.placeSearchText.next(change);
    });

    this.eventLocationMatched$.pipe(takeUntil(this.destroySubject$)).subscribe(matched => {
      this.popoverOptions.warningMessage = 'bd/event-activity/form.location_does_not_match_event_location';
      this.popoverOptions.showMatchPopover = !matched && this.touched;
      this.cdr.detectChanges();
    });

    this.value$.pipe(takeUntil(this.destroySubject$)).subscribe(value => {
      this.suggesterInput.setValue(value);
      this.selectedAddress = value;
      this.selectedValue = value ? [value] : [];
      this.cdr.detectChanges();
    });

    this.timeZoneFailed$.pipe(
      takeUntil(this.destroySubject$),
    ).subscribe(failed => {
      if (failed) {
        this.suggesterControlStream.next(CupcakeSuggesterActions.ClearAll);
        this.popoverOptions.warningMessage = 'bd/event-activity/form.time_zone_update_failed';
        this.popoverOptions.showTzPopover = true;
      }
      this.tzFailed = failed;
    });
  }

  public ngOnDestroy(): void {
    this.destroySubject$.next();
  }

  public onAddressClear(value: string) {
    this.clearAddress(value);
  }

  public onLocationSelected(selectedItems: LocationViewModel[]) {
    this.touched = true;
    if (selectedItems.length === 0) {
      return;
    }

    if (selectedItems[0].groupKey === LocationGroupEnum.GoogleLocations) {
      this.selectGoogleResult(selectedItems[0].item as PlaceAutocompletePrediction);
    }
  }

  public mapToSelected() {
    this.touched = true;
    setTimeout(() => {
      if (this.selectedPlace === this.suggesterInput.value) {
        this.suggesterInput.setValue(this.selectedAddress);
        this.cdr.detectChanges();
      } else if (this.inputChanged && this.selectedAddress !== this.suggesterInput.value) {
        this.suggesterControlStream.next(CupcakeSuggesterActions.ClearAll);
        this.locationSelected.emit(null);
      }
      this.inputChanged = false;
    }, 200);
  }

  public onFocus() {
    this.popoverOptions.showTzPopover = this.tzFailed;
  }

  private selectGoogleResult(item: PlaceAutocompletePrediction) {
    const { placeId, description } = item;
    this.selectedPlace = description;

    this.placeFacade.getDetails({ placeId }).pipe(takeUntil(this.destroySubject$)).subscribe(location => {
      this.selectedAddress = this.placeFacade.getDescription(
        location.venueName,
        location.address
      );
      this.locationSelected.emit(location);
      this.cdr.detectChanges();
    });

    this.sourceService.setAutocompleteSessionToken();
  }

  private clearAddress(value: string) {
    if (!!this.selectedAddress) {
      if (!!value) {
        this.locationSelected.emit(null);
      }
      this.selectedAddress = null;
    }
  }

  public abstract fetchResult(value: string): void;
}

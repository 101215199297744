<div class="c-dropdown" data-automation-id="external-participant-list-container"
      eaClickOutside
      (clickOutside)="closeDropdown()">
    <button class="c-btn c-btn-default c-btn-dropdown c-p-horizontal-sm"
            data-automation-id="add-contact-or-institution-list-button"
            #dropdown
            [title]="'bd/event-activity/form.list_tooltip' | translate"
            [disabled]="disabled"
            (click)="toggleDropdown()"
            [cupcakeDropdownTrigger]="dropdownOptions">
        <i aria-hidden="true" class="fa fa-list"></i>&nbsp; <i aria-hidden="true" class="fa fa-caret-down"></i>
    </button>
    <div class="c-dropdown-list c-dropdown-list-below ea-lists-selector" (click)="stopPropagation($event)">
        <cupcake-suggester #suggester
                           [formControl]="searchText"
                           [suggesterPlaceholder]="'bd/event-activity/form.list_placeholder' | translate"
                           [suggesterMinLength]="0"
                           [suggesterDataSource]="data"
                           [suggesterIdField]="'id'"
                           [suggesterValueField]="'name'"
                           [suggesterMultiselectMode]="false"
                           [suggesterFilterMode]="false"
                           [suggesterNoResultsText]="'bd/event-activity/form.no_list_found' | translate"
                           [suggesterGroupField]="'type'"
                           [suggesterGroupHeaderTemplate]="groupHeader"
                           [suggesterItemTemplate]="resultTemplate"
                           (suggesterOnSelectEvent)="onListSelect($event)">
        </cupcake-suggester>
    </div>
    <ng-template #groupHeader let-item="item">
        {{ getGroupHeader(item.item.model) }}
    </ng-template>
    <ng-template #resultTemplate let-item="item">
        <div class="c-d-flex">
            <div class="ea-list-name">{{item.item.model.name}}</div>
            <div class="c-text-muted">{{item.item.model.itemsCount}}</div>
        </div>
    </ng-template>
</div>

import { Action } from '@ngrx/store';
import { Security, SourceSecurity } from '../../models';
import { FailAction } from '../../../store/actions/fail-action';

export const LOAD_SECURITIES = '[SECURITIES] Load Securities';
export const LOAD_SECURITIES_SUCCESS = '[SECURITIES] Load Securities Success';
export const LOAD_SECURITIES_FAIL = '[SECURITIES] Load Securities Fail';

export const SUBMIT_SECURITIES = '[EVENT ACTIVITY DIALOG] Submit Securities';
export const SET_SELECTED_SECURITIES = '[SECURITIES] Set Selected Securities';
export const INIT_SELECTED_SECURITIES = '[SECURITIES] Init Selected Securities';

export class LoadSecurities implements Action {
  readonly type = LOAD_SECURITIES;

  constructor(public payload: string) {}
}

export class LoadSecuritiesSuccess implements Action {
  readonly type = LOAD_SECURITIES_SUCCESS;

  constructor(
    public payload: SourceSecurity[]
  ) {}
}

export class LoadSecuritiesFail extends FailAction {
  readonly type = LOAD_SECURITIES_FAIL;

  constructor(public error: any) {
    super();
  }
}

export class SubmitSecurities implements Action {
  readonly type = SUBMIT_SECURITIES;

  constructor (public payload: SourceSecurity[]) {}
}

export class SetSelectedSecurities implements Action {
  readonly type = SET_SELECTED_SECURITIES;

  constructor(
    public payload: Security[]
  ) {}
}

export class InitSelectedSecurities implements Action {
  readonly type = INIT_SELECTED_SECURITIES;

  constructor(
    public payload: Security[]
  ) {}
}

export type SecuritiesAction =
  | LoadSecurities
  | LoadSecuritiesSuccess
  | LoadSecuritiesFail
  | SubmitSecurities
  | SetSelectedSecurities
  | InitSelectedSecurities;

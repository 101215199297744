export const ALLOWED_FILE_TYPES = [
  'text/csv',
  'video/avi',
  'video/msvideo',
  'text/xml',
  'image/bmp',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/x-dvi',
  'image/gif',
  'application/x-compressed',
  'application/x-gzip',
  'text/html',
  'image/jpeg',
  'image/pjpeg',
  'audio/midi',
  'audio/x-midi',
  'audio/mpeg3',
  'audio/x-mpeg3',
  'video/mpeg',
  'video/x-mpeg',
  'application/pdf',
  'image/png',
  'image/x-png',
  'application/mspowerpoint',
  'application/powerpoint',
  'application/vnd.ms-powerpoint',
  'application/x-ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'audio/x-pn-realaudio',
  'application/vnd.rn-realmedia',
  'application/rtf',
  'application/x-rtf',
  'text/richtext',
  'application/plain',
  'image/tiff',
  'image/x-tiff',
  'audio/wav',
  'application/wordperfect',
  'application/mswrite',
  'application/excel',
  'application/vnd.ms-excel',
  'application/x-msexcel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/x-zip-compressed',
  'application/zip',
  'multipart/x-zip',
  'application/vnd.ms-project',
  'application/x-project',
  'video/mp4',
  'video/x-ms-wmv',
  'audio/mp4'
];

import { Action } from '@ngrx/store';

import { UserProfile } from '../../models';
import { FailAction } from './fail-action';

export const LOAD_USER_PROFILE = '[USER PROFILE] Load';
export const LOAD_USER_PROFILE_SUCCESS = '[USER PROFILE] Load Success';
export const LOAD_USER_PROFILE_FAIL = '[USER PROFILE] Load Fail';
export const SET_DEFAULT_LOCATION = '[USER PROFILE] Set Default Location';
export const SET_DEFAULT_LOCATION_SUCCESS = '[USER PROFILE] Set Default Location Success';
export const SET_DEFAULT_LOCATION_FAIL = '[USER PROFILE] Set Default Location Fail';

export class LoadUserProfile implements Action {
  readonly type = LOAD_USER_PROFILE;
}

export class LoadUserProfileSuccess implements Action {
  readonly type = LOAD_USER_PROFILE_SUCCESS;

  constructor(public payload: UserProfile) {}
}
export class LoadUserProfileFail extends FailAction {
  readonly type = LOAD_USER_PROFILE_FAIL;

  constructor(public error: any) {
    super();
  }
}

export class SetDefaultLocation implements Action {
  readonly type = SET_DEFAULT_LOCATION;

  constructor (public payload: number) {}
}

export class SetDefaultLocationSuccess implements Action {
  readonly type = SET_DEFAULT_LOCATION_SUCCESS;

  constructor (public payload: number) {}
}

export class SetDefaultLocationFail extends FailAction {
  readonly type = SET_DEFAULT_LOCATION_FAIL;

  constructor(public error: any) {
    super();
  }
}

export type UserActions = LoadUserProfile
| LoadUserProfileSuccess
| LoadUserProfileFail
| SetDefaultLocation
| SetDefaultLocationSuccess
| SetDefaultLocationFail;

import { Directive, ElementRef, EventEmitter, HostListener, Inject, Output, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Directive({
  selector: '[eaClickOutside]'
})
export class ClickOutsideDirective {
  @Input() excludeRoot: string = null;
  @Output() clickOutside = new EventEmitter();

  private subscription = Subscription.EMPTY;

  @HostListener('click')
  attachOnClick() {
    this.subscription.unsubscribe();

    this.subscription = fromEvent(this.document, 'mouseup')
      .pipe(
        filter(event => this.element.nativeElement
                    && !this.element.nativeElement.contains(event.target)
                    && !(event.target as HTMLElement).closest(this.excludeRoot)),
        take(1)
      )
      .subscribe(() => this.clickOutside.emit());
  }
  constructor(private element: ElementRef, @Inject(DOCUMENT) private document: Document) {
  }
}

import { Injectable, NgZone, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { PlaceAutocompletePrediction } from '../models/place-autocomplete-prediction.model';
import { PlaceAutocompletionRequest } from '../models/place-autocompletion-request.model';
import { PlaceDetails } from '../models/place-details.model';
import { PlaceDetailsRequest } from '../models/place-details-request.model';

@Injectable({
  providedIn: 'root'
})
export class PlaceService {
  private placesService: google.maps.places.PlacesService;
  private autocompleteService: google.maps.places.AutocompleteService;

  constructor(private zone: NgZone, @Inject(DOCUMENT) document: Document) {
    this.autocompleteService = new google.maps.places.AutocompleteService();
    // in order to make google place service work DOM element should be attached to it, even if it remains invisible
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
  }

  public getPlacePredictions(
    autocompletionRequest: PlaceAutocompletionRequest
  ): Observable<PlaceAutocompletePrediction[]> {
    const request = <google.maps.places.AutocompletionRequest>{
      input: autocompletionRequest.input,
      types: autocompletionRequest.types || [],
      sessionToken: autocompletionRequest.sessionToken || null
    };

    const subj = new Subject<PlaceAutocompletePrediction[]>();

    this.autocompleteService.getPlacePredictions(
      request,
      (
        result: google.maps.places.AutocompletePrediction[],
        status: google.maps.places.PlacesServiceStatus
      ) => {
        this.zone.run(() => {
          switch (status) {
            case google.maps.places.PlacesServiceStatus.OK:
              subj.next(
                result.map((r: google.maps.places.AutocompletePrediction) => {
                  return <PlaceAutocompletePrediction>{
                    placeId: r.place_id,
                    name: r.structured_formatting.main_text,
                    description: r.description,
                    reference: r.reference
                  };
                })
              );
              subj.complete();
              break;
            case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
              subj.next([]);
              subj.complete();
              break;
            case google.maps.places.PlacesServiceStatus.INVALID_REQUEST:
            case google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT:
            case google.maps.places.PlacesServiceStatus.NOT_FOUND:
            case google.maps.places.PlacesServiceStatus.REQUEST_DENIED:
            case google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR:
              subj.error(status.toString());
              break;
          }
        });
      }
    );

    return subj.asObservable();
  }

  public createAutocompleteSessionToken() {
    return new google.maps.places.AutocompleteSessionToken();
  }

  public getDetails(
    request: PlaceDetailsRequest
  ): Observable<PlaceDetails> {
    const subj = new Subject<PlaceDetails>();

    this.placesService.getDetails(
      request,
      (
        result: google.maps.places.PlaceResult,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        this.zone.run(() => {
          switch (status) {
            case google.maps.places.PlacesServiceStatus.OK:
              subj.next(<PlaceDetails> result);
              subj.complete();
              break;
            case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
            case google.maps.places.PlacesServiceStatus.INVALID_REQUEST:
            case google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT:
            case google.maps.places.PlacesServiceStatus.NOT_FOUND:
            case google.maps.places.PlacesServiceStatus.REQUEST_DENIED:
            case google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR:
              subj.error(status.toString());
              break;
          }
        });
      });

    return subj.asObservable();
  }
}

<div class="c-p-bottom-sm">
    <div class="c-row c-row-collapsed c-row-align-center c-full-width">
        <span class="c-col-6 c-p-right-md c-text-ellipsis c-text-right" [title]="field?.cdfName">{{field?.cdfName}}</span>
        <span class="c-col-6">
            <cupcake-suggester [suggesterMinLength]="0"
                               [suggesterIdField]="'id'"
                               [suggesterValueField]="'name'"
                               [suggesterMultiselectMode]="true"
                               [suggesterDataSource]="elements"
                               [suggesterSelectAllEnabled]="true"
                               [suggesterClearAllEnabled]="true"
                               [suggesterFilterMode]="true"
                               [suggesterSelectFilteredEnabled]="false"
                               [suggesterSelectAllText]="'bd/event-activity/form.select_all' | translate"
                               [suggesterClearAllText]="'bd/event-activity/form.clear_all' | translate"
                               [suggesterPlaceholder]="'bd/event-activity/form.adf_select_values' | translate"
                               [suggesterSelectedItems]="selectedElements$ | async"
                               [suggesterItemTemplate]="resultsTemplate"
                               [suggesterOptionsLimit]="optionsLimit"
                               [suggesterPillTemplate]="pillTemplate"
                               class="ea-adf-multiselect-suggester"
                               (suggesterOnSelectEvent)="onAdfMultiSelectChange($event)">
            </cupcake-suggester>

            <ng-template #resultsTemplate let-model="item">
                {{ model.item.model.name }}
            </ng-template>

            <ng-template #pillTemplate let-model="item">
                <span>{{ model.model.name }}</span>
            </ng-template>

        </span>
    </div>
</div>

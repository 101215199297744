<div class="c-full-height c-d-flex c-flex-column ea-external-participants-container">
    <div class="c-header-md c-m-bottom-md" data-automation-id="external-participants-name">
        {{'bd/event-activity/form.external_participants' | translate}}
    </div>
    <div class="c-d-flex ea-participant-search">
        <div class="c-full-width">
            <bd-external-participants-search
                    [containerPosition]="containerPosition"
                    [dropdownWidth]="640"
                    [selectedItems]="suggesterParticipants$ | async"
                    [disabled]="participantsLoading$ | async"
                    (selectionChange)="onSelect($event)">
            </bd-external-participants-search>
        </div>
        <ea-lists-selector [data]="lists$"
                        [totalCount]="totalCount$ | async"
                        [loaded]="listsLoaded$ | async"
                        [disabled]="participantsLoading$ | async"
                        [filtered]="listsFiltered$ | async"
                        (searchTextChange)="onListTextChange($event)"
                        (listSelect)="onListSelect($event)"
        ></ea-lists-selector>
    </div>
    <div class="ea-missing-data-alert">
        <ea-missing-data-alert *ngIf="(failedParticipantsInformation$ | async) as failedParticipants"
                               [context]="failedParticipants"
                               (hide)="onFailMessageHide()">
        </ea-missing-data-alert>
    </div>
    <div class="ea-participants-warning" *ngIf="fundsPreselected$ | async">
        <div class="c-alert c-alert-warning c-alert-top c-alert-has-icon c-m-bottom-0 c-m-top-md">
            <a class="c-a c-alert-close" aria-hidden="true">
                <i class="fa fa-times" aria-hidden="true" (click)="onWarningHide()"></i>
            </a>
            <i class="fa fa-exclamation-circle c-alert-icon" aria-hidden="true"></i>
            {{ 'bd/event-activity/form.funds_cannot_be_added' | translate }}
            </div>
    </div>
    <div class='c-text-warning c-m-top-xs' *ngIf="alert$ | async">
        {{ 'bd/event-activity/form.externals_saving_error_message' | translate }}
    </div>
    <div class="c-text-muted c-d-flex c-full-height ea-no-participants"
        *ngIf="(noParticipants$ | async); else participantList"
    data-qa-id="attendee-no-records">
        {{'bd/event-activity/form.external_participants_not_added' | translate}}
    </div>
    <ng-template #participantList>
        <ea-external-participant-list [participants]="externalParticipants$ | async"
                                      [loading]="participantsLoading$ | async"
                                      (remove)="onRemove($event)"
                                      (update)="onSetStatus($event)"
                                      (add)="onAdd($event)"
                                      class="ea-external-list">
        </ea-external-participant-list>
    </ng-template>
</div>

import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromEventActivity from '../reducers/event-activity.reducer';
import { ActivityLocation } from '../../models/activity-location.model';
import { getSelectedLocation } from './saved-locations.selector';
import { LocationModel } from '../../models';

export const getEventActivityState = createSelector(
  fromFeature.getEventActivityDialogState,
  (state: fromFeature.State) => state.eventActivity
);

// Entity selectors
export const getEventActivityEntity = createSelector(
  getEventActivityState,
  fromEventActivity.getEventActivityEntity
);

export const getEventActivitySubject = createSelector(
  getEventActivityEntity,
  entity => entity && entity.subject
);

export const getEventActivityLocationFullAddress = createSelector(
  getEventActivityEntity,
  getSelectedLocation,
  (entity, selected) => entity.location && getFullAddress(entity.location, selected)
);

export const getEventActivityLocation = createSelector(
  getEventActivityEntity,
  entity => entity && entity.location
);

export const getSavedLocationAddress = createSelector(
  getEventActivityLocation,
  entity => entity && entity.locationId && getAddress(entity) || ''
);

export const getEventActivityId = createSelector(
  getEventActivityEntity,
  entity => entity && entity.activityId
);

export const getEventActivityTimezone = createSelector(
  getEventActivityEntity,
  entity => entity && entity.timeZone
);

export const getEventActivityStartDate = createSelector(
  getEventActivityEntity,
  entity => entity && entity.date
);

export const getEventActivityEndDate = createSelector(
  getEventActivityEntity,
  entity => entity && entity.endDate
);

export const getEventActivityInternalParticipants = createSelector(
  getEventActivityEntity,
  entity => entity && entity.internalParticipants
);

export const getEventScheduleId = createSelector(
  getEventActivityEntity,
  entity => entity && entity.scheduleId
);

export const getEventId = createSelector(
  getEventActivityEntity,
  entity => entity && entity.eventId
);

export const getExternalParticipants = createSelector(
  getEventActivityEntity,
  fromEventActivity.getExternalParticipants
);

export const getNewExternalParticipantsCount = createSelector(
  getEventActivityEntity,
  fromEventActivity.getNewExternalParticipantsCount
);

export const getSuggesterExternalParticipants = createSelector(
  getEventActivityEntity,
  fromEventActivity.getSuggesterExternalParticipants
);

export const getEventActivityLoading = createSelector(
  getEventActivityState,
  fromEventActivity.getEventActivityLoading
);

export const getEventActivityLoaded = createSelector(
  getEventActivityState,
  fromEventActivity.getEventActivityLoaded
);

export const getEventActivitySubjectIsValid = createSelector(
  getEventActivityState,
  fromEventActivity.getEventActivitySubjectIsValid
);

export const getEventActivityStartDateIsValid = createSelector(
  getEventActivityState,
  fromEventActivity.getEventActivityStartDateIsValid
);

export const getEventActivityEndDateIsValid = createSelector(
  getEventActivityState,
  fromEventActivity.getEventActivityEndDateIsValid
);

export const getEventActivityDateRangeIsValid = createSelector(
  getEventActivityState,
  fromEventActivity.getEventActivityDateRangeIsValid
);

export const getEventActivityPredefinedFieldsIsValid = createSelector(
  getEventActivityStartDateIsValid,
  getEventActivityEndDateIsValid,
  getEventActivityDateRangeIsValid,
  (startDateIsValid, endDateIsValid, dateRangeIsValid) => {
    return startDateIsValid && endDateIsValid && dateRangeIsValid;
  }
);

export const getEventActivityVirtualMeetingLinkIsValid = createSelector(
  getEventActivityState,
  fromEventActivity.getEventActivityVirtualMeetingLinkIsValid
);

export const getEventActivityVirtualMeetingPasswordIsValid = createSelector(
  getEventActivityState,
  fromEventActivity.getEventActivityVirtualMeetingPasswordIsValid
);

export const getEventActivityVirtualMeetingIsValid = createSelector(
  getEventActivityVirtualMeetingLinkIsValid,
  getEventActivityVirtualMeetingPasswordIsValid,
  (linkValidity, passwordValidity) => linkValidity && passwordValidity
);

export const getEventActivityIsValid = createSelector(
  getEventActivitySubjectIsValid,
  getEventActivityPredefinedFieldsIsValid,
  getEventActivityVirtualMeetingIsValid,
  (subjectIsValid, datesIsValid, virtualMeetingIsValid) => {
    return subjectIsValid && datesIsValid && virtualMeetingIsValid;
  }
);

export const getIsNewActivity = createSelector(
  getEventActivityState,
  fromEventActivity.getIsNewActivity
);

export const getEventActivityDuration = createSelector(
  getEventActivityState,
  fromEventActivity.getEventActivityDuration
);

export const getNotes = createSelector(
  getEventActivityEntity,
  entity => entity.comment
);

export const getIsEventActivity = createSelector(
  getEventActivityEntity,
  fromEventActivity.getIsEventActivity
);

export const isSavedLocationSelected = createSelector(
  getEventActivityEntity,
  fromEventActivity.isSavedLocationSelected
);

export const getLocationType = createSelector(
  getEventActivityEntity,
  fromEventActivity.getLocationType
);

export const getAddressComment = createSelector(
  getEventActivityEntity,
  fromEventActivity.getAddressComment
);

export const getOnlineMeetingLink = createSelector(
  getEventActivityEntity,
  fromEventActivity.getOnlineMeetingLink
);

export const getOnlineMeetingPassword = createSelector(
  getEventActivityEntity,
  fromEventActivity.getOnlineMeetingPassword
);

export function getFullAddress(location: ActivityLocation, selected?: LocationModel): string {
  if (selected && selected.locationName) {
    return selected.locationName;
  }

  return getAddress(location);
}

export function getAddress(location: ActivityLocation): string {
  if (!location) {
    return '';
  }

  if (!location.googlePlaceId) {
    return getUnmappedLocationAddress(location);
  }

  if (!location.address) {
    return '';
  }
  const venuePart = location.address.startsWith(location.venueName) ? '' : location.venueName;
  return [venuePart, location.address].filter(Boolean).join(', ');
}

function getUnmappedLocationAddress(location: ActivityLocation) {
  return [location.address, location.city, location.state, location.country].filter(Boolean).join(', ');
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { CupcakeSuggesterContainerPosition } from '@ipreo/cupcake-suggester';
import { Contact, Institution } from '@ipreo/bd-external-participants';
import * as fromRoot from '../../../store';
import * as fromStore from '../../store';
import {
  ExternalParticipant,
  FailedParticipantsInformation,
  ListCount,
  ListEntity
} from '../../models';
import { ExternalParticipantStatus } from '../../models/participant/external-participant-status.enum';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { ModalService} from '../../services';

@Component({
  selector: 'ea-external-participants-container',
  templateUrl: './event-activity-external-participants.component.html',
  styleUrls: ['./event-activity-external-participants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventActivityExternalParticipantsComponent {
  public containerPosition = CupcakeSuggesterContainerPosition.BottomRight;

  externalParticipants$: Observable<ExternalParticipant[]>;
  suggesterParticipants$: Observable<Array<Contact | Institution>>;
  lists$: Observable<ListEntity[]>;
  totalCount$: Observable<ListCount>;
  listsLoaded$: Observable<boolean>;
  listsFiltered$: Observable<boolean>;
  participantsLoading$: Observable<boolean>;
  noParticipants$: Observable<boolean>;
  failedParticipantsInformation$: Observable<FailedParticipantsInformation>;
  alert$: Observable<boolean>;
  fundsPreselected$: Observable<boolean>;

  constructor(
    private store: Store<fromRoot.State>,
    private modalService: ModalService
    ) {
    this.externalParticipants$ = this.store.pipe(select(fromStore.getExternalParticipants));
    this.suggesterParticipants$ = this.store.pipe(
      select(fromStore.getSuggesterExternalParticipants)
    );
    this.listsLoaded$ = this.store.pipe(select(fromStore.getListsLoaded));
    this.lists$ = this.store.pipe(select(fromStore.getLists)).pipe(
      withLatestFrom(this.listsLoaded$),
      filter(([, loaded]) => !!loaded),
      map(([lists]) => lists)
    );
    this.totalCount$ = this.store.pipe(select(fromStore.getListsTotalCount));
    this.listsFiltered$ = this.store.pipe(select(fromStore.getListsFiltered));
    this.participantsLoading$ = this.store.pipe(select(fromStore.getExternalParticipantsLoading));
    this.noParticipants$ = combineLatest(
      [this.externalParticipants$,
      this.participantsLoading$]
    ).pipe(map(([participants, loading]) => participants.length === 0 && !loading));
    this.failedParticipantsInformation$ = this.store.pipe(
      select(fromStore.getFailedParticipantsInformation)
    );
    this.alert$ = this.store.pipe(select(fromStore.getSaveExternalParticipantsError));
    this.fundsPreselected$ = this.store.pipe(select(fromStore.getFundsPreselected));
  }

  onSelect(a: Array<Contact | Institution>) {
    this.store.dispatch(new fromStore.SubmitExternalParticipants(a));
  }

  onRemove(participant: ExternalParticipant) {
    this.modalService.openModal({
      title: 'bd/event-activity/form.remove_external_participant',
      text: 'bd/event-activity/form.remove_participant_warning',
      titleCancelButton: 'bd/event-activity/form.cancel',
      titleConfirmButton: 'bd/event-activity/form.remove',
      type: 'danger',
      confirmEventEmit: () => this.removeParticipant(participant),
      interpolateParams: {name: this.getParticipantName(participant)}
    });
  }

  onSetStatus(payload: { participant: ExternalParticipant; newStatus: ExternalParticipantStatus }) {
    this.store.dispatch(new fromStore.UpdateExternalParticipantStatus(payload));
  }

  onAdd(participants: ExternalParticipant[]) {
    this.store.dispatch(new fromStore.AddExternalParticipants(participants));
  }

  onListTextChange(searchText: string) {
    this.store.dispatch(new fromStore.LoadLists(searchText));
  }

  onListSelect(list: ListEntity) {
    this.store.dispatch(new fromStore.SubmitExternalParticipantsList(list));
  }

  onFailMessageHide() {
    this.store.dispatch(new fromStore.UpdateFailedParticipants(null));
  }

  onWarningHide() {
    this.store.dispatch(new fromStore.SetPreselectWarnings(null));
  }

  private removeParticipant(participant: ExternalParticipant) {
    this.store.dispatch(new fromStore.DeleteExternalParticipant(participant));
  }

  private getParticipantName(participant: ExternalParticipant): string {
    return `${participant.firstName || ''} ${participant.lastName || ''}`.trim() || participant.company.name;
  }
}

import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromEventActivity from './event-activity.reducer';
import * as fromEventDataActivity from './event-activity-data.reducer';
import * as fromDialogView from './dialog-view.reducer';
import * as fromActivityDataField from './activity-defined-field.reducer';
import * as fromAttachments from './attachment.reducer';
import * as fromSecurities from './securities.reducer';
import * as fromSavedLocations from './saved-locations.reducer';

export interface State {
  eventActivity: fromEventActivity.State;
  dialogView: fromDialogView.State;
  eventActivityData: fromEventDataActivity.State;
  activityDataField: fromActivityDataField.State;
  activityAttachments: fromAttachments.State;
  securities: fromSecurities.State;
  savedLocations: fromSavedLocations.State;
}

export const reducers: ActionReducerMap<State> = {
  eventActivity: fromEventActivity.reducer,
  dialogView: fromDialogView.reducer,
  eventActivityData: fromEventDataActivity.reducer,
  activityDataField: fromActivityDataField.reducer,
  activityAttachments: fromAttachments.reducer,
  securities: fromSecurities.reducer,
  savedLocations: fromSavedLocations.reducer
};

export const getEventActivityDialogState = createFeatureSelector<State>('event-activity-dialog');

import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

import { storeLogger } from 'ngrx-store-logger';
import { storeFreeze } from 'ngrx-store-freeze';

import * as fromUser from './user.reducer';
import * as fromTranslation from './translation.reducer';

import { environment } from '@env/environment';
import { RouterStateUrl } from '../../models';

export interface State {
  routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
  userProfile: fromUser.State;
  translation: fromTranslation.State;
}

export const reducers: ActionReducerMap<State> = {
  routerReducer: fromRouter.routerReducer,
  userProfile: fromUser.reducer,
  translation: fromTranslation.reducer
};

export function logger(reducer: ActionReducer<State>): any {
  return storeLogger({ level: 'info', collapsed: true })(reducer);
}

export const metaReducers: MetaReducer<State>[] = environment.production
  ? []
  : [logger, storeFreeze];

export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;

    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;

    while (state.firstChild) {
      state = state.firstChild;
    }

    const { params } = state;

    return { url, queryParams, params };
  }
}

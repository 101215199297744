import { createSelector } from '@ngrx/store';

import * as fromRoot from '../../../store';
import * as fromFeature from './event-activity.selector';
import * as fromAdf from './activity-defined-field.selector';
import * as fromDialogView from './dialog-view.selector';

export const getDialogReady = createSelector(
  fromRoot.getUserProfileLoaded,
  fromRoot.getTranslationLoaded,
  fromFeature.getEventActivityLoaded,
  fromAdf.getHasAnyActivityDefinedFieldsLoaded,
  (userLoaded, translationLoaded, eventActivityLoaded, hasAnyAdfLoaded) =>
    userLoaded && translationLoaded && eventActivityLoaded && hasAnyAdfLoaded
);

export const getIsSavingEnabled = createSelector(
  fromFeature.getEventActivityIsValid,
  fromDialogView.getExternalParticipantsLoading,
  (activityIsValid, externalParticipantsLoading) => activityIsValid && !externalParticipantsLoading
);

export * from './event-activity.selector';
export * from './event-activity-data.selector';
export * from './dialog-view.selector';
export * from './activity-defined-field.selector';
export * from './event-schedule.selector';
export * from './error.selector';
export * from './attachment.selector';
export * from './securities.selector';
export * from './saved-locations.selector';
export * from './activity-type.selector';

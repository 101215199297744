import { Action } from '@ngrx/store';

import {
    ActivityInitiator,
    ActivityMethod,
    ActivityMode,
    ActivitySubType,
    Duration,
    EventActivity,
    ExternalParticipant,
    EventActivityModel,
    CompositeId,
    ChangeEntity
} from '../../models';
import { InternalParticipant } from '../../models/participant/internal-participant.model';
import { ActivityLocation } from '../../models/activity-location.model';
import { ActivityTemplate } from '../../models/activity-type/activity-template.model';
import { ExternalParticipantStatus } from '../../models/participant/external-participant-status.enum';
import { ActivitySavedPayload } from '../../models/activity-saved-payload';
import { FailAction } from '../../../store/actions/fail-action';

export const INIT = '[EVENT ACTIVITY] Init';
export const INIT_NEW = '[EVENT ACTIVITY] Init New';

export const LOAD_EVENT_ACTIVITY = '[EVENT ACTIVITY] Load';

export const LOAD_EVENT_ACTIVITY_MODEL = '[EVENT ACTIVITY MODEL] Load';
export const LOAD_EVENT_ACTIVITY_MODEL_SUCCESS = '[EVENT ACTIVITY MODEL] Load Success';
export const LOAD_EVENT_ACTIVITY_MODEL_FAIL = '[EVENT ACTIVITY MODEL] Load Fail';

export const SAVE_EVENT_ACTIVITY = '[EVENT ACTIVITY] Save';
export const SAVE_EVENT_ACTIVITY_SUCCESS = '[EVENT ACTIVITY] Save Success';
export const SAVE_EVENT_ACTIVITY_FAIL = '[EVENT ACTIVITY] Save Fail';

export const UPDATE_SUBJECT = '[EVENT ACTIVITY] Update Subject';
export const UPDATE_START_DATE = '[EVENT ACTIVITY] Update Start Date';
export const UPDATE_END_DATE = '[EVENT ACTIVITY] Update End Date';
export const UPDATE_DURATION = '[EVENT ACTIVITY] Update Duration';
export const UPDATE_INTERNAL_PARTICIPANTS = '[EVENT ACTIVITY] Update Internal Participants';
export const UPDATE_ACTIVITY_SUB_TYPE = '[EVENT ACTIVITY] Update Activity Type';
export const UPDATE_ACTIVITY_MODE = '[EVENT ACTIVITY] Update Activity Mode';
export const UPDATE_ACTIVITY_METHOD = '[EVENT ACTIVITY] Update Activity Method';
export const UPDATE_ACTIVITY_INITIATOR = '[EVENT ACTIVITY] Update Activity Initiator';
export const UPDATE_ACTIVITY_TEMPLATE = '[EVENT ACTIVITY] Update Activity Template';
export const UPDATE_LOCATION = '[EVENT ACTIVITY] Update Location';
export const UPDATE_EVENT_LOCATION = '[EVENT ACTIVITY] Update Event Location';
export const UPDATE_EXTERNAL_PARTICIPANTS = '[EVENT ACTIVITY] Update External Participants';
export const ADD_EXTERNAL_PARTICIPANTS = '[EVENT ACTIVITY] Add External Participants';
export const UPDATE_EXTERNAL_PARTICIPANT_STATUS =
  '[EVENT ACTIVITY] Update External Participant Status';
export const CHANGE_EXTERNAL_PARTICIPANTS_COMPANY_CONTACTS =
  '[EVENT ACTIVITY] Change External Participants Company Contacts';
export const DELETE_EXTERNAL_PARTICIPANT = '[EVENT ACTIVITY] Delete External Participant';
export const UPDATE_NOTES = '[EVENT ACTIVITY] Update Notes';
export const DELETE_ACTIVITY = '[EVENT ACTIVITY] Delete Activity';
export const DELETE_ACTIVITY_SUCCESS = '[EVENT ACTIVITY] Delete Success';
export const DELETE_ACTIVITY_FAIL = '[EVENT ACTIVITY] Delete Fail';
export const RESET_PARTIAL_ERRORS = '[EVENT ACTIVITY] Reset Activity Errors';
export const UPDATE_TIMEZONE = '[EVENT ACTIVITY] Update Time Zone';
export const UPDATE_ADDRESS_COMMENT = '[EVENT ACTIVITY] Update Address Comment';
export const UPDATE_ONLINE_MEETING_LINK = '[EVENT ACTIVITY] Update Online Meeting Link';
export const UPDATE_ONLINE_MEETING_PASSWORD = '[EVENT ACTIVITY] Update Online Meeting Password';

export class Init implements Action {
  readonly type = INIT;
}

export class InitNew implements Action {
  readonly type = INIT_NEW;
}

export class LoadEventActivity implements Action {
  readonly type = LOAD_EVENT_ACTIVITY;

  constructor(public payload: EventActivity) {}
}

export class LoadEventActivityModel implements Action {
  readonly type = LOAD_EVENT_ACTIVITY_MODEL;

  constructor(public payload: string) {}
}

export class LoadEventActivityModelSuccess implements Action {
  readonly type = LOAD_EVENT_ACTIVITY_MODEL_SUCCESS;

  constructor(public payload: EventActivityModel) {}
}

export class LoadEventActivityModelFail extends FailAction {
  readonly type = LOAD_EVENT_ACTIVITY_MODEL_FAIL;

  constructor(public payload: number, public error: any) {
    super();
  }
}

export class SaveEventActivity implements Action {
  readonly type = SAVE_EVENT_ACTIVITY;
}

export class SaveEventActivitySuccess implements Action {
  readonly type = SAVE_EVENT_ACTIVITY_SUCCESS;

  constructor(public payload: ActivitySavedPayload) {}
}

export class SaveEventActivityFail extends FailAction {
  readonly type = SAVE_EVENT_ACTIVITY_FAIL;

  constructor(public payload: number, public error: any) {
    super();
  }
}

export class UpdateSubject implements Action {
  readonly type = UPDATE_SUBJECT;

  constructor(public payload: string) {}
}

export class UpdateStartDate implements Action {
  readonly type = UPDATE_START_DATE;

  constructor(public payload: Date) {}
}

export class UpdateDuration implements Action {
  readonly type = UPDATE_DURATION;

  constructor(public payload: Duration) {}
}

export class UpdateEndDate implements Action {
  readonly type = UPDATE_END_DATE;

  constructor(public payload: Date) {}
}

export class UpdateInternalParticipants implements Action {
  readonly type = UPDATE_INTERNAL_PARTICIPANTS;

  constructor(public payload: InternalParticipant[]) {}
}

export class UpdateActivitySubType implements Action {
  readonly type = UPDATE_ACTIVITY_SUB_TYPE;

  constructor(public payload: ActivitySubType) {}
}

export class UpdateActivityMode implements Action {
  readonly type = UPDATE_ACTIVITY_MODE;

  constructor(public payload: ActivityMode) {}
}

export class UpdateActivityMethod implements Action {
  readonly type = UPDATE_ACTIVITY_METHOD;

  constructor(public payload: ActivityMethod) {}
}

export class UpdateActivityInitiator implements Action {
  readonly type = UPDATE_ACTIVITY_INITIATOR;

  constructor(public payload: ActivityInitiator) {}
}

export class UpdateActivityTemplate implements Action {
  readonly type = UPDATE_ACTIVITY_TEMPLATE;

  constructor(public payload: ActivityTemplate) {}
}

export class UpdateLocation implements Action {
  readonly type = UPDATE_LOCATION;

  constructor(public payload: ActivityLocation) {}
}

export class UpdateEventLocation implements Action {
  readonly type = UPDATE_EVENT_LOCATION;

  constructor(public payload: { scheduleId: number, timeZone: string }) {}
}

export class UpdateExternalParticipants implements Action {
  readonly type = UPDATE_EXTERNAL_PARTICIPANTS;

  constructor(public payload: ExternalParticipant[]) {}
}

export class AddExternalParticipants implements Action {
  readonly type = ADD_EXTERNAL_PARTICIPANTS;

  constructor(public payload: ExternalParticipant[]) {}
}

export class UpdateExternalParticipantStatus implements Action {
  readonly type = UPDATE_EXTERNAL_PARTICIPANT_STATUS;

  constructor(
    public payload: { participant: ExternalParticipant; newStatus: ExternalParticipantStatus }
  ) {}
}

export class DeleteExternalParticipant implements Action {
  readonly type = DELETE_EXTERNAL_PARTICIPANT;

  constructor(public payload: ExternalParticipant) {}
}

export class ChangeExternalParticipantsCompanyContacts implements Action {
  readonly type = CHANGE_EXTERNAL_PARTICIPANTS_COMPANY_CONTACTS;

  constructor(public payload: { participants: ExternalParticipant[]; id: CompositeId }) {}
}

export class UpdateNotes implements Action {
  readonly type = UPDATE_NOTES;

  constructor(public payload: string) {}
}

export class DeleteActivity implements Action {
  readonly type = DELETE_ACTIVITY;

  constructor() {}
}

export class DeleteActivitySuccess implements Action {
  readonly type = DELETE_ACTIVITY_SUCCESS;

  constructor () {}
}

export class ResetActivityPartialErrors implements Action {
  readonly type = RESET_PARTIAL_ERRORS;

  constructor (public payload: ChangeEntity) {}
}

export class DeleteActivityFail extends FailAction {
  readonly type = DELETE_ACTIVITY_FAIL;

  constructor (public payload: number, public error: any) {
    super();
  }
}

export class UpdateTimeZone implements Action {
  readonly type = UPDATE_TIMEZONE;

  constructor (public payload: string) {}
}

export class UpdateAddressComment implements Action {
  readonly type = UPDATE_ADDRESS_COMMENT;

  constructor(public payload: string) {}
}

export class UpdateOnlineMeetingLink implements Action {
  readonly type = UPDATE_ONLINE_MEETING_LINK;

  constructor(public payload: string) {}
}

export class UpdateOnlineMeetingPassword implements Action {
  readonly type = UPDATE_ONLINE_MEETING_PASSWORD;

  constructor(public payload: string) {}
}

export type EventActivityAction =
  | Init
  | InitNew
  | LoadEventActivity
  | LoadEventActivityModel
  | LoadEventActivityModelSuccess
  | LoadEventActivityModelFail
  | SaveEventActivity
  | SaveEventActivitySuccess
  | SaveEventActivityFail
  | UpdateSubject
  | UpdateStartDate
  | UpdateEndDate
  | UpdateDuration
  | UpdateInternalParticipants
  | UpdateActivitySubType
  | UpdateActivityMode
  | UpdateActivityMethod
  | UpdateActivityInitiator
  | UpdateActivityTemplate
  | UpdateLocation
  | UpdateEventLocation
  | UpdateExternalParticipants
  | UpdateExternalParticipantStatus
  | DeleteExternalParticipant
  | ChangeExternalParticipantsCompanyContacts
  | DeleteActivity
  | DeleteActivitySuccess
  | DeleteActivityFail
  | UpdateNotes
  | AddExternalParticipants
  | ResetActivityPartialErrors
  | UpdateTimeZone
  | UpdateAddressComment
  | UpdateOnlineMeetingLink
  | UpdateOnlineMeetingPassword;

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectorRef, ElementRef
} from '@angular/core';
  @Component({
    selector: 'ea-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })
  export class NotesComponent implements OnInit {
    @Input() value: string;
    @Input() language: string;
    @Input() isHubActivityFormEnabled: boolean;

    @Output() notesChange = new EventEmitter<string>();

    public isExpanded: boolean;

    constructor(private cdr: ChangeDetectorRef, private el: ElementRef) {}

    ngOnInit() {
      this.isExpanded = this.value && this.isHubActivityFormEnabled;
    }

    onNotesChange($event: string) {
      if (!this.value && !$event) {
        return;
      }
      this.notesChange.emit($event);
    }

    onFocus() {
      if (this.isHubActivityFormEnabled) {
        this.isExpanded = true;
        this.cdr.detectChanges();
      }
      this.bubbleClickEvent();
    }

    // notes editor textarea is placed inside an iframe
    // that's why clicks on it aren't captured by dropdowns and suggesters clickOutside handlers
    private bubbleClickEvent() {
      const eventName = 'mouseup';

      let event;

      if (typeof(Event) === 'function') {
        event = new Event(eventName, { bubbles: true, cancelable: true });
      } else {
        // to support IE
        event = document.createEvent('Event');
        event.initEvent(eventName, true, true);
      }

      this.el.nativeElement.dispatchEvent(event);
    }
  }

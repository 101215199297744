import { AuthType } from './../contracts/auth-type.enum';
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserManager, User, UserManagerSettings, Log } from 'oidc-client';
import { AUTH_CLIENT_SETTINGS } from '../contracts/auth-client-settings.token';
import {
  AuthClientSettings,
  IhsSamAuthorityEnvCode,
  IpreoAccountAuthorityEnvCode
} from '../contracts/auth-client-settings';
import { AuthRedirectNavigator } from '../contracts/redirect-navigator';
import { SilentSignInIFrameObserver } from './silent-sign-in-iframe-observer.service';
import { OnAuthorizedHandler } from '../contracts/on-authorized-handler';

const IPREO_ACCOUNT_STAGING_URL = 'https://staging.account.ipreo.com/';
const IPREO_ACCOUNT_PROD_URL = 'https://account.ipreo.com/';
const IHS_SAM_STAGING_URL = 'https://sam.samexternal.net:443/sso/oauth2/';
const IHS_SAM_PROD_URL = 'https://sam.ihsmarkit.com:443/sso/oauth2/';

@Injectable()
export class BillfoldAuthClient {
  private userManager: UserManager;
  private userSubject = new BehaviorSubject<User>(null);
  authTypeKey = 'auth-type';
  authType: AuthType;

  constructor(
    private silentSignInObserver: SilentSignInIFrameObserver,
    @Inject(AUTH_CLIENT_SETTINGS) private settings: AuthClientSettings,
    @Optional() private redirectNavigator: AuthRedirectNavigator,
    @Optional() private onAuthorizedHandler: OnAuthorizedHandler) {
    this.setAuthType();
    Log.logger = console;
    Log.level = Log.INFO;

    if (this.authType === AuthType.SAM) {
      this.userManager = new UserManager(this.getSamUserManagerSettings());
    } else {
      this.userManager = new UserManager(this.getBillfoldUserManagerSettings());
    }

    this.userManager.events.addUserSignedOut(() => {
      this.userManager.removeUser();
    });

    this.initUserEvents();
  }

  public getUser(): Observable<User> {
    return this.userSubject.asObservable();
  }

  public getIsUserSignedIn(): Observable<boolean> {
    return this.getUser().pipe(map((user: User) => this.isValidUser(user)));
  }

  public getAuthorizationToken(): Observable<string> {
    return this.getUser().pipe(map((user: User) => user && user.access_token));
  }

  public silentSingIn() {
    return this.silentSignInObserver.observeOn(from(this.getSilentSignInMethod()));
  }

  public signIn(redirectUrl: string) {
    return from(this.userManager.signinRedirect({ redirect_uri: redirectUrl }));
  }

  public clearSession() {
    return this.userManager.removeUser();
  }

  public getAuthenticationType(): AuthType {
    return this.getAuthTypeEnum(localStorage.getItem(this.authTypeKey));
  }

  private getSilentSignInMethod() {
    if (this.authType === AuthType.IpreoAccount) {
      return this.userManager.signinSilent();
    }
    return this.userManager.signinPopup();
  }

  private initUserEvents() {
    // to handle case when we already has authorized user in current session
    this.userManager.getUser().then((u: User) => {
      if (this.isValidUser(u)) {
        this.userSubject.next(u);
        if (this.onAuthorizedHandler) {
          this.onAuthorizedHandler.handle();
        }
      }
    });

    this.userManager.events.addUserLoaded((user: User) => {
      this.userSubject.next(user);
      if (this.onAuthorizedHandler) {
        this.onAuthorizedHandler.handle();
      }
    });

    this.userManager.events.addUserUnloaded(() => {
      this.userSubject.next(null);
    });
  }

  private isValidUser(user: User) {
    return !!user && !user.expired;
  }

  private getBillfoldUserManagerSettings(): UserManagerSettings {
    const settings = this.settings.billfoldAuthSettings;
    return <UserManagerSettings>{
      client_id: settings.clientId,
      authority: this.getIpreoAccountAuthorityUrl(settings.authority),
      scope: settings.scope.join(' '),
      silent_redirect_uri:
        location.origin + settings.baseRedirectionPath + 'auth/silentCallback.html',
      response_type: 'id_token token',
      automaticSilentRenew: true,
      redirectNavigator: this.redirectNavigator || undefined
    };
  }

  private getSamUserManagerSettings(): UserManagerSettings {
    const settings = this.settings.ihsSamSettings;
    return <UserManagerSettings>{
      client_id: settings.clientId,
      authority: this.getIhsSamAuthorityUrl(settings.authority),
      scope: settings.scope.join(' '),
      popup_redirect_uri: location.origin + settings.baseRedirectionPath + 'auth/popupCallback.html',
      response_type: 'code',
      automaticSilentRenew: true,
      redirectNavigator: this.redirectNavigator || undefined
    };
  }

  private getIpreoAccountAuthorityUrl(environment: IpreoAccountAuthorityEnvCode): string {
    // tslint:disable-next-line:no-small-switch
    switch (environment) {
      case IpreoAccountAuthorityEnvCode.Production:
        return IPREO_ACCOUNT_PROD_URL;
      default:
        return IPREO_ACCOUNT_STAGING_URL;
    }
  }

  private getIhsSamAuthorityUrl(environment: IhsSamAuthorityEnvCode): string {
    // tslint:disable-next-line:no-small-switch
    switch (environment) {
      case IhsSamAuthorityEnvCode.Production:
        return IHS_SAM_PROD_URL;
      default:
        return IHS_SAM_STAGING_URL;
    }
  }

  private setAuthType(): void {
    const queryParam = this.getParam('idp');
    this.authType = this.getAuthTypeEnum(queryParam);
    localStorage.removeItem(this.authTypeKey);
    localStorage.setItem(this.authTypeKey, this.authType);
  }

  private getParam(paramName: string): string {
    const params = new HttpParams({ fromString: window.location.search.toLowerCase().substring(1) });
    const matchingParam = params.get(paramName.toLowerCase());
    return matchingParam
      ? matchingParam.replace(/[^A-Za-z']/g, '')
      : '';
  }

  private getAuthTypeEnum(value: string): AuthType {
    return value && value.toLowerCase() === AuthType.SAM ? AuthType.SAM : AuthType.IpreoAccount;
  }
}

import { Action } from '@ngrx/store';

export abstract class FailAction<T = any> implements Action {
  type: string;
  error: T;
}

export const LOG_AUTH_ERROR_TYPE = '[AUTH] Log Error';
export const LOG_STYLES_LOAD_ERROR_TYPE = '[Styles] Log Load Error';

export class LogStyleLoadErrorAction extends FailAction<{link: string, message: string}> {
  type = LOG_STYLES_LOAD_ERROR_TYPE;

  constructor(link: string, message: string) {
    super();
    this.error = { link, message };
  }
}

export class LogAuthErrorAction<T> implements Action {
  type = LOG_AUTH_ERROR_TYPE;

  constructor(public error: T, public availableToken?: string) {
  }
}

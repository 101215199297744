<div class="c-row c-row-align-center c-m-bottom-sm ea-event-location c-align-center" data-automation-id="event-location-row">
  <div class="c-col-2 c-p-right-0" >
    {{'bd/event-activity/form.event_location' | translate}}
  </div>
  <div class="c-col-10" *ngIf="schedules && schedules.length && !isVirtualEvent; else noLocation" data-automation-id="event-location-value">
    <div class="c-row c-row-collapsed">
      <div class="c-col-6" *ngIf="schedules.length === 1 && selectedPlaceId; else multipleLocations">
        {{ getDisplayLocation(schedules[0].location) }}
      </div>
    </div>
    <ng-template #multipleLocations>
      <div class="c-col-6">
        <select class="c-input ea-event-location-selector" #eventLocation
                (change)="onChange(eventLocation.value)">
          <option *ngIf="!selectedPlaceId" hidden>{{ 'bd/event-activity/form.select_location' | translate }}</option>
          <option *ngFor="let option of schedules"
                [selected]="selectedPlaceId === option.googlePlaceId"
                [value]="option.eventScheduleId">{{ getDisplayLocation(option.location) }}
        </option>
        </select>
      </div>
    </ng-template>
  </div>
  <ng-template #noLocation>
    <div class="c-col-10" data-qa-id="no-event-location">{{ 'bd/event-activity/form.event_location_not_available' | translate }}</div>
  </ng-template>
</div>

import { createSelector } from '@ngrx/store';
import { getEventActivityState } from './event-activity.selector';
import { ChangeEntity } from '../../models';

export const getEventActivityError = createSelector(
  getEventActivityState,
  (entities) => entities.error.type
);

export const getSaveADFsError = createSelector(
  getEventActivityState,
  (entities) => entities.error.failedEntities[ChangeEntity.Adf]
);

export const getSaveActivityLocationError = createSelector(
  getEventActivityState,
  (entities) => entities.error.failedEntities[ChangeEntity.Location]
);

export const getSaveInternalParticipantsError = createSelector(
  getEventActivityState,
  (entities) => entities.error.failedEntities[ChangeEntity.InternalParticipant]
);

export const getSaveExternalParticipantsError = createSelector(
  getEventActivityState,
  (entities) => entities.error.failedEntities[ChangeEntity.ExternalParticipant]
);

export const getSaveSecurityError = createSelector(
  getEventActivityState,
  (entities) => entities.error.failedEntities[ChangeEntity.Security]
);

export const getSaveAttachmentsError = createSelector(
  getEventActivityState,
  (entities) => entities.error.failedEntities[ChangeEntity.Attachment]
);

import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Schedule, Location, countriesWithStates } from '../../models';

@Component({
  selector: 'ea-event-location',
  templateUrl: './event-location.component.html',
  styleUrls: ['./event-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventLocationComponent {
  @Input() schedules: Schedule[];
  @Input() selectedPlaceId: string;
  @Input() isVirtualEvent: boolean;

  @Output() eventLocationChange = new EventEmitter();

  onChange(id: string) {
    const schedule = this.schedules.find(d => d.eventScheduleId === +id);
    this.eventLocationChange.emit({
      scheduleId: schedule.eventScheduleId,
      timeZone: schedule.timeZone
    });
  }

  getDisplayLocation(location: Location): string {
    if (!location) {
      return '';
    }
    const locationComponents = countriesWithStates.includes(location.countryName) ?
      [location.city, location.stateProvinceCode, location.countryCode] :
        [location.city, location.countryCode];

    return locationComponents.filter(Boolean).join(', ');
  }
}

<div class="c-row c-row-align-center">
  <div class="c-col-offset-2 c-col-10">
    <div class="ea-rixml-fields c-d-flex">
      <div class="c-p-right-md" data-automation-id="activity-type">
        <span class="c-text-muted c-p-right-sm">{{'bd/event-activity/form.type' | translate}}:</span>{{ 'bd/event-activity/form.' + localizationKeys[type || 'N/A'] | translate }}
      </div>
      <div class="c-p-right-md" data-automation-id="activity-mode">
        <span class="c-text-muted c-p-right-sm">{{'bd/event-activity/form.mode' | translate}}:</span>{{ 'bd/event-activity/form.' + localizationKeys[mode || 'N/A'] | translate }}
      </div>
      <div class="c-p-right-md" data-automation-id="activity-method">
        <span class="c-text-muted c-p-right-sm">{{'bd/event-activity/form.method' | translate}}:</span>{{ 'bd/event-activity/form.' + localizationKeys[method || 'N/A'] | translate }}
      </div>
      <div class="c-p-right-lg" data-automation-id="activity-initiator">
        <span class="c-text-muted c-p-right-sm">{{'bd/event-activity/form.initiator' | translate}}:</span>{{ 'bd/event-activity/form.' + localizationKeys[initiator || 'N/A'] | translate }}
      </div>
      <div data-automation-id="activity-change-btn" >
        <span class="c-btn-link c-p-0 c-m-right-md" *ngIf="showChangeButton" (click)="onChange()">{{'bd/event-activity/form.change' | translate}}</span>
        <i class="fa fa-question-circle c-text-muted ea-rixml-info-guide-icon" aria-hidden="true" [cupcakePopover]="popoverOptions"></i>
      </div>
    </div>
  </div>
</div>

import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {
  CupcakeModalOptions,
  CupcakeModalService,
  CupcakePopoverOptions
} from '@ipreo/cupcake-components';
import * as fromModels from '../../models';
import { RixmlFieldsModalComponent } from '../rixml-fields-modal/rixml-fields-modal.component';

@Component({
  selector: 'ea-rixml-fields',
  templateUrl: './rixml-fields.component.html',
  styleUrls: ['./rixml-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RixmlFieldsComponent implements OnDestroy {
  @Input() type: fromModels.ActivitySubType;
  @Input() mode: fromModels.ActivityMode;
  @Input() method: fromModels.ActivityMethod;
  @Input() initiator: fromModels.ActivityInitiator;
  @Input() showChangeButton: boolean;
  @Output() change = new EventEmitter();

  localizationKeys = fromModels.RIXMLFieldsLocalization;
  popoverOptions: CupcakePopoverOptions;

  subscription: Subscription;

  constructor(private modal: CupcakeModalService, private translateService: TranslateService) {
    this.popoverOptions = new CupcakePopoverOptions({
      position: 'bottom',
      text: this.translateService.instant('bd/event-activity/form.rixml_tooltip_text'),
      trigger: 'hover'
    });
  }

  onChange() {
    const options = <CupcakeModalOptions>{
      mode: 'component',
      type: 'default',
      view: RixmlFieldsModalComponent,
      title: this.translateService.instant('bd/event-activity/form.change_rixml_fields_title'),
      rootCssClass: 'ea-fixml-modal',
      contentWidth: 385,
      context: { type: this.type, mode: this.mode, initiator: this.initiator, method: this.method }
    };

    this.subscription = this.modal.open(options).subscribe(d => {
      if (d && d.action && d.action === 'cancel') {
        return;
      }
      this.change.emit(d);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
  }
}

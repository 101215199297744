import { Action } from '@ngrx/store';
import { FailAction } from './fail-action';

export const LOAD_TRANSLATION = '[TRANSLATION] Load';
export const LOAD_TRANSLATION_SUCCESS = '[TRANSLATION] Load Success';
export const LOAD_TRANSLATION_FAIL = '[TRANSLATION] Load Fail';
export const LOAD_LOCALIZATION_FAIL = '[TRANSLATION] Load Localization Fail';

export class LoadTranslation implements Action {
  readonly type = LOAD_TRANSLATION;

  constructor(public payload: string) {}
}

export class LoadTranslationSuccess implements Action {
  readonly type = LOAD_TRANSLATION_SUCCESS;
}

export class LoadTranslationFail extends FailAction {
  readonly type = LOAD_TRANSLATION_FAIL;
  constructor(public error: any) {
    super();
  }
}

export class LoadLocalizationFail extends FailAction {
  readonly type = LOAD_LOCALIZATION_FAIL;
  constructor(public error: any) {
    super();
  }
}

export type TranslateActions = LoadTranslation | LoadTranslationFail | LoadTranslationSuccess | LoadLocalizationFail;

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { PlaceSearchResponse, PlaceAutocompleteResponse, PlaceDetailsResponse } from '../models/hub-place.model';
import { PlaceDetails } from '../models/place-details.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapsService {
  private baseUrl = environment.hubBaseUrl;

  constructor(private http: HttpClient) { }

  search(text: string, addressTypes?: string[]) {
    let params = new HttpParams().set('text', text);

    if (Array.isArray(addressTypes) && addressTypes.length >= 1) {
      params = params.set('addressType', addressTypes[0]);
    }

    return this.http.get<PlaceSearchResponse>(`${this.baseUrl}/api/maps/search`, { params }).pipe(
      map(response => response.candidates)
    );
  }

  autocomplete(text: string, addressTypes?: string[], sessionToken?: string) {
    let params = new HttpParams()
      .set('text', text)
      .set('sessionToken', sessionToken);

    if (Array.isArray(addressTypes) && addressTypes.length >= 1) {
      params = params.set('type', addressTypes[0]);
    }

    return this.http.get<PlaceAutocompleteResponse>(`${this.baseUrl}/api/maps/autocomplete`, { params }).pipe(
      map(response => response.predictions)
    );
  }
  getDetails(placeId: string) {
    return this.http.get<PlaceDetailsResponse>(`${this.baseUrl}/api/maps/places/${placeId}`).pipe(
      map(response => <PlaceDetails>{
        ...response.result,
        place_id: placeId
      })
    );
  }

  getTimeZone(placeId: string): Observable<string> {
    return this.http.get(
      `${this.baseUrl}/api/maps/places/${placeId}/timezone`,
      { responseType: 'text' }
      );
  }
}

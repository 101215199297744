import { Pipe, PipeTransform } from '@angular/core';
import { ExternalParticipantStatus } from '../../models/participant/external-participant-status.enum';

@Pipe({
  name: 'participantStatusLocalizationKey'
})
export class ParticipantStatusLocalizationKeyPipe implements PipeTransform {
  transform(status: ExternalParticipantStatus) {
    return status in ExternalParticipantStatus ?
      `bd/event-activity/form.status_${ExternalParticipantStatus[status].toLowerCase()}` :
      '';
  }
}

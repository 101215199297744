import { NgModule } from '@angular/core';
import { PagerComponent } from './pager-component/pager.component';
import { CommonModule } from '@angular/common';
import { PaginatePipe } from './paginate-pipe/paginate.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [PagerComponent, PaginatePipe],
    exports: [PagerComponent, PaginatePipe]
})
export class PagerModule {}
